import axios, { AxiosInstance } from "axios";
import { Logout } from 'features/user';


let instance: AxiosInstance;

export const axiosMiddleware = (store: any) => (next: any) => (action: any) => {
  if (store) {
    setInterceptors(store);
  }

  return next(action);
};

const setInterceptors = (store: any) => {
  if (!store) {
    return;
  }

  const state = store.getState();
  const authToken = state?.currentUser?.Data?.Data?.access_token;

  instance = axios.create({
    baseURL: "https://muns.ais-works.com/web_services/",
    //baseURL: 'http://localhost:52551/',
    //baseURL: 'https://muns.ais-app.com/web_services/',
    //baseURL: 'https://10.36.37.11/',
    //baseURL: 'https://api.mes.dmaal.shj.ae/',
    headers: {
      Authorization: `${authToken ? "Bearer " + authToken : ""}`,
      'Access-Control-Allow-Origin': "*",
      "Access-Control-Allow-Credentials": "true",
      // 'Content-Type': 'multipart/form-data'
    },
  });

  instance.interceptors.response.use(
    function (response: any) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error: any) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      if (
        error.response.status === 401 ||
        error.response.statusText === "Unauthorized"
      ) {
        store.dispatch(Logout());
      }

      return Promise.reject(error);
    }
  );
  return instance;
};

export { instance as apiConfig };
