import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TabModal from 'components/TabModal'
import { Form, Row, Col } from 'antd'
import { addDepartmentSector, updateDepartmentSector } from 'api/departments/departments'
import { useAppSelector, useAppDispatch } from 'store'
import { triggerLoading } from 'features/utils/loadingSlice'
import { useNavigate } from 'react-router-dom'
import Textfield from "components/Textfield"
import Button from 'components/Button'
import { triggerModalTab } from 'features/utils/modalTabSlice'

export default function AdministrationsForm() {

    const { t, i18n } = useTranslation()
    const [showTabForm, setShowTabForm] = useState<any>(false)
    const [formData, setFormData] = useState<any>()
    const currentUser = useAppSelector((state: any) => state.currentUser)
    const currentactiveTab = useAppSelector(state => state.activeTab.Data)
    const modalStatus = useAppSelector(state => state.modalTab)
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()

    useEffect(() => {
        setFormData(modalStatus?.Data)
    }, [])

    useEffect(() => {
        if (currentactiveTab == '/municipality-management-form') {
            setShowTabForm(true)
            setTimeout(() => dispatch(triggerLoading({ status: false })), 1000)
        }
    }, [currentactiveTab])

    const handleSubmit = async (values: any) => {
        dispatch(triggerLoading({ status: true }))
        if (modalStatus?.Type == 'add') {
            await addDepartmentSector(
                currentUser.Data.Data.access_token,
                '1',
                values.Department_Sector_Code,
                values.Department_Sector_Name_AR,
                values.Department_Sector_Name_EN
            ).then(() => {
                dispatch(triggerLoading({ status: false }))
                dispatch(triggerModalTab({ Open: false, Data: {} }))
            })
        } else {
            await updateDepartmentSector(
                currentUser.Data.Data.access_token,
                values.Department_Sector_Id,
                values.Department_Sector_Code,
                values.Department_Sector_Name_AR,
                values.Department_Sector_Name_EN
            ).then(() => {
                dispatch(triggerLoading({ status: false }))
                dispatch(triggerModalTab({ Open: false, Data: {} }))
            })
        }
    }

    const myForm = (
        <Form
            form={form}
            style={{ width: '100%' }}
            onFinish={handleSubmit}
            initialValues={{
                "Department_Sector_Name_AR": modalStatus?.Data?.Department_Sector_Name_AR,
                "Department_Sector_Name_EN": modalStatus?.Data?.Department_Sector_Name_EN,
                "Department_Sector_Code": modalStatus?.Data?.Department_Sector_Code,
                "Department_Sector_Id": modalStatus?.Data?.Department_Sector_Id,
            }}
        >
            <Row gutter={[12, 0]}>
                <Col xs={24} md={12}>
                    <Form.Item name="Department_Sector_Id">
                        <Textfield
                            label={t('departmentNo') as string}
                            disabled={true}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="Department_Sector_Code"
                        rules={[{ required: true, message: t('error.field_required') as string }]}
                    >
                        <Textfield
                            label={t('departmentCode') as string}
                            disabled={modalStatus.Type == 'view'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="Department_Sector_Name_AR"
                        rules={[{ required: true, message: t('error.field_required') as string }]}
                    >
                        <Textfield
                            label={t('depNameAR') as string}
                            disabled={modalStatus.Type == 'view'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="Department_Sector_Name_EN"
                        rules={[{ required: true, message: t('error.field_required') as string }]}
                    >
                        <Textfield
                            label={t('depNameEN') as string}
                            disabled={modalStatus.Type == 'view'}
                        />
                    </Form.Item>
                </Col>
            </Row>

            {!modalStatus?.Open &&
                <Row style={{ justifyContent: 'end' }}>
                    <Col>
                        <Button title={t('save') as string} onClick={() => form.submit()} />
                    </Col>
                </Row>
            }
        </Form>
    )

    return (
        <>
            <TabModal
                children={myForm}
                title={'مدخلات ادارات البلديات / Municipal Administrations Form'}
                link={'/municipality-management-form'}
                formData={formData}
                onOk={() => { form.submit() }}
            />

            {showTabForm && myForm}
        </>
    )
}