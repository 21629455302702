import { apiConfig } from '../apiConfig'

// export const getDepartmentsList = async (at: string) => {
//     const response = await apiConfig.post(
//         '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS/GET_DEPARTMENT_LIST', {},
//         {
//             headers: {
//                 Authorization: `Bearer ${at}`,
//             },
//         }
//     );


//     if (response.data.Status) {
//         return response.data.Data;
//     }

//     return [];
// }

export const changeDepartmentStatus = async (at: string, departmentId: string, status: number) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS/SET_DEPARTMENT_STATUS',
        {
            Department_Id: departmentId,
            Active_Status_Id: status
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
    );

    return response.data;
}

export const updateDepartments = async (
    at: string,
    departmentId: string,
    departmentNo: string,
    departmentCode: string,
    departmentNameAR: string,
    departmentNameEN: string,
    departmentAddressAR: string,
    departmentAddressEN: string,
    departmentDescriptionAR: string,
    departmentDescriptionEN: string,
    activeStatusId: string,
    departmentEmail: string,
    departmentFacebook: string,
    departmentTelephone: string,
    departmentInstagram: string,
    departmentWebsite: string,
    departmentTwitter: string,
    latitudes: string,
    longitudes: string
) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS/UPD_DEPARTMENT',
        {
            Department_Id: departmentId,
            Department_No: departmentNo,
            Department_Code: departmentCode,
            Department_Name_AR: departmentNameAR,
            Department_Name_EN: departmentNameEN,
            Department_Address_AR: departmentAddressAR,
            Department_Address_EN: departmentAddressEN,
            Department_Description_AR: departmentDescriptionAR,
            Department_Description_EN: departmentDescriptionEN,
            Active_Status_Id: activeStatusId,
            Department_Email: departmentEmail,
            Department_Facebook: departmentFacebook,
            Department_Telephone: departmentTelephone,
            Department_Instagram: departmentInstagram,
            Department_Website: departmentWebsite,
            Department_Twitter: departmentTwitter,
            Department_latitudes: latitudes,
            Department_longitudes: longitudes
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
    );

    return response.data;
}

// Sectors
export const getDepartmentsSectorsList = async (at: string, departmentId: string, userId: number) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTSSECTORS/GET_DEPARTMENT_SECTORS_LIST',
        {
            Department_Id: departmentId,
            User_Id: userId,
            PageSize: 10000,
            PageIndex: 1
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );

    if (response.data.Status) {
        for (let i in response.data.Data) {
            if (response.data.Data[i].Active_Status_Id === 1) {
                response.data.Data[i].Active_Status_Id = true;
            } else {
                response.data.Data[i].Active_Status_Id = false;
            }
        }
        return response.data.Data;
    }

    return [];
}

export const setDepartmentSectorStatus = async (at: string, departmentSectorId: string, status: number) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTSSECTORS/UPD_DEPARTMENT_SECTOR_ACTIVE',
        {
            Department_Sector_Id: departmentSectorId,
            Active_Status_Id: status
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
    );

    return response.data;

}

export const deleteDepartmentSector = async (at: string, departmentSectorId: string) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTSSECTORS/DEL_DEPARTMENT_SECTOR',
        {
            Department_Sector_Id: departmentSectorId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );

    return response.data;
}

export const updateDepartmentSector = async (
    at: string,
    departmentId: string,
    departmentCode: string,
    departmentNameAR: string,
    departmentNameEN: string
) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTSSECTORS/UPD_DEPARTMENT_SECTOR',
        {
            Department_Sector_Id: departmentId,
            Department_Sector_Code: departmentCode,
            Department_Sector_Name_AR: departmentNameAR,
            Department_Sector_Name_EN: departmentNameEN,
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
    );

    return response.data;
}

export const addDepartmentSector = async (
    at: string,
    activeStatusId: string,
    departmentCode: string,
    departmentNameAR: string,
    departmentNameEN: string
) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTSSECTORS/ADD_Department_Sector',
        {
            Active_Status_Id: activeStatusId,
            Department_Sector_Code: departmentCode,
            Department_Sector_Name_AR: departmentNameAR,
            Department_Sector_Name_EN: departmentNameEN,
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
    );

    return response.data;
}

// Sections
export const getDepartmentsSectionsList = async (at: string, departmentId: string) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS_SECTIONS/GET_DEPARTMENT_SECTIONS_LIST',
        {
            Department_Id: departmentId,
            PageSize: 100000,
            PageIndex: 1
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );

    if (response.data.Status) {
        for (let i in response.data.Data) {
            if (response.data.Data[i].Active_Status_Id === 1) {
                response.data.Data[i].Active_Status_Id = true;
            } else {
                response.data.Data[i].Active_Status_Id = false;
            }
        }
        return response.data.Data;
    }

    return [];
}

export const setDepartmentSectionStatus = async (at: string, departmentSectionId: string, status: number) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS_SECTIONS/UPD_DEPARTMENT_SECTION_ACTIVE',
        {
            Department_Section_Id: departmentSectionId,
            Active_Status_Id: status
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
    );

    return response.data;

}

export const deleteDepartmentSection = async (at: string, departmentSectionId: string) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS_SECTIONS/DEL_DEPARTMENT_SECTION',
        {
            Department_Section_Id: departmentSectionId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );

    return response.data;
}

export const updateDepartmentSection = async (
    at: string,
    sectionId: string,
    sectionNameAR: string,
    sectionNameEN: string,
    sectionDescAR: string,
    sectionDescEN: string,
    telephone: string,
    sectionStatus: number,
    sectionCode: string,
    municipalAdministrations: string,
) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS_SECTIONS/UPD_DEPARTMENT_SECTION',
        {
            Department_Section_Id: sectionId,
            Department_Section_Name_AR: sectionNameAR,
            Department_Section_Name_EN: sectionNameEN,
            Department_Section_Description_AR: sectionDescAR,
            Department_Section_Description_EN: sectionDescEN,
            Department_Section_Phone: telephone,
            Active_Status_Id: sectionStatus,
            Department_Section_Code: sectionCode,
            Department_Sector_Id: municipalAdministrations
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
    );

    return response.data;
}

export const addDepartmentSection = async (
    at: string,
    departmentId: string,
    sectionNameAR: string,
    sectionNameEN: string,
    sectionDescAR: string,
    sectionDescEN: string,
    telephone: string,
    sectionStatus: number,
    sectionCode: string,
    municipalAdministrations: string,
) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS_SECTIONS/ADD_DEPARTMENT_SECTION',
        {
            Department_Id: departmentId,
            Department_Section_Name_AR: sectionNameAR,
            Department_Section_Name_EN: sectionNameEN,
            Department_Section_Description_AR: sectionDescAR,
            Department_Section_Description_EN: sectionDescEN,
            Department_Section_Phone: telephone,
            Active_Status_Id: sectionStatus,
            Department_Section_Code: sectionCode,
            Department_Sector_Id: municipalAdministrations

        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
    );

    return response.data;
}

export const getDepartmentSectionSectorsDropdown = async (at: string, departmentId: string) => {
    const response = await apiConfig.post(
        '/API/SYSTEM/DDL/GET_DEPARTMENT_SECTORS',
        {
            Department_id: departmentId,
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    )

    return response.data
}

// Centers
export const getDepartmentsCentersList = async (at: string) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS/GET_DEPARTMENT_CENTERS_LIST', {},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );

    if (response.data.Status) {
        for (let i in response.data.Data) {
            if (response.data.Data[i].Active_Status_Id === 1) {
                response.data.Data[i].Active_Status_Id = true;
            } else {
                response.data.Data[i].Active_Status_Id = false;
            }
        }
        return response.data.Data;
    }

    return [];
}

export const setDepartmentCentersStatus = async (at: string, departmentSectionId: string, status: number) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS/SET_DEPARTMENT_CENTER_STATUS',
        {
            Department_Service_Center_Id: departmentSectionId,
            Active_Status_Id: status
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
    );

    return response.data;
}

export const deleteDepartmentCenter = async (at: string, departmentSectionId: string) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS/DEL_DEPARTMENT_CENTER',
        {
            Department_Service_Center_Id: departmentSectionId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );

    return response.data.Data;
}

export const updateDepartmentCenter = async (
    at: string,
    centerId: string,
    centerNameAR: string,
    centerNameEN: string,
    centerAddAR: string,
    centerAddEN: string,
    telephone: string,
    email: string,
    centerLat: number,
    centerLng: string,
    centerStatus: number
) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS/UPD_DEPARTMENT_CENTER',
        {
            Department_Service_Center_Id: centerId,
            Department_Service_Center_Name_AR: centerNameAR,
            Department_Service_Center_Name_EN: centerNameEN,
            Department_Service_Center_Address_AR: centerAddAR,
            Department_Service_Center_Address_EN: centerAddEN,
            Department_Service_Center_Telephone: telephone,
            Department_Service_Center_Email: email,
            Department_Service_Center_latitudes: centerLat,
            Department_Service_Center_longitudes: centerLng,
            Active_Status_Id: centerStatus
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
    );

    return response.data;
}

export const addDepartmentCenter = async (
    at: string,
    departmentId: string,
    centerNameAR: string,
    centerNameEN: string,
    centerAddAR: string,
    centerAddEN: string,
    telephone: string,
    email: string,
    centerLat: number,
    centerLng: string,
    centerStatus: number,
) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS/ADD_DEPARTMENT_CENTER',
        {
            Department_Id: departmentId,
            Department_Service_Center_Name_AR: centerNameAR,
            Department_Service_Center_Name_EN: centerNameEN,
            Department_Service_Center_Address_AR: centerAddAR,
            Department_Service_Center_Address_EN: centerAddEN,
            Department_Service_Center_Telephone: telephone,
            Department_Service_Center_Email: email,
            Department_Service_Center_latitudes: centerLat,
            Department_Service_Center_longitudes: centerLng,
            Active_Status_Id: centerStatus

        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
    );

    return response.data;
}

// Jobs
export const getDepartmentsJobsList = async (at: string, departmentId: string) => {
    const response = await apiConfig.post(
        "/API/LOOKUPS/DEPARTMENTJOBS/GET_DEPARTMENT_JOBS_LIST",
        {
            Department_Id: departmentId,
            PageSize: 100000,
            PageIndex: 1,
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );

    if (response.data.Status) {
        for (let i in response.data.Data) {
            if (response.data.Data[i].Active_Status_Id === 1) {
                response.data.Data[i].Active_Status_Id = true;
            } else {
                response.data.Data[i].Active_Status_Id = false;
            }
        }
        return response.data.Data;
    }

    return [];
}

export const setDepartmentJobStatus = async (at: string, departmentJobId: string, status: number) => {
    const response = await apiConfig.post(
        '/API/LOOKUPS/DEPARTMENTJOBS/UPD_DEPARTMENT_JOB_ACTIVE',
        {
            Department_Job_Id: departmentJobId,
            Active_Status_Id: status
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
    );

    return response.data;

}

export const deleteDepartmentJob = async (at: string, departmentJobId: string) => {
    const response = await apiConfig.post(
        '/API/LOOKUPS/DEPARTMENTJOBS/DEL_DEPARTMENT_JOB',
        {
            Department_Job_Id: departmentJobId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );

    return response.data;
}

export const getJobsTypes = async (at: string, departmentId: string) => {
    const response = await apiConfig.post(
        '/API/SYSTEM/DDL/GET_DEPARTMENT_JOBS_TYPES',
        {
            Department_id: departmentId,
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    )

    return response.data
}

export const updateDepartmentJob = async (
    at: string,
    jobId: string,
    jobNameAR: string,
    jobNameEN: string,
    jobType: string,
    jobSignature: string,
    jobStatus: number
) => {
    const response = await apiConfig.post(
        '/API/LOOKUPS/DEPARTMENTJOBS/UPD_DEPARTMENT_JOB',
        {
            Department_Job_Id: jobId,
            Department_Job_Name_AR: jobNameAR,
            Department_Job_Name_EN: jobNameEN,
            Department_Job_Type_Id: jobType,
            Department_Job_Signature_Text: jobSignature,
            Active_Status_Id: jobStatus
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
    );

    return response.data;
}

export const addDepartmentJob = async (
    at: string,
    departmentId: string,
    jobNameAR: string,
    jobNameEN: string,
    jobType: string,
    jobSignature: string,
    jobStatus: number
) => {
    const response = await apiConfig.post(
        '/API/LOOKUPS/DEPARTMENTJOBS/ADD_DEPARTMENT_JOB',
        {
            Department_Id: departmentId,
            Department_Job_Name_AR: jobNameAR,
            Department_Job_Name_EN: jobNameEN,
            Department_Job_Type_Id: jobType,
            Department_Job_Signature_Text: jobSignature,
            Active_Status_Id: jobStatus

        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
    );

    return response.data;
}

export const getDepartmentJobSectionsList = async (at: string, departmentJobId: string) => {
    const response = await apiConfig.post(
        '/API/LOOKUPS/DEPARTMENTJOBS/GET_DEPARTMENT_JOBS_SECTIONS_LIST',
        {
            Department_Job_Id: departmentJobId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    )

    return response.data.Data
}

export const getDepartmentJobsUsersList = async (at: string, departmentJobId: string) => {
    const response = await apiConfig.post(
        '/API/LOOKUPS/DEPARTMENTJOBS/GET_DEPARTMENT_JOB_USERS_LIST',
        {
            Department_Job_Id: departmentJobId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    )

    return response.data.Data
}

export const deleteDepartmentJobSection = async (at: string, jobSectionId: string) => {
    const response = await apiConfig.post(
        '/API/LOOKUPS/DEPARTMENTJOBS/DEL_DEPARTMENT_JOB_SECTION',
        {
            Department_Job_Section_Id: jobSectionId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );

    return response.data
}

export const addDepartmentJobSection = async (at: string, departmentJobId: string, departmentSectionId: string) => {
    const response = await apiConfig.post(
        '/API/LOOKUPS/DEPARTMENTJOBS/ADD_DEPARTMENT_JOB_SECTION',
        {
            Department_Job_Id: departmentJobId,
            Department_Section_Id: departmentSectionId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );

    return response.data
}
