
const ButterFlyIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="16" y="2" width="10" height="10" rx="2" fill="#D0B166" />
        <path d="M12.225 13.1085H5.10829C3.93029 13.1085 2.80053 12.6405 1.96756 11.8075C1.13459 10.9746 0.666626 9.84481 0.666626 8.66681V5.10848C0.666626 3.93047 1.13459 2.80072 1.96756 1.96774C2.80053 1.13477 3.93029 0.666809 5.10829 0.666809H8.66663C9.84463 0.666809 10.9744 1.13477 11.8074 1.96774C12.6403 2.80072 13.1083 3.93047 13.1083 5.10848V12.2168C13.1083 12.4519 13.0155 12.6774 12.8501 12.8444C12.6847 13.0114 12.46 13.1063 12.225 13.1085ZM5.10829 2.44181C4.40105 2.44181 3.72277 2.72276 3.22267 3.22286C2.72258 3.72295 2.44163 4.40123 2.44163 5.10848V8.66681C2.44163 9.37405 2.72258 10.0523 3.22267 10.5524C3.72277 11.0525 4.40105 11.3335 5.10829 11.3335H11.3333V5.10848C11.3333 4.40123 11.0523 3.72295 10.5522 3.22286C10.0521 2.72276 9.37387 2.44181 8.66663 2.44181H5.10829Z" fill="#011224" />
        <path d="M22.8916 13.1084H15.775C15.5385 13.1084 15.3117 13.0144 15.1445 12.8472C14.9772 12.68 14.8833 12.4532 14.8833 12.2167V5.10836C14.8833 4.52437 14.9985 3.94611 15.2222 3.40668C15.4459 2.86725 15.7739 2.37724 16.1872 1.96468C16.6005 1.55212 17.0911 1.22513 17.631 1.0024C18.1708 0.779678 18.7493 0.665599 19.3333 0.666695H22.8916C24.0696 0.666695 25.1994 1.13465 26.0324 1.96763C26.8653 2.8006 27.3333 3.93036 27.3333 5.10836V8.6667C27.3333 9.8447 26.8653 10.9745 26.0324 11.8074C25.1994 12.6404 24.0696 13.1084 22.8916 13.1084ZM16.6666 11.3334H22.8916C23.5989 11.3334 24.2772 11.0524 24.7773 10.5523C25.2773 10.0522 25.5583 9.37394 25.5583 8.6667V5.10836C25.5583 4.40112 25.2773 3.72284 24.7773 3.22274C24.2772 2.72265 23.5989 2.44169 22.8916 2.44169H19.3333C18.6261 2.44169 17.9478 2.72265 17.4477 3.22274C16.9476 3.72284 16.6666 4.40112 16.6666 5.10836V11.3334Z" fill="#011224" />
        <path d="M8.66663 27.3334H5.10829C3.93029 27.3334 2.80053 26.8654 1.96756 26.0325C1.13459 25.1995 0.666626 24.0697 0.666626 22.8917V19.3334C0.666626 18.1554 1.13459 17.0256 1.96756 16.1927C2.80053 15.3597 3.93029 14.8917 5.10829 14.8917H12.2166C12.4531 14.8917 12.6799 14.9857 12.8471 15.1529C13.0143 15.3201 13.1083 15.5469 13.1083 15.7834V22.8917C13.1083 24.0697 12.6403 25.1995 11.8074 26.0325C10.9744 26.8654 9.84463 27.3334 8.66663 27.3334ZM5.10829 16.6667C4.40105 16.6667 3.72277 16.9477 3.22267 17.4478C2.72258 17.9479 2.44163 18.6261 2.44163 19.3334V22.8917C2.44163 23.599 2.72258 24.2772 3.22267 24.7773C3.72277 25.2774 4.40105 25.5584 5.10829 25.5584H8.66663C9.37387 25.5584 10.0521 25.2774 10.5522 24.7773C11.0523 24.2772 11.3333 23.599 11.3333 22.8917V16.6667H5.10829Z" fill="#011224" />
        <path d="M22.8916 27.3334H19.3333C18.1553 27.3334 17.0255 26.8654 16.1925 26.0324C15.3596 25.1995 14.8916 24.0697 14.8916 22.8917V15.775C14.8916 15.5385 14.9855 15.3117 15.1528 15.1445C15.32 14.9773 15.5468 14.8834 15.7833 14.8834H22.8916C23.4756 14.8834 24.0539 14.9985 24.5933 15.2223C25.1327 15.446 25.6227 15.7739 26.0353 16.1872C26.4478 16.6006 26.7748 17.0912 26.9976 17.6311C27.2203 18.1709 27.3344 18.7494 27.3333 19.3334V22.8917C27.3333 24.0697 26.8653 25.1995 26.0323 26.0324C25.1994 26.8654 24.0696 27.3334 22.8916 27.3334ZM16.6666 16.6667V22.8917C16.6666 23.5989 16.9476 24.2772 17.4477 24.7773C17.9477 25.2774 18.626 25.5584 19.3333 25.5584H22.8916C23.5988 25.5584 24.2771 25.2774 24.7772 24.7773C25.2773 24.2772 25.5583 23.5989 25.5583 22.8917V19.3334C25.5583 18.6261 25.2773 17.9478 24.7772 17.4477C24.2771 16.9476 23.5988 16.6667 22.8916 16.6667H16.6666Z" fill="#011224" />
        </svg>
        
    )

}

export default ButterFlyIcon;