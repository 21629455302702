import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { getSuspensionsList } from 'api/suspensions/suspensions'
import { useAppSelector, useAppDispatch } from 'store'
import GridExpress from 'components/GridExpress/GridExpress'
import CustomStore from 'devextreme/data/custom_store'
import { triggerModalTab } from 'features/utils/modalTabSlice'
import SuspensionsAdministrationForm from './SuspensionsAdministrationForm'
import moment from 'moment'

export default function SuspensionsAdministrationList() {
    const { t, i18n } = useTranslation()
    const currentUser = useAppSelector(state => state.currentUser)
    const modalStatus = useAppSelector(state => state.modalTab)
    const [showModal, setShowModal] = useState<any>(false)
    const dispatch = useAppDispatch()

    useEffect(() => {
        setShowModal(modalStatus?.Open)
    }, [modalStatus])

    const columnsList = () => {
        let ClientName = 'Client_Name_EN',
            DealingTypeName = 'Dealing_Type_Name_EN',
            ViolationsStatusName = 'Violations_Status_Name_EN'

        if (i18n.language == 'ar') {
            ClientName = 'Client_Name_AR'
            DealingTypeName = 'Dealing_Type_Name_AR'
            ViolationsStatusName = 'Violations_Status_Name_AR'

        }

        return [
            {
                dataField: "Violation_No",
                caption: t('suspensionNo'),
                width: '150px'
            },
            {
                dataField: "Violation_Date",
                caption: t('suspensionDate'),
                width: '100px',
                cellRender: (data: any) => moment(data.Mission_Date).format('YYYY-MM-DD')
            },
            {
                dataField: ClientName,
                caption: t('clientName'),
                // width: '250px'
            },
            {
                dataField: DealingTypeName,
                caption: t('clientType'),
                width: '150px'
            },
            {
                dataField: ViolationsStatusName,
                caption: t('suspensionStatus'),
                width: '100px'
            }
        ]
    }

    const jsonDataSource = new CustomStore({
        key: "Violation_Id",
        loadMode: 'raw',
        load: () => {
            return getSuspensionsList(currentUser.Data.Data.access_token)
        }
    })

    return (
        <>
            <Row>
                <Col>
                    <GridExpress
                        allowSelectAll
                        allowAdd={true}
                        allowView={true}
                        allowEdit={true}
                        allowDelete={false}
                        allowEditException={{ Violations_Status_Name_EN: 'Canceled' }}
                        columnsList={columnsList()}
                        dataSource={jsonDataSource}
                        handleView={(data) => dispatch(triggerModalTab({ Open: true, Data: data, Type: 'view' }))}
                        handleEdit={(data) => dispatch(triggerModalTab({ Open: true, Data: data, Type: 'edit' }))}
                        handleAdd={(data) => dispatch(triggerModalTab({ Open: true, Data: data, Type: 'add' }))}
                        gridTitle={t('manageSuspensions') as string}
                        groupPanelVisibility={false}
                        xlsxFileName={t('manageSuspensions') as string}
                    >
                    </GridExpress>
                </Col>
            </Row>

            {showModal && <SuspensionsAdministrationForm />}
        </>
    )
}