import { apiConfig } from '../apiConfig'

export const getSuspensionsList = async (at: string) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/VIOLATIONS/GET_VIOLATION_LIST',
        {
            PageIndex: 1,
            PageCount: 1000
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );


    if (response.data.Status) {
        return response.data.Data.Data;
    }

    return []
}

export const getAllSuspensionsList = async (at: string) => {
    const response = await apiConfig.post(
        "/API/ADMINISTRATION/VIOLATIONS/GET_VIOLATION_ALL_LIST",
        {
            pageCount: 10000,
            PageIndex: 1,
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );

    if (response.data.Status) {
        return response.data.Data.Data;
    }

    return []
}

export const getClientsList = async (at: string, url: string) => {
    const response = await apiConfig.post(
        url,
        {},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    )

    if (response.data.Status) {
        return response.data.Data;
    }

    return []
}

export const getSuspensionsAttachmentList = async (at: string, ViolationId: number) => {
    const response = await apiConfig.post(
        'API/ADMINISTRATION/VIOLATIONS/GET_VIOLATION_ATTACHMENT_LIST',
        {
            Violation_Id: ViolationId,
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );


    if (response.data.Status) {
        return response.data.Data;
    }

    return []
}

export const downloadViolationAttachmentFile = async (at: string, fileCode: string) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/VIOLATIONS/DOWNLOAD_VIOLATION_ATTACHMENT_FILE',
        {
            File_Code: fileCode
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );

    return response.data;
}

export const cancelSuspensions = async (at: string, violationId: number) => {
    const response = await apiConfig.post(
        "/API/ADMINISTRATION/VIOLATIONS/CANCEL_VIOLATION",
        {
            Violation_Id: violationId,
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );

    return response;
}

export const deleteSuspensionAttachment = async (at: string, violationId: number) => {
    const response = await apiConfig.post(
        "/API/ADMINISTRATION/VIOLATIONS/DEL_VIOLATION_ATTACHMENT",
        {
            Violation_Attach_Id: violationId,
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );

    return response;
};
