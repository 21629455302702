const Certificate = () => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.669 26.7789H4.9043V28.9112H23.669V26.7789Z" fill="#219EBC" />
            <path d="M17.6629 9.6134H11.0882C10.4993 9.6134 10.022 10.0907 10.022 10.6796C10.022 11.2685 10.4993 11.7458 11.0882 11.7458H17.6629C18.2518 11.7458 18.7291 11.2685 18.7291 10.6796C18.7291 10.0907 18.2518 9.6134 17.6629 9.6134Z" fill="#219EBC" />
            <path d="M22.6384 14.5889H11.0882C10.4993 14.5889 10.022 15.0662 10.022 15.6551C10.022 16.244 10.4993 16.7213 11.0882 16.7213H22.6384C23.2273 16.7213 23.7046 16.244 23.7046 15.6551C23.7046 15.0662 23.2273 14.5889 22.6384 14.5889Z" fill="#219EBC" />
            <path d="M28.3717 0.218655C27.9889 0.0466372 27.5415 0.114549 27.2273 0.392088L24.2417 3.03335L21.2525 0.360426C20.8477 -0.0017339 20.2357 -0.0017339 19.8309 0.360426L16.8456 3.03013L13.86 0.360426C13.4552 -0.0017339 12.8435 -0.0017339 12.4387 0.360426L9.44952 3.03335L6.46388 0.392088C6.1501 0.114889 5.70229 0.0469771 5.31952 0.218655C4.93714 0.391012 4.6912 0.771297 4.6912 1.19066V20.1686H1.0662C0.477311 20.1686 0 20.6459 0 21.2348V23.8291C0 26.6314 2.27984 28.9112 5.08208 28.9112V26.7789C3.45547 26.7789 2.13235 25.4554 2.13235 23.8291V22.301H18.8358V23.8291C18.8358 26.6314 21.1156 28.9113 23.9179 28.9113C26.7201 28.9113 28.9999 26.6314 28.9999 23.8292V1.19061C29 0.771241 28.7541 0.391012 28.3717 0.218655ZM26.8677 23.8291C26.8677 25.4554 25.5442 26.7789 23.9179 26.7789C22.2917 26.7789 20.9682 25.4554 20.9682 23.8291V21.2348C20.9682 20.6459 20.4909 20.1686 19.902 20.1686H6.82355V3.55717L8.74729 5.25916C9.15244 5.61741 9.76156 5.6156 10.1642 5.25525L13.1495 2.58555L16.1348 5.25491C16.5396 5.61707 17.1516 5.61707 17.5564 5.25491L20.542 2.58521L23.5273 5.25491C23.9303 5.61526 24.5395 5.61668 24.9442 5.25882L26.8677 3.55722V23.8291Z" fill="#219EBC" />
        </svg>
    )
}

export default Certificate;