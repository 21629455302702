import React, { useState, useEffect, ReactNode } from 'react';
import { Button as AntdButton } from 'antd';
import styled from 'styled-components';

const StyledButton = styled(AntdButton) <{ btncolor: string | any, icon?: ReactNode | string, iconstyle: string, width: string, height: string , bgColor: string}>`
    border-radius: 15px;
    border: none;
    width: ${props => props.width}!important;
    height: ${props => props.height};
    display: flex;
    align-items: center;
    justify-content: center;
    ${props => props.btncolor == 'default' ? `
        background: ${convertHexToRGBA(props.theme.antd.colorSplit)};
        & path {
            ${props.icon == Edit || props.icon == Eye || props.iconstyle == 'outline' ?
            `stroke: ${props.theme.antd.colorSplit};
            fill: transparent;`
            :
            `fill: ${props.theme.antd.colorSplit};
            stroke: transparent;`
        }
        }`
        : props.btncolor == 'error' ? `
        background: ${props.bgColor ? props.bgColor : convertHexToRGBA(props.theme.antd.colorError)};
        & path {
            ${props.icon == Edit || props.icon == Eye || props.iconstyle == 'outline' ?
                `stroke: ${props.theme.antd.colorError};
                fill: transparent;`
                :
                `fill: ${props.theme.antd.colorError};
                stroke: transparent;`
            }
        }`
            : props.btncolor == 'warning' ? `
            background: ${convertHexToRGBA(props.theme.antd.colorPrimary)};
            & path {
                ${props.icon == Edit || props.icon == Eye || props.iconstyle == 'outline' ?
                    `stroke: ${props.theme.antd.colorPrimary};
                    fill: transparent;`
                    :
                    `fill: ${props.theme.antd.colorPrimary};
                    stroke: transparent;`
                }
            }`
                : props.btncolor == 'success' ? `
                background: ${convertHexToRGBA(props.theme.antd.colorSuccess)};
                & path {
                    ${props.icon == Edit || props.icon == Eye || props.iconstyle == 'outline' ?
                        `stroke: ${props.theme.antd.colorSuccess};
                        fill: transparent;`
                        :
                        `fill: ${props.theme.antd.colorSuccess};
                        stroke: transparent;`
                    }
                }`
                    : props.btncolor == 'info' ? `
                    background: #CCE5EB;
                    & path {
                        ${props.icon == Edit || props.icon == Eye || props.iconstyle == 'outline' ?
                            `stroke: #219EBC;
                            fill: transparent;`
                            :
                            `fill: #219EBC;
                            stroke: transparent;`
                        }
                    }`
                        : props.btncolor == 'primary' ? `
                        background: ${props.theme.antd.colorPrimary};
                            & path {
                                ${props.icon == Edit || props.icon == Eye || props.iconstyle == 'outline' ?
                                `stroke: #FFFFFF;
                                    fill: transparent;`
                                :
                                `fill: #FFFFFF;
                                    stroke: transparent;`
                            }
                            }`
                            : null}
`
const convertHexToRGBA = (hexCode: string, opacity = 0.15) => {
    let hex = hexCode.replace('#', '');
    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    if (opacity > 1 && opacity <= 100) {
        opacity = opacity / 100;
    }
    return `rgba(${r},${g},${b},${opacity})`;
}

const Pin = (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.7837 4.86874L12.7646 0.1285C12.5835 -0.0427927 12.2893 -0.0427927 12.1081 0.1285L12.0868 0.148585C11.779 0.43916 11.6095 0.825687 11.6095 1.23668C11.6095 1.50147 11.6807 1.75556 11.813 1.98088L6.56142 6.23759C6.15949 5.89834 5.6465 5.71266 5.10313 5.71266C4.51761 5.71266 3.96719 5.92798 3.55328 6.31898L3.5218 6.34872C3.34043 6.51992 3.34043 6.79761 3.5218 6.96881L6.52864 9.8086L3.60018 12.5743C3.54158 12.6312 2.15545 13.9802 1.24415 15.0538C0.376308 16.0758 0.20469 16.2631 0.195774 16.2727C0.0346505 16.446 0.0435657 16.7074 0.216019 16.8711C0.306379 16.9568 0.425527 17.0001 0.545046 17.0001C0.653979 17.0001 0.763191 16.9641 0.851043 16.8916C0.858751 16.8853 1.05247 16.7263 2.14171 15.9015C3.27821 15.0408 4.70659 13.7316 4.77151 13.6719L7.69523 10.9106L10.541 13.5983C10.6317 13.684 10.7506 13.7268 10.8693 13.7268C10.9881 13.7268 11.1071 13.684 11.1976 13.5983L11.2291 13.5685C11.6431 13.1776 11.8711 12.6577 11.8711 12.1048C11.8711 11.5916 11.6744 11.1071 11.3153 10.7275L15.8224 5.76766C16.061 5.89264 16.33 5.95991 16.6104 5.95991C17.0456 5.95991 17.4548 5.79984 17.7625 5.50909L17.7837 5.48901C17.9651 5.31763 17.9651 5.03995 17.7837 4.86874Z" />
    </svg>
)

const Delete = (
    <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.66663 18.399C4.08653 18.3964 3.5302 18.1323 3.11646 17.6631C2.70272 17.1938 2.46432 16.5566 2.45202 15.8873C2.21532 13.3558 1.81714 7.37185 1.81714 7.31115C1.80887 7.13067 1.86048 6.95332 1.96138 6.8151C2.01073 6.74995 2.07146 6.69763 2.13961 6.66155C2.20777 6.62547 2.28183 6.60651 2.35681 6.60585H12.8349C12.9097 6.60748 12.9835 6.62688 13.0515 6.66289C13.1195 6.69891 13.1804 6.75071 13.2303 6.8151C13.2806 6.88308 13.3186 6.96204 13.3421 7.04722C13.3656 7.13241 13.3742 7.22211 13.3672 7.31115C13.3672 7.37185 12.9645 13.3652 12.7323 15.8873C12.72 16.5672 12.4743 17.2136 12.0492 17.6845C11.6241 18.1555 11.0543 18.4124 10.4651 18.399C9.49025 18.422 8.53356 18.4314 7.59041 18.4314C6.59012 18.4335 5.61432 18.4251 4.66663 18.399ZM1.32927 5.17731C1.18301 5.17211 1.04444 5.10074 0.943467 4.97854C0.84249 4.85633 0.787248 4.69316 0.789701 4.52433V4.18631C0.786292 4.01716 0.841183 3.85339 0.94236 3.73095C1.04354 3.6085 1.1827 3.53737 1.32927 3.53326H3.53204C3.75408 3.52894 3.96823 3.43713 4.1388 3.27304C4.30937 3.10894 4.42616 2.88244 4.4698 2.63117L4.58493 2.02526C4.6565 1.64189 4.83809 1.29759 5.10026 1.04805C5.36242 0.798508 5.68984 0.65828 6.02961 0.650116H9.14833C9.48184 0.657851 9.80373 0.79305 10.0635 1.03451C10.3233 1.27597 10.5063 1.61007 10.5839 1.98444L10.7073 2.63327C10.7506 2.88482 10.8673 3.11164 11.0379 3.27597C11.2085 3.44031 11.4228 3.53221 11.645 3.53639H13.8478C13.9205 3.53748 13.9923 3.5552 14.0591 3.58845C14.1259 3.6217 14.1863 3.66987 14.2369 3.73018C14.2875 3.79049 14.3272 3.86171 14.3538 3.93982C14.3804 4.01792 14.3934 4.10131 14.392 4.18522V4.52324C14.3942 4.69182 14.339 4.8547 14.2382 4.97681C14.1375 5.09893 13.9993 5.17055 13.8533 5.17629L1.32927 5.17731Z" />
    </svg>
)

const Close = (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.13754 11.9249C0.843336 11.942 0.553996 11.8442 0.330533 11.6521C-0.110178 11.2087 -0.110178 10.4927 0.330533 10.0494L9.98066 0.399214C10.439 -0.0297067 11.1583 -0.00586323 11.5872 0.452515C11.9751 0.867025 11.9977 1.50414 11.6402 1.94507L1.93317 11.6521C1.71259 11.8414 1.42789 11.939 1.13754 11.9249Z" fill="#F23E50" />
        <path d="M10.7763 11.9248C10.4781 11.9236 10.1923 11.8052 9.98061 11.5952L0.330442 1.94503C-0.0778544 1.46823 -0.0223444 0.750684 0.45445 0.342351C0.880002 -0.022076 1.5076 -0.022076 1.93312 0.342351L11.6401 9.99252C12.0984 10.4215 12.1221 11.1409 11.693 11.5991C11.676 11.6174 11.6583 11.635 11.6401 11.6521C11.4024 11.8587 11.0896 11.9575 10.7763 11.9248Z" fill="#F23E50" />
    </svg>

)

const Search = (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.0389 19.0085L16.6022 15.6478L16.521 15.5249C16.3692 15.3755 16.1652 15.2918 15.9527 15.2918C15.7401 15.2918 15.5361 15.3755 15.3843 15.5249C13.9551 16.8215 12.114 17.5672 10.1887 17.6293C8.26331 17.6915 6.3784 17.066 4.86916 15.8642C3.37748 14.6878 2.37612 12.9954 2.06059 11.1175C1.74507 9.23968 2.13807 7.31119 3.16281 5.70859C4.21696 4.09207 5.81034 2.90553 7.65713 2.36187C9.50392 1.81821 11.4832 1.95298 13.24 2.74202C14.9855 3.49571 16.3918 4.87088 17.1882 6.60289C17.9846 8.3349 18.1148 10.3014 17.5538 12.124C17.5122 12.2573 17.5072 12.3994 17.5393 12.5354C17.5714 12.6713 17.6394 12.796 17.7362 12.8964C17.8344 12.9985 17.9571 13.0736 18.0926 13.1143C18.228 13.155 18.3716 13.16 18.5095 13.1289C18.6465 13.0998 18.7734 13.0352 18.8776 12.9414C18.9817 12.8476 19.0595 12.7279 19.1032 12.5944C19.7751 10.4298 19.638 8.09349 18.7175 6.02308C17.797 3.95267 16.1562 2.29012 14.1025 1.34694C12.0343 0.36344 9.68794 0.139351 7.47207 0.713632C5.2562 1.28791 3.31115 2.62426 1.97554 4.48992C0.673036 6.33583 0.0919218 8.59804 0.342745 10.8461C0.593569 13.0942 1.65883 15.1712 3.33585 16.682C5.03752 18.2222 7.22195 19.1167 9.51114 19.2106C11.8003 19.3046 14.0502 18.5921 15.8715 17.1965L18.9155 20.1724C19.0684 20.3195 19.272 20.4017 19.4838 20.4017C19.6957 20.4017 19.8993 20.3195 20.0522 20.1724C20.1258 20.1005 20.1846 20.0148 20.225 19.92C20.2655 19.8253 20.287 19.7235 20.2881 19.6204C20.2892 19.5173 20.27 19.4149 20.2315 19.3193C20.1931 19.2237 20.1362 19.1368 20.0642 19.0633L20.0522 19.0526L20.0389 19.0085Z" fill="#219EBC" />
    </svg>

)

const Eye = (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.8348 10C14.8348 11.98 13.2348 13.58 11.2548 13.58C9.2748 13.58 7.6748 11.98 7.6748 10C7.6748 8.02001 9.2748 6.42001 11.2548 6.42001C13.2348 6.42001 14.8348 8.02001 14.8348 10Z" stroke="#D0B166" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.2547 18.27C14.7847 18.27 18.0747 16.19 20.3647 12.59C21.2647 11.18 21.2647 8.81 20.3647 7.4C18.0747 3.8 14.7847 1.72 11.2547 1.72C7.72473 1.72 4.43473 3.8 2.14473 7.4C1.24473 8.81 1.24473 11.18 2.14473 12.59C4.43473 16.19 7.72473 18.27 11.2547 18.27Z" stroke="#D0B166" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

const Edit = (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1549 3.3L4.62907 11.2658C4.3449 11.5683 4.0699 12.1642 4.0149 12.5767L3.67574 15.5467C3.55657 16.6192 4.32657 17.3525 5.3899 17.1692L8.34157 16.665C8.75407 16.5917 9.33157 16.2892 9.61574 15.9775L17.1416 8.01166C18.4432 6.63666 19.0299 5.06916 17.0041 3.15333C14.9874 1.25583 13.4566 1.925 12.1549 3.3V3.3Z" stroke="#4E6B31" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.8992 4.62918C11.0913 5.8583 11.6862 6.9888 12.5904 7.84324C13.4946 8.69768 14.657 9.22767 15.895 9.35001M2.75 20.1667H19.25" stroke="#4E6B31" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

const Erase = (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.2075 14.0505L16.9887 7.26923C17.3399 6.91741 17.5371 6.44063 17.5371 5.94355C17.5371 5.44646 17.3399 4.96968 16.9887 4.61786L13.131 0.760671C12.7792 0.409507 12.3024 0.21228 11.8053 0.21228C11.3082 0.21228 10.8315 0.409507 10.4796 0.760671L3.69839 7.54192C3.4744 7.76689 3.31137 8.04519 3.22467 8.35059C3.13797 8.65599 3.13045 8.97844 3.20283 9.28755L0.613332 11.877C0.262156 12.2291 0.0649414 12.7061 0.0649414 13.2033C0.0649414 13.7006 0.262156 14.1776 0.613332 14.5296L3.21958 17.1359C3.39448 17.3109 3.6024 17.4494 3.83127 17.5434C4.06014 17.6375 4.3054 17.6851 4.55283 17.6836C4.65898 17.7714 4.79256 17.8193 4.93033 17.8188H13.6803C13.8461 17.8188 14.0051 17.7529 14.1223 17.6357C14.2395 17.5185 14.3053 17.3596 14.3053 17.1938C14.3053 17.028 14.2395 16.8691 14.1223 16.7519C14.0051 16.6346 13.8461 16.5688 13.6803 16.5688H6.43914L8.46164 14.5463C8.7708 14.6187 9.0933 14.6111 9.39873 14.5244C9.70417 14.4376 9.98248 14.2745 10.2075 14.0505ZM1.49739 13.6458C1.38022 13.5283 1.31442 13.3692 1.31442 13.2033C1.31442 13.0374 1.38022 12.8783 1.49739 12.7608L3.88208 10.3762L7.37327 13.8674L4.98864 16.252C4.86915 16.3652 4.71084 16.4283 4.54627 16.4283C4.3817 16.4283 4.22339 16.3652 4.10389 16.252L1.49739 13.6458Z" fill="#F23E50" />
    </svg>

)

type CustomIconButton = {
    icon?: ReactNode | 'pin' | 'delete' | 'close' | 'search' | 'eye' | 'edit' | 'erase'
    disabled?: boolean
    btncolor?: 'info' | 'success' | 'warning' | 'error' | 'default' | 'primary'
    onClick?: () => void
    iconstyle?: 'outline' | 'fill'
    width?: string
    height?: string
    bgColor?: string
}

export default function IconButton({
    icon = Delete,
    disabled,
    btncolor = 'default',
    iconstyle = 'fill',
    width = '40px',
    height = '40px',
    bgColor = '',
    onClick = () => { }
}: CustomIconButton) {

    const icons = () => {
        switch (icon) {
            case 'pin':
                return Pin
            case 'delete':
                return Delete
            case 'close':
                return Close
            case 'search':
                return Search
            case  'eye':
                return Eye
            case  'edit':
                return Edit
            case 'erase':
                return Erase
            default:
                return icon
        }
    }

    return (
        <StyledButton
            icon={icons()}
            disabled={disabled}
            btncolor={btncolor}
            iconstyle={iconstyle}
            bgColor={bgColor}
            width={width}
            height={height}
            onClick={onClick}
        />
    )
}