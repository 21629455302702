import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FileImageOutlined, DownloadOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons'

const AttachmentListWrapper = styled.div``

const Label = styled.div<{ formfield: boolean }>`
    font-family: 'Almarai';
    font-style: normal;
    font-weight: ${props => props.formfield ? '600' : '400'};
    fontSize: 16px;
    color: ${props => props.formfield ? props.theme.antd.colorTextLabel : '#000'};
    text-align: start;
    padding: 12px;
`
const List = styled.div<{}>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F5F5F5;
    border-radius: 15px;
    height: 43px;
    border: 1px solid #e3e0e0;
    padding: 4px 11px;
    margin-bottom: 10px;

    div {
        display: flex;
    }

    div:first-child div {
        width: 100%;
        padding: 0 8px;
    }

    div:last-child {
        width: 40px;
        justify-content: end;
    }

    div:last-child > div {
        cursor: pointer;
        margin: 0 5px;
    }
`

type FilesListProps = {
    attachmentList?: {}[]
    label?: string
    formfield?: boolean
    canDownload?: boolean
    canDelete?: boolean
    canView?: boolean
    downloadAPI?: string
    deleteAPI?: string
    onDelete?: (attachment: any) => void
    onDownload?: (attachment: any) => void
    onView?: (attachment: any) => void
    fileName?: string
}

export default function FilesUploadedList({
    attachmentList,
    label,
    formfield = true,
    canDownload,
    canDelete,
    canView,
    onDownload = () => { },
    onDelete = () => { },
    onView = () => { },
    fileName
}: FilesListProps) {

    return (
        <AttachmentListWrapper>
            <>
                {label && <Label formfield={formfield}>{label}</Label>}

                {attachmentList && attachmentList?.map((L: any, i: number) => (
                    <List
                        key={i}
                    >
                        <div>
                            <FileImageOutlined />
                            <div>{fileName && L[fileName]}</div>
                        </div>

                        <div>
                            {canDelete &&
                                <div onClick={() => onDelete(L)}>
                                    <DeleteOutlined />
                                </div>
                            }

                            {canDownload &&
                                <div onClick={() => onDownload(L)}>
                                    <DownloadOutlined />
                                </div>
                            }

                            {canView &&
                                <div onClick={() => onView(L)}>
                                    <EyeOutlined />
                                </div>
                            }
                        </div>
                    </List>
                ))}
            </>
        </AttachmentListWrapper>
    )
}