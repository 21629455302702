import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import {
    getDepartmentJobsUsersList,
    deleteDepartmentJobSection,
    addDepartmentJobSection
} from 'api/departments/departments'
import GridExpress from 'components/GridExpress/GridExpress'
import Switch from 'components/Switch'
import CustomStore from 'devextreme/data/custom_store'
import { triggerLoading } from 'features/utils/loadingSlice'
import { useAppSelector, useAppDispatch } from 'store'

export default function JobGroupWorkersNameList(props: any) {

    const { t, i18n } = useTranslation()
    const currentUser = useAppSelector(state => state.currentUser)

    const customizeCell = (data: any) => {
        return (
            <>
                {data.data?.Main_Profile ?
                    <span
                        style={{
                            background: 'red',
                            color: '#fff',
                            padding: '4px 8px',
                            borderRadius: '5px',
                        }}
                    >
                        Main
                    </span>
                    : null}
            </>
        )
    }

    const columnsList = () => {
        return [
            {
                dataField: 'User_Account_Id',
                caption: t('profileNo'),
            },
            {
                dataField: i18n.language == 'ar' ? 'User_Name_AR' : 'User_Name_EN',
                caption: t('employeeName'),
            },
            {
                dataField: 'Main_Profile',
                caption: t('mainProfile'),
                cellRender: customizeCell
            },
            {
                dataField: 'User_Mail',
                caption: t('email'),
            },
            {
                dataField: 'Login_Name',
                caption: t('loginName'),
            },
            {
                dataField: 'User_Signature_Text',
                caption: t('profileSignature'),
            },
        ]
    }

    const handleExportCustomization = (options: any) => {
        if (options.gridCell.rowType === 'data' && options.gridCell.column.dataField == "Main_Profile") {
            if (options.gridCell.value == 1) {
                options.excelCell.value = "Main"
                options.excelCell.font = {
                    color: { argb: 'ff0000' }
                };
            } else {
                options.excelCell.value = " "
            }
        }
    }

    const jsonDataSource = new CustomStore({
        key: "User_Id",
        loadMode: 'raw',
        load: () => {
            return getDepartmentJobsUsersList(currentUser.Data.Data.access_token, props.departmentJobId)
        }
    })

    return (
        <>
            <Row>
                <Col>
                    <GridExpress
                        allowSelectAll={false}
                        allowView={false}
                        allowEdit={false}
                        allowDelete={false}
                        columnsList={columnsList()}
                        dataSource={jsonDataSource}
                        groupPanelVisibility={false}
                        gridTitle={''}
                        xlsxFileName={t('jobGroupWorkersName') as string}
                        handleWordCustomization={(options: any) => handleExportCustomization(options)}
                    >
                    </GridExpress>
                </Col>
            </Row>
        </>
    )
}