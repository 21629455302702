import React, { ReactNode, useState } from 'react'
import { Button, Upload, Progress } from 'antd'
import { useTranslation } from 'react-i18next'
import 'i18n'
import styled from 'styled-components'
import type { UploadFile } from 'antd/es/upload/interface'
import type { UploadProps } from 'antd/es/upload'

interface IUploadProps extends UploadProps {
  label?: string;
  titleStyle?: string;
  uploaderContainerStyle?: string;
  uploadCustomButton?: ReactNode;
  formfield?: boolean;
  multiple?: boolean;
  status?: string;
  onChange?: (data: any) => void
  handleUpload?: (data: any) => void
  defaultFile?: string
  maxCount?: number
}

const FileUploader: React.FC<IUploadProps> = ({ ...props }) => {
  const { i18n } = useTranslation()
  const buttonText = i18n.language === "ar" ? "اختر الملف" : "Choose File"
  const {
    label = i18n.language === "ar" ? "ارفق ملف" : "Attach File",
    titleStyle = "",
    uploaderContainerStyle = "",
    uploadCustomButton,
    formfield,
    defaultFile,
    maxCount,
    multiple,
    onChange = () => { },
    handleUpload = () => { },
    ...rest
  } = props

  const [uploadedList, setUploadedList] = useState<UploadFile[]>([])

  const handleChange: UploadProps['onChange'] = (options) => {
    setUploadedList(options?.fileList)
    options?.fileList.map((file: any, i: number) => {
      if (file.status == 'done') {
        options?.fileList?.splice(i, 1)
      }
    })
  }

  let showUploadList = {
    showDownloadIcon: false,
    showRemoveIcon: false
  }

  let progress = {
    strokeColor: {
      '0%': '#d8b86a',
      '100%': '#d8b86a',
    },
    strokeWidth: 5,
    format: (percent: any) => percent && `${parseFloat(percent.toFixed(2))}%`
  }

  return (
    <>
      <Upload
        {...rest}
        customRequest={handleUpload}
        onChange={handleChange}
        maxCount={maxCount}
        multiple={multiple}
        showUploadList={showUploadList}
        fileList={uploadedList}
        progress={progress}
      >
        <div className={`titleCustom ${titleStyle}`}>{label}</div>
        <span className={`uploaderContainerCustom ${uploaderContainerStyle}`}>
          {uploadCustomButton ? (
            uploadCustomButton
          ) : (
            <Button type="primary" icon={uploadIcon} className="buttonStyle">
              {buttonText}
            </Button>
          )}
        </span>
      </Upload>
    </>
  );
};

const uploadIcon = (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1350_4387)">
      <path
        d="M5.21094 16.1723H7.90625C8.40246 16.1723 8.80469 15.7701 8.80469 15.2739C8.80469 14.7777 8.40246 14.3754 7.90625 14.3754H5.21094C3.22934 14.3754 1.79688 12.7633 1.79688 10.7817C1.79688 9.07651 2.82896 7.59539 4.49848 7.25996C4.9238 7.17452 5.22729 6.7974 5.21983 6.36368C5.21898 6.31642 5.21516 6.27536 5.21121 6.2436C5.23587 3.78759 7.24141 1.79737 9.70317 1.79737C11.0473 1.79737 12.3151 2.41253 13.1815 3.48517C13.422 3.78305 13.825 3.89571 14.1853 3.7658C14.5053 3.65039 14.8024 3.59429 15.0938 3.59429C16.5602 3.59429 17.8215 4.78831 17.7805 6.35595C17.7692 6.79254 18.0735 7.17402 18.5017 7.26005C20.1712 7.59548 21.2033 9.0766 21.2033 10.7818C21.2033 12.7634 19.7708 14.3755 17.7892 14.3755H15.0939C14.5977 14.3755 14.1954 14.7778 14.1954 15.274C14.1954 15.7702 14.5977 16.1724 15.0939 16.1724H17.7892C20.7616 16.1724 23.0001 13.7542 23.0001 10.7818C23.0001 8.46512 21.6883 6.4241 19.5456 5.68562C19.2498 3.49308 17.3662 1.79741 15.0939 1.79741C14.7921 1.79741 14.4849 1.83205 14.1752 1.90078C12.9911 0.686224 11.392 0.00044632 9.70312 0.00044632C6.43771 0.00044632 3.7455 2.50228 3.44245 5.68971C1.30628 6.43169 0 8.46943 0 10.7817C0 13.7541 2.23855 16.1723 5.21094 16.1723V16.1723Z"
        fill="white"
      />
      <g clipPath="url(#clip1_1350_4387)">
        <path
          d="M7.04761 12.0111C7.13375 12.1003 7.24974 12.1544 7.37316 12.1628C7.49659 12.1712 7.61874 12.1334 7.71609 12.0567L7.76839 12.0111L10.9899 8.68918V17.8824C10.9848 18.0156 11.0318 18.1455 11.1207 18.2444C11.2097 18.3433 11.3336 18.4034 11.4661 18.4117C11.5985 18.4201 11.729 18.3761 11.8296 18.2892C11.9302 18.2023 11.993 18.0793 12.0046 17.9465V17.8824V7.42617C12.0051 7.32357 11.9755 7.22308 11.9195 7.13722C11.8636 7.05137 11.7838 6.98395 11.6899 6.94333C11.608 6.90906 11.5184 6.89725 11.4304 6.9092C11.3424 6.92114 11.2591 6.95633 11.1892 7.01123L11.1384 7.05763L7.04611 11.2749C6.95167 11.3728 6.89829 11.5034 6.897 11.6397C6.89571 11.776 6.94659 11.9076 7.03916 12.0073L7.04611 12.0142L7.04761 12.0111ZM12.9261 9.63015L12.9707 9.68192L15.2292 12.0111C15.2759 12.0595 15.3319 12.098 15.3937 12.1243C15.4555 12.1506 15.522 12.1641 15.5892 12.1641C15.6563 12.1641 15.7228 12.1506 15.7846 12.1243C15.8464 12.098 15.9024 12.0595 15.9492 12.0111C16.0368 11.9209 16.0897 11.8023 16.0983 11.6764C16.1069 11.5506 16.0707 11.4258 15.9961 11.3243L15.95 11.2703L13.6938 8.94418C13.647 8.89575 13.5909 8.85721 13.529 8.83092C13.4671 8.80463 13.4006 8.79112 13.3334 8.79112C13.2662 8.79112 13.1997 8.80463 13.1377 8.83092C13.0758 8.85721 13.0198 8.89575 12.973 8.94418C12.8853 9.03424 12.8323 9.15271 12.8236 9.27839C12.8148 9.40408 12.8509 9.52872 12.9253 9.63015H12.9261Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1350_4387">
        <rect width="23" height="23" fill="white" />
      </clipPath>
      <clipPath id="clip1_1350_4387">
        <rect
          width="9.2"
          height="11.5"
          fill="white"
          transform="matrix(1 0 0 -1 6.8999 18.4)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default styled(FileUploader).attrs({}) <{ formfield?: boolean, status?: string }>`
  .titleCustom {
    margin-bottom: 5px;
    font-family: 'Almarai';
    font-style: normal;
    font-weight: ${props => props.formfield ? '600' : '400'};
    fontSize: 16px;
    color: ${props => props.formfield ? props.theme.antd.colorTextLabel : '#000'};
    text-align: start;
    padding: 12px;
  }

  .uploaderContainerCustom {
    border-radius: 15px;
    padding: 5px;
    background-color: rgb(245, 245, 245);
    min-width: 25vw;
    display: flex;
    height: 43px;
    border: 1px solid #e3e0e0;
    ${props => props.status == 'error' && `
        border: 1px solid ${props.theme.antd.colorError}!important;
    `}  
    ${props => props.formfield && `
        &:hover {
            border: 1px solid ${props.theme.antd.colorTextLabel}!important;
        }
    `}
  }

  .buttonStyle {
    border-radius: 10px;
    display: flex;
    gap: 10px;
  }

  .ant-upload-list-item-name {
    text-align: start;
  }

  .ant-upload-list-item-action {
    opacity: 1!important;
  }

  .ant-upload-icon span.anticon.anticon-loading.anticon-spin path {
    fill: #d8b86a!important;
  }
`;
