import { useState } from 'react'
import { Layout, Typography, Col, Row } from 'antd'
import styled from 'styled-components'
import Textfield from 'components/Textfield'
import { SearchOutlined } from 'components/icons'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store'
import defaultProfileImg from 'app/images/UserImg.png'
import IconButton from 'components/IconButton'
import { LogoutArrowOutlined } from 'components/icons'
import { Logout } from 'features/user'
import { triggerLoading } from 'features/utils/loadingSlice'
import { setCurrentProfile } from 'features/user/setCurrentProfile'
import Notifications from './notifications'
import { Certificate, DollarSign, ArrowDown, ButterFlyIcon, Cart, Stock, DblCog, Cog, MaginfierWithUser, Bell } from 'components/icons'
const { Header } = Layout

const CustomHeader = styled(Header) <{ lang: string }>`
    background: #FFFFFF!important;
    padding: 1rem 10px!important;
    border-radius: ${props => props.lang == 'ar' ? '0px 56px 0px 0px;' : '56px 0px 0px 0px;'}
    height: 100px!important;

    > div {
        height: 100%;
        display: flex;
        align-items: end;
    }
`
const SearchInput = styled(Textfield)`
    border: 1px solid rgba(1, 18, 36, 0.1);
    max-width: 225px;
`
const ItemContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    span {
        display: flex;
        cursor: pointer;
        padding: 5px;
        line-height: 20px!important;
    }

    .lang-btn {
        fontSize: 18px;
        margin-top: 5px;
    }
`
// Profiles
const User = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
    position: relative;

    img {
        width: 58px;
        height: 58px;
        border-radius: 15px;
        margin-inline-start: 50px;
        margin-inline-end: 15px;
        cursor: pointer;
        object-fit: cover;
        object-position: top;
    }

    p {
        line-height: 20px;
        margin-bottom: 0;
        fontSize: 18px;
        font-weight: 700;
        cursor: pointer;
    }

    p:last-child {
        margin-bottom: 0px;
        fontSize: 16px;
        font-weight: 400;
        color: ${props => props.theme.antd.colorPrimary};
    }
`
const ProfilesDropDown = styled.div<{ showProfiles: boolean, lang?: string }>`
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    width: 400px;
    height: calc(100vh - 100px);
    position: absolute;
    top: 100%;
    z-index: 999;
    padding: 6px 0;
    padding-inline-start: 4px;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;
    ${props => props.showProfiles ? 'display: flex;' : 'display: none;'}
    ${props => props.lang == 'ar' ?
        `right: 43%;;
    border-radius: 15px 0px 15px 15px;`
        :
        `left: 43%;;
    border-radius: 0px 15px 15px 15px;`}   
`
const ProfilesList = styled.div<{ selected?: string }>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    border-radius: 14px;
    padding: 7px;
    margin-bottom: 3px;
    background: ${props => props.selected == '1' && '#1a2d3f;'};

    &:hover {
        background: #1a2d3f;
    }

    &:hover p:first-child {
        color: #fff;
    }

    img {
        margin-inline-start: 0;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: #fff;
    }

    p {
        fontSize: 14px!important;
        line-height: 15px;
        margin-bottom: 0;
    }

    p:first-child {
        color: ${props => props.selected == '1' && '#fff;'}
    }
`
const MainBadge = styled.div<{ lang?: string }>`
  background: ${props => props.theme.antd.colorPrimary};
  width: 40px;
  height: 20px;
  border-radius: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  ${props => props.lang == 'ar' ?
        `left: 5px;`
        :
        `right: 5px;`
    }
  top: 50%;
  transform: translateY(-50%);
  fontSize: 12px;
`
const LogoutText = styled.div<{}>`
    color: ${props => props.theme.antd.colorInfo};
    fontSize: 14px;
    font-weight: 700;
    padding-inline-start: 14px;
    line-height: normal;
`
// Modules
const ModulesDropDown = styled.div<{ showModules: boolean, lang?: string }>`
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    width: 280px;
    height: 216px;
    position: absolute;
    top: 70%;
    z-index: 999;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px;
    ${props => props.showModules ? 'display: flex;' : 'display: none;'}
    ${props => props.lang == 'ar' ?
        `left: 92%;
    border-radius: 0px 15px 15px 15px;`
        :
        `right: 92%;
    border-radius: 15px 0px 15px 15px;`} 
    
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 0 0 33.333333%;
    }
`
// Notifications
const NotificationsDropDown = styled.div<{ showNotifications: boolean, lang?: string }>`
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    width: 350px;
    height: 500px;
    position: absolute;
    top: 100%;
    z-index: 999;
    ${props => props.showNotifications ? 'display: block;' : 'display: none;'}
    ${props => props.lang == 'ar' ?
        `left: 20%;
    border-radius: 0px 15px 15px 15px;`
        :
        `right: 20%;
    border-radius: 15px 0px 15px 15px;`}   
`
const DropDownsOverlay = styled.div<{ showNotifications: boolean, showProfiles: boolean, showModules: boolean }>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: transparent;
    ${props => props.showNotifications || props.showProfiles || props.showModules ? 'display: block;' : 'display: none;'}
`

type CustomHeaderProps = {}

export default function MainFooter({ }: CustomHeaderProps) {
    const { t, i18n } = useTranslation()
    const [showModules, setShowModules] = useState(false)
    const [showProfiles, setShowProfiles] = useState(false)
    const [notifNo, setNotifNo] = useState(0)
    const [showNotifications, setShowNotifications] = useState(false)
    const currentUser = useAppSelector(state => state.currentUser)
    const currentProfile = useAppSelector(state => state.currentProfile)
    const dispatch = useAppDispatch()

    const accountProfiles = currentUser?.Data?.Data?.Account_Profiles

    return (
        <CustomHeader lang={i18n.language}>
            <Row justify={'space-between'}>
                <Col xs={24} md={12}>
                    <User onClick={() => setShowProfiles(!showProfiles)}>
                        <img src={currentProfile?.Data?.Data?.User_Image ? currentProfile?.Data?.Data?.User_Image : defaultProfileImg} />
                        <div>
                            <p>{i18n.language == 'ar' ? currentProfile?.Data?.Data?.User_Name_AR : currentProfile?.Data?.Data?.User_Name_EN}</p>
                            <p>{i18n.language == 'ar' ? currentProfile?.Data?.Data?.Department_Name_AR : currentProfile?.Data?.Data?.Department_Name_EN}</p>
                        </div>
                        <span style={{ padding: '0 10px', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                            <ArrowDown />
                        </span>
                        <ProfilesDropDown
                            showProfiles={showProfiles}
                            lang={i18n.language}
                        >
                            <div style={{ overflowY: 'scroll', height: '100%' }}>
                                {accountProfiles?.map((acc: any, i: any) => (
                                    <ProfilesList
                                        key={i}
                                        selected={
                                            (Object.keys(currentProfile?.Data).length !== 0 && acc?.User_Id == currentProfile?.Data?.Data?.User_Id) ? '1'
                                                : Object.keys(currentProfile?.Data).length == 0 && acc?.Selected_Profile
                                        }
                                        onClick={() => {
                                            dispatch(triggerLoading({ status: true }));
                                            dispatch(setCurrentProfile({ userId: acc?.User_Id, token: currentUser?.Data?.Data.access_token }))
                                        }}
                                    >
                                        <div>
                                            <img src={acc?.Department_Logo_Url ? acc?.Department_Logo_Url : defaultProfileImg} />
                                        </div>
                                        <div>
                                            <p style={{ fontSize: '14px', width: 'auto' }}>
                                                {i18n.language == 'ar' ? acc?.Department_Job_Name_AR : acc?.Department_Job_Name_EN}
                                                {
                                                    i18n.language == 'ar' && acc?.Department_Job_Name_AR && acc?.Department_Name_AR ? ' - '
                                                        : i18n.language == 'en' && acc?.Department_Job_Name_EN && acc?.Department_Name_EN ? ' - '
                                                            : null
                                                }
                                                {i18n.language == 'ar' ? acc?.Department_Name_AR : acc?.Department_Name_EN}
                                            </p>
                                            {acc?.Main_Profile ? <MainBadge lang={i18n.language}>Main</MainBadge> : null}
                                            <p style={{ display: 'flex', justifyContent: 'space-between', marginBlockStart: '10px' }}>
                                                {i18n.language == 'ar' ? acc?.User_Group_Name_AR : acc?.User_Group_Name_EN}
                                            </p>
                                        </div>
                                    </ProfilesList>
                                ))}
                            </div>

                            <div>
                                <div
                                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', margin: '6px 0' }}
                                    onClick={() => dispatch(Logout())}
                                >
                                    <span style={{ transform: i18n.language == 'en' ? 'scaleX(-1)' : 'none' }}>
                                        <IconButton
                                            icon={<LogoutArrowOutlined />}
                                            btncolor="primary"
                                            onClick={() => { }}
                                            iconstyle='outline'
                                            width='40px'
                                            height='40px'
                                        />
                                    </span>
                                    <LogoutText>{t('logout')}</LogoutText>
                                </div>
                            </div>
                        </ProfilesDropDown>
                    </User>
                </Col>

                <Col xs={24} md={12} lg={8} style={{ height: '100%' }}>
                    <ItemContainer>
                        {/* Modules */}
                        <span onClick={() => setShowModules(!showModules)}>
                            <ButterFlyIcon />
                        </span>
                        <ModulesDropDown
                            showModules={showModules}
                            lang={i18n.language}
                        >
                            <div>
                                <span>
                                    <DollarSign />
                                </span>
                                <span>{t('accounts')}</span>
                            </div>

                            <div>
                                <span>
                                    <Certificate />
                                </span>
                                <span>{t('sales')}</span>
                            </div>

                            <div>
                                <span>
                                    <Cart />
                                </span>
                                <span>{t('purchases')}</span>
                            </div>

                            <div>
                                <span>
                                    <Stock />
                                </span>
                                <span>{t('stock')}</span>
                            </div>

                            <div>
                                <span>
                                    <DblCog />
                                </span>
                                <span>{t('services')}</span>
                            </div>

                            <div>
                                <span>
                                    <MaginfierWithUser />
                                </span>
                                <span>HR</span>
                            </div>
                        </ModulesDropDown>

                        {/* Search */}
                        <span>
                            <SearchInput
                                placeholder={t('searchFor') as string}
                                prefix={<SearchOutlined />}
                                prefixcolor='#219EBC'
                                formfield={false}
                            />
                        </span>

                        {/* Language */}
                        <span
                            onClick={() => {
                                if (i18n.language === 'en') {
                                    localStorage.setItem('i18nextLng', 'ar')
                                } else {
                                    localStorage.setItem('i18nextLng', 'en')
                                }
                                window.location.reload()
                            }}
                            className='lang-btn'
                        >
                            {t('langBtn')}
                        </span>

                        {/* Notifications */}
                        <div style={{ position: 'relative' }}>
                            <span onClick={() => setShowNotifications(!showNotifications)}>
                                <Bell notifNo={notifNo} />
                            </span>
                            <NotificationsDropDown
                                showNotifications={showNotifications}
                                lang={i18n.language}
                            >
                                <Notifications onNewNotifications={(data: number) => setNotifNo(data)} />
                            </NotificationsDropDown>
                        </div>

                        {/* Setting */}
                        <span style={{ marginTop: '5px' }}>
                            <Cog />
                        </span>
                    </ItemContainer>
                </Col>

                <DropDownsOverlay
                    showModules={showModules}
                    showNotifications={showNotifications}
                    showProfiles={showProfiles}
                    onClick={() => {
                        setShowModules(false)
                        setShowNotifications(false)
                        setShowProfiles(false)
                    }}
                />
            </Row>
        </CustomHeader>
    )
}