import { Layout, theme, Tabs } from "antd"
import HomeTrial from "app/HomeTrial/HomeTrial"
import styled from "styled-components"
import MainFooter from "./footer"
import MainSidebar from "./sidebar"
import MainHeader from "./header"
import { useAppDispatch, useAppSelector } from "store"
import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { triggerTabs } from "features/utils/tabsSlice"
import { Route, Routes } from 'react-router-dom'
import { triggerActiveTab } from "features/utils/activeTabSlice"
import { useNavigate } from 'react-router-dom'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core'
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import tabBG from 'app/images/SystemBG.png'
import DepartmentsList from "pages/Departments/Departments/DepartmentsList"
import DepartmentsForm from "pages/Departments/Departments/DepartmentsForm"
import AdministrationsList from "pages/Departments/Administrations/AdministrationsList"
import AdministrationsForm from "pages/Departments/Administrations/AdministrationsForm"
import SectionsForm from "pages/Departments/Sections/SectionsForm"
import JobsForm from "pages/Departments/Jobs/JobsForm"
import CentersForm from "pages/Departments/Centers/CentersForm"
import SectionsList from "pages/Departments/Sections/SectionsList"
import CentersList from "pages/Departments/Centers/CentersList"
import JobsList from "pages/Departments/Jobs/JobsList"
import TasksList from "pages/Tasks/Tasks/TasksList"
import TasksForm from "pages/Tasks/Tasks/TasksForm"
import AllTasksList from "pages/Tasks/TasksList/TasksList"
import AllTasksForm from "pages/Tasks/TasksList/TasksListForm"
import MyTasksList from "pages/Tasks/MyTasks/MyTasksList"
import MyTasksForm from "pages/Tasks/MyTasks/MyTasksForm"
import SuspensionsAdministrationList from "pages/Suspensions/SuspensionsAdministrationList"
import SuspensionsAdministrationForm from "pages/Suspensions/SuspensionsAdministrationForm"
import SuspensionsList from "pages/Suspensions/SuspensionsList"
import { useLocation } from "react-router-dom"

interface DraggableTabPaneProps extends React.HTMLAttributes<HTMLDivElement> {
  'data-node-key': string;
  onActiveBarTransform: (className: string) => void;
}

const DraggableTabNode = ({ className, onActiveBarTransform, ...props }: DraggableTabPaneProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isSorting } = useSortable({
    id: props['data-node-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'move',
  };

  return React.cloneElement(props.children as React.ReactElement, {
    ref: setNodeRef,
    style,
    ...attributes,
    ...listeners,
  });
}

const { Content, Header } = Layout
const tabPrefixCls = "ant-tabs"

function MainLayout() {
  const [currentActiveTabKey, setActiveTabKey] = React.useState('/dashboard')
  const { t, i18n } = useTranslation()
  const { token: { colorBgContainer } } = theme.useToken()
  const dispatch = useAppDispatch()
  const currentTabs = useAppSelector(state => state.tabs.Data)
  const currentActiveTab = useAppSelector(state => state.activeTab.Data)
  const navigate = useNavigate()
  const [tabType, setTabType] = React.useState<any>('editable-card')
  const location = useLocation()
  const [tabs, setTabs] = React.useState([
    {
      key: "/dashboard",
      label: i18n.language == 'ar' ? 'لوحة التحكم' : 'Dashboard',
      children: <></>,
      closable: false
    }
  ])

  useEffect(() => {
    const crrentTabIsInTabs = currentTabs?.find((element: any) => element.key === location.pathname);

    if (!crrentTabIsInTabs) {
      navigate('/dashboard')
      dispatch(triggerActiveTab({ Data: '/dashboard' }))
    } else {
      dispatch(triggerActiveTab({ Data: location.pathname }))
    }
  }, [])

  useEffect(() => {
    let newTabs = currentTabs?.map((t: any) => t.key != "/dashboard" && { key: t?.key, label: t?.label, closable: true }).filter((x: any) => x != false)
    newTabs?.unshift({ key: "/dashboard", label: i18n.language == 'ar' ? 'لوحة التحكم' : 'Dashboard', children: <></>, closable: false })
    setTabs(newTabs)
  }, [currentTabs])

  useEffect(() => {
    setActiveTabKey(currentActiveTab)
  }, [currentActiveTab])

  const sensor = useSensor(PointerSensor, { activationConstraint: { distance: 10 } })

  const onDragStart = ({ active }: DragStartEvent) => {
    setTabType('line')
  }

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id == "/dashboard" || over?.id == "/dashboard") {
      return
    } else if (active.id !== over?.id) {
      setTabs((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id)
        const overIndex = prev.findIndex((i) => i.key === over?.id)
        dispatch(triggerTabs({ Data: arrayMove(prev, activeIndex, overIndex) }))
        return arrayMove(prev, activeIndex, overIndex)
      })
    }
    setTabType('editable-card')
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>

      <MainSidebar />

      <BodyLayout className="site-layout" lang={i18n.language}>

        <MainHeader />

        <Content>
          <Header style={{ background: colorBgContainer, padding: 0, height: '100%' }}>
            <CustomTabs
              defaultActiveKey="/dashboard"
              activeKey={currentActiveTabKey}
              lang={i18n.language}
              onTabClick={(key) => {
                navigate(key)
                dispatch(triggerActiveTab({ Data: key }))
              }}
              type={tabType}
              hideAdd
              onEdit={(e, action) => {
                if (action === "remove") {
                  let arr = tabs.filter((t) => t.key !== e)
                  dispatch(triggerTabs({ Data: arr }))
                  let nextTab = arr?.slice(-1)?.pop()?.key
                  if (currentActiveTabKey != nextTab) {
                    navigate(nextTab ? nextTab : '/dashboard')
                    dispatch(triggerActiveTab({ Data: nextTab }))
                  }
                }
              }}
              // ant-tabs-nav-wrap ant-tabs-nav-wrap-ping-left
              renderTabBar={(tabBarProps, DefaultTabBar) => {
                tabBarProps.rtl = i18n.language == 'ar' ? true : false
                return (
                  <DndContext sensors={[sensor]} onDragStart={onDragStart} onDragEnd={onDragEnd}>
                    <SortableContext
                      items={tabs?.slice(1)?.map((i) => i.key)}
                      strategy={horizontalListSortingStrategy}
                    >
                      <DefaultTabBar {...tabBarProps}>
                        {(node) => (
                          <DraggableTabNode
                            {...node.props}
                            key={node.key}
                          >
                            {node}
                          </DraggableTabNode>
                        )}
                      </DefaultTabBar>
                    </SortableContext>
                  </DndContext>
                )
              }}
            >
              {tabs.map(({ children, label, ...rest }) => (
                <CustomTabs.TabPane
                  tab={i18n.language == 'ar' ? label.split('/')[0] : label.split('/').pop()}
                  {...rest}
                >
                  <TabWrapper>
                    <Routes>
                      <Route path='/dashboard' element={<HomeTrial />} />
                      {/* <Route path='/project-execution-file' element={<HomeTrial />} /> */}

                      <Route path='/departments' element={<DepartmentsList />} />
                      <Route path='/department-form' element={<DepartmentsForm />} />

                      <Route path='/municipality-management' element={<AdministrationsList />} />
                      <Route path='/municipality-management-form' element={<AdministrationsForm />} />

                      <Route path='/municipality-sections' element={<SectionsList />} />
                      <Route path='/department-sections-form' element={<SectionsForm />} />

                      <Route path='/municipality-centers' element={<CentersList />} />
                      <Route path='/department-centers-form' element={<CentersForm />} />

                      <Route path='/municipality-jobs' element={<JobsList />} />
                      <Route path='/department-jobs-form' element={<JobsForm />} />

                      <Route path='/assigned-tasks' element={<TasksList />} />
                      <Route path='/tasks-form' element={<TasksForm />} />

                      <Route path='/all-tasks' element={<AllTasksList />} />
                      <Route path='/all-tasks-form' element={<AllTasksForm />} />

                      <Route path='/my-tasks' element={<MyTasksList />} />
                      <Route path='/my-tasks-form' element={<MyTasksForm />} />

                      <Route path='/manage-suspensions' element={<SuspensionsAdministrationList />} />
                      <Route path='/manage-suspensions-form' element={<SuspensionsAdministrationForm />} />
                      <Route path='/suspensions' element={<SuspensionsList />} />
                    </Routes>
                  </TabWrapper>
                </CustomTabs.TabPane>
              ))}
            </CustomTabs>
          </Header>
        </Content>

        <MainFooter />

      </BodyLayout>

    </Layout >
  )
}

const CustomTabs = styled(Tabs) <{ lang: string }>`
  & .${tabPrefixCls}-nav {
    ::before {
      border-bottom: none;
    }

    & .${tabPrefixCls}-nav-wrap {
      // flex-direction: ${props => props.lang === 'ar' ? 'row-reverse' : 'row'};
    }

    & .${tabPrefixCls}-tab:first-child {
      width: 112px;
      height: 40px;
      border-radius: 15px;
      background-color: #f5f5f5;
      border: none;
      padding: 0;
      justify-content: center;
      color: rgba(112, 112, 112, 0.5);
      font-weight: 700;
      cursor: pointer!important;
      .ant-tabs-tab-remove {
        margin-right: 5px;
      }
    }

    & .${tabPrefixCls}-tab:not(:first-child) {
      width: 205px;
      height: 40px;
      border-radius: 15px;
      background-color: #f5f5f5;
      margin-inline: 4px;
      padding: 0px;
      padding-inline-start: 10px;
      border: none;
      justify-content: space-between;
      color: rgba(112, 112, 112, 0.5);
    }

    & .${tabPrefixCls}-tab-active {
      background-color: #219ebc !important;

      .${tabPrefixCls}-tab-btn {
        color: #fff;
      }
    }

    & .${tabPrefixCls}-tab-remove {
      margin-right: 5px;
   }

    & .${tabPrefixCls}-ink-bar {
      display: none;
    }

    .ant-tabs-nav-wrap {
      padding-inline-start: 25px;
    }
  }
  height: 100%;
`
const BodyLayout = styled(Layout) <{ lang: string }>`
  background: ${props => props.theme.antd.colorInfo};

  .${tabPrefixCls}-content-holder, 
  .${tabPrefixCls}-content, 
  .${tabPrefixCls}-tabpane-active {
    height: 100%;
  }

  .${tabPrefixCls}-tabpane-active::before {
    // content: '';
    // position: absolute;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // background: url(${tabBG});
    // background-repeat: no-repeat;
    // background-size: 50%;
    // background-position: bottom left;
    // transform: ${props => props.lang == 'ar' ? 'none' : 'scaleX(-1)'};
  }
`
const TabWrapper = styled.div`
  padding: 1rem;
  // background: #F7F7F7;
  height: 100%;
`
export default MainLayout;
