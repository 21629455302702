import i18n from "i18next";
import "moment/min/locales.min";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const detectionOptions = {
	order: ["queryString", "localStorage"],
	lookupQuerystring: "lng",
	lookupLocalStorage: "i18nextLng",
};

i18n.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		lowerCaseLng: true,
		fallbackLng: "ar",
		lng: localStorage.getItem("i18nextLng") || "ar",
		supportedLngs: ["ar", "en"],
		preload: ["ar", "en"],
		cleanCode: true,
		detection: detectionOptions,
		load: "languageOnly",
		debug: false,
		ns: [],
		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: false,
		},
		backend: {
			loadPath: "/locales/{{lng}}/{{ns}}.json",
		},
	});

i18n.on("languageChanged", function (lng) {
	const html = document.querySelector("html");
	if (html) {
		html.setAttribute("lang", lng.split("-")[0]);
		html.setAttribute("dir", i18n.dir());
	}
});

export default i18n;