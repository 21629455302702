import React, { useState, ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import { Steps as AntdSteps } from 'antd'
import {
    RightOutlined,
    LeftOutlined
} from '@ant-design/icons'
import IconButton from './IconButton'
import { useTranslation } from 'react-i18next'

const prefixCls = "ant-steps"

const AntdCustomSteps = styled(AntdSteps) <{ width?: string }>`
    width: ${props => props.width};
    margin: 10px auto;

    .${prefixCls}-item-title {
        font-size: 14px;
        line-height: 22px;
    }
`

type CustomStepsProps = {
    direction?: "horizontal" | "vertical"
    disabled?: boolean
    steps?: {
        title?: string
        content?: string | ReactNode
        status?: "error" | "wait" | "process" | "finish" | undefined
        icon?: ReactNode
        disabled?: boolean
    }[]
    showBtn?: boolean
    disabledNext?: boolean
    onStepChange?: (current: any) => void
    labelPlacement?: "horizontal" | "vertical" | undefined
    width?: string
}

export default function Steps({
    direction = 'horizontal',
    steps = [],
    showBtn = true,
    disabledNext = false,
    onStepChange = () => { },
    labelPlacement = "vertical",
    width
}: CustomStepsProps) {

    const { t, i18n } = useTranslation()
    const [currentStep, setCurrentStep] = useState(0)

    useEffect(() => {
        onStepChange(currentStep)
    }, [currentStep])

    const next = () => setCurrentStep(currentStep + 1)
    const prev = () => setCurrentStep(currentStep - 1)

    const items = steps?.map((item) => ({
        key: item?.title,
        title: item?.title,
        status: item?.status,
        icon: item?.icon,
        disabled: item?.disabled
    }))

    const onChange = (value: number) => setCurrentStep(value)

    return (
        <>
            <div
                style={{
                    display: 'flex',
                }}
            >
                {showBtn &&
                    <>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '88px'
                            }}
                        >
                            <IconButton
                                btncolor="info"
                                disabled={currentStep <= 0}
                                icon={i18n.language == 'ar' ? <RightOutlined /> : <LeftOutlined />}
                                onClick={() => prev()}
                            />

                            <IconButton
                                btncolor="info"
                                disabled={currentStep == steps.length - 1 || disabledNext}
                                icon={i18n.language == 'ar' ? <LeftOutlined /> : <RightOutlined />}
                                onClick={() => next()}
                            />
                        </div>
                    </>
                }

                <AntdCustomSteps
                    current={currentStep}
                    items={items}
                    direction={direction}
                    width={width}
                    labelPlacement={labelPlacement}
                    onChange={onChange}
                />
            </div>

            <div>{steps[currentStep]?.content}</div>
        </>
    )
}