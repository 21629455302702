import { DatePicker } from 'antd'
import styled from "styled-components"
import classNames from 'classnames'
import { Dayjs } from 'dayjs';

const prefixCls = "ant-picker"
const popupClassName = "ant-picker-custom-dropdown"

const Label = styled.div <{ formfield: boolean }>`
  font-family: "Almarai";
  font-style: normal;
  font-weight: ${props => props.formfield ? '600' : '400'};
  fontSize: 16px;
  color: ${props => props.formfield ? props.theme.antd.colorTextLabel : '#000'};
  text-align: start;
  padding: 12px;
`;

const AntdDatePicker = styled(DatePicker) <{ status: string, formfield: boolean }>`
  width: 100%;
  border-radius: 15px;
  ${props => props.status == 'error' && `
    border: 1px solid ${props.theme.antd.colorError}!important;
  `}
  background: #f5f5f5 !important;
  border-radius: 15px !important;
  padding: 7px 11px !important;
  border: 1px solid #e3e0e0;
  height: 38px !important;
  ${props => props.formfield && `
    &:hover {
      border: 1px solid ${props.theme.antd.colorTextLabel}!important;
    }
  `}

  .${prefixCls}-suffix path {
    fill: ${(props) => props.theme.antd.colorPrimary};
  }
`

type CustomDateBoxProps = {
  onChange?: (date: Dayjs | null, dateString: string) => void
  className?: string
  popupClassName?: string
  placeholder?: string
  value?: Dayjs
  onOk?: () => void
  allowClear?: boolean
  suffixIcon?: React.ReactNode
  dateFormat?: string
  timeFormat?: string
  variant?: "date" | "datetime"
  label?: string
  formfield?: boolean
  status?: "error" | "warning" | ""
  disabled?: boolean
  defaultValue?: Dayjs
}

export default function CustomDateBox ({
  label,
  status = "",
  className,
  popupClassName,
  variant = "date",
  dateFormat = "YYYY/MM/DD",
  timeFormat = "hh:mm a",
  onChange = () => { },
  formfield = true,
  placeholder = '',
  disabled = false,
  defaultValue,
  ...rest 
}: CustomDateBoxProps) {

  return (
    <>
      {label && <Label formfield={formfield}>{label}</Label>}
      <AntdDatePicker
        className={className}
        popupClassName={classNames(className, popupClassName)}
        showToday={false}
        showNow={variant === 'datetime'}
        showTime={variant === 'datetime' ? { format: timeFormat } : false}
        format={variant === 'datetime' ? `${dateFormat} ${timeFormat}` : dateFormat}
        onChange={onChange}
        formfield={formfield}
        status={status}
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={defaultValue}
        {...rest}
      />
    </>
  )
}


// export default styled(CustomDateBox).attrs({
//   popupClassName
// })`
//   &.${prefixCls}-focused {
//     box-shadow: ${props => `0px 1px 2px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px ${props.theme.antd.colorPrimary}, 0px 0px 0px 4px #F8EFD9;`};
//     border-color: transparent;
//   }

//   &.${popupClassName} {
//     & .${prefixCls}-footer {
//       .${prefixCls}-now {
//         padding-inline-start: 25%;
//         &-btn {
//           fontSize: 0;
//           color: ${props => props.theme.antd.colorPrimary};

//           &:hover {
//             color: ${props => props.theme.antd.colorPrimary};
//           }
          
//           &::after {
//             content: 'Today';
//             fontSize: 14px;
//           }
//         }
//       }
//     }
//     /* & .${prefixCls}-month-panel,
//     .${prefixCls}-year-panel,
//     .${prefixCls}-decade-panel,
//     .${prefixCls}-date-panel {   
//         .${prefixCls}-header {
//           border-bottom: none;
          
//           &-super-prev-btn, &-super-next-btn {
//             color: rgba(0, 0, 0, 0.85);
//             min-width: 2rem;

//             .${prefixCls}-super-prev-icon, .${prefixCls}-super-next-icon {
//               width: 5px;
//               height: 5px;

//               &:after { 
//                 width: 5px;
//                 height: 5px;
//               }

//               &:before {
//                 display: none;
//               }
//             }

//             &:before {
//               content: "";
//               position: absolute;
//               top: 12px;
//               width: 24px;
//               height: 20px;
//               border-radius: 6px;
//               box-shadow: 0px 1px 1px rgb(0 0 0 / 10%), 0px 0px 0px 1px rgb(70 79 96 / 16%);
//             }
//           }

//           &-super-prev-btn {
//             &:before {
//               right: 16px;
//             }
//           }

//           &-super-next-btn {
//             &:before {
//               left: 16px;
//             }
//           }
//         }
//     } */

//     /* & .${prefixCls}-date-panel {
//           .${prefixCls}-cell {
//             &:not(.${prefixCls}-cell-in-view) {
//               .${prefixCls}-cell-inner {
//                 display: none;
//               }
//               pointer-events: none;
//             }

//             &-inner {
//               min-width: 32px;
//               height: 32px;
//               line-height: 34px;
//             }
//           }
//           .${prefixCls}-header {

//             &-super-prev-btn, &-super-next-btn {
//               display: none
//             }

//             &-prev-btn, &-next-btn {
//               color: rgba(0, 0, 0, 0.85);
//               min-width: 2.5rem;
              
//               &:before {
//                 content: "";
//                 position: absolute;
//                 top: 12px;
//                 width: 24px;
//                 height: 20px;
//                 border-radius: 6px;
//                 box-shadow: 0px 1px 1px rgb(0 0 0 / 10%), 0px 0px 0px 1px rgb(70 79 96 / 16%);
//               }

//               .${prefixCls}-prev-icon, .${prefixCls}-next-icon {
//                 width: 5px;
//                 height: 5px;

//                 &:before {
//                   width: 5px;
//                   height: 5px;
//                 }
//               }
//             }

//             &-prev-btn {
//               &:before {
//                 right: 15px;
//               }
//             }

//             &-next-btn {
//               &:before {
//                 left: 15px;
//               }
//             }
//           }

//           .${prefixCls}-body {
//             padding: 0 12px 8px
//           }
//     } */
//   }
// `
