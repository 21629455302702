import { useEffect, useState } from 'react'
import TabModal from 'components/TabModal'
import { useAppSelector, useAppDispatch } from 'store'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'
import {
    addDepartmentJob,
    updateDepartmentJob,
    getJobsTypes
} from 'api/departments/departments'
import Textfield from "components/Textfield"
import { triggerLoading } from 'features/utils/loadingSlice'
import { triggerModalTab } from 'features/utils/modalTabSlice'
import Switch from 'components/Switch'
import Selectfield from 'components/Selectfield'
import Button from 'components/Button'
import { Tabs } from 'antd'
import styled from 'styled-components'
import JobGroupWorkersNameList from './JobGroupWorkersNameList'
import JobSectionsList from './JobSectionsList'

const CustomAntdTab = styled(Tabs)`
    .ant-tabs-tab {
        padding: 12px;
    }
`

export default function JobsForm() {

    const { t, i18n } = useTranslation()
    const [formData, setFormData] = useState<any>()
    const [isChecked, setIsChecked] = useState<any>()
    const [showTabForm, setShowTabForm] = useState<any>(false)
    const [departmentJobId, setDepartmentJobId] = useState<any>(null)
    const [showJobSectionsList, setJobSectionsList] = useState<any>(false)
    const [jobTypesList, setJobTypesList] = useState<any>([])
    const modalStatus = useAppSelector(state => state.modalTab)
    const currentUser = useAppSelector((state: any) => state.currentUser)
    const currentactiveTab = useAppSelector(state => state.activeTab.Data)
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()

    useEffect(() => {
        setFormData(modalStatus?.Data)
        setIsChecked(modalStatus.Type == 'add' ? 1 : modalStatus?.Data?.Active_Status_Id)
        getJobsTypesLst()
    }, [])

    useEffect(() => {
        if (currentactiveTab == '/department-jobs-form') {
            setShowTabForm(true)
            setTimeout(() => dispatch(triggerLoading({ status: false })), 1000)
        }
    }, [currentactiveTab])

    const getJobsTypesLst = async () => {
        let JobsTypesArr: any = [],
            departmentId = currentUser?.Data?.Data?.Department_Id,
            at = currentUser.Data.access_token
        await getJobsTypes(at, departmentId)
            .then(res => {
                if (res?.Status) {
                    res?.Data.map((item: any) => {
                        let label = i18n.language === 'ar' ? item.Record_Name_AR : item.Record_Name_EN
                        JobsTypesArr.push({ value: item.Record_Id, label })
                    })
                    setJobTypesList(JobsTypesArr)
                }
            })
    }

    const handleSubmit = async (values: any) => {
        dispatch(triggerLoading({ status: true }))
        if (modalStatus?.Type == 'add') {
            await addDepartmentJob(
                currentUser.Data.Data.access_token,
                modalStatus?.Data?.Department_Id,
                values.Department_Job_Name_AR,
                values.Department_Job_Name_EN,
                values.Department_Job_Type_Id,
                values.Department_Job_Signature_Text,
                values.Active_Status_Id ? 1 : 0
            ).then(data => {
                setDepartmentJobId(data.Data.Department_Job_Id)
                dispatch(triggerLoading({ status: false }))
                setJobSectionsList(true)
            })
        } else {
            await updateDepartmentJob(
                currentUser.Data.Data.access_token,
                modalStatus?.Data?.Department_Job_Id,
                values.Department_Job_Name_AR,
                values.Department_Job_Name_EN,
                values.Department_Job_Type_Id,
                values.Department_Job_Signature_Text,
                values.Active_Status_Id ? 1 : 0
            ).then(() => {
                dispatch(triggerLoading({ status: false }))
                dispatch(triggerModalTab({ Open: false, Data: {} }))
            })
        }
    }

    let myForm
    if (!showJobSectionsList) {
        myForm = (
            <Form
                form={form}
                style={{ width: '100%' }}
                onFinish={handleSubmit}
                initialValues={{
                    "Department_Job_Name_AR": modalStatus?.Data?.Department_Job_Name_AR,
                    "Department_Job_Name_EN": modalStatus?.Data?.Department_Job_Name_EN,
                    "Department_Job_Signature_Text": modalStatus?.Data?.Department_Job_Signature_Text,
                    "Active_Status_Id": isChecked,
                    "Department_Job_Id": modalStatus?.Data?.Department_Job_Id,
                    "Department_Job_Type_Id": modalStatus?.Data?.Department_Job_Type_Id
                }}
            >
                <Row gutter={[12, 0]}>
                    <Col xs={24} md={3}>
                        <Form.Item name="Department_Job_Id">
                            <Textfield
                                label={t('jobNo') as string}
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3} style={{ textAlign: 'center' }}>
                        <Form.Item name="Active_Status_Id">
                            <Switch
                                label={t('status') as string}
                                disabled={modalStatus.Type == 'view'}
                                checked={isChecked}
                                onChange={(state) => setIsChecked(state == true ? 1 : 0)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item
                            name="Department_Job_Name_AR"
                            rules={[{ required: true, message: t('error.field_required') as string }]}
                        >
                            <Textfield
                                label={t('jobNameAR') as string}
                                disabled={modalStatus.Type == 'view'}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item
                            name="Department_Job_Name_EN"
                            rules={[{ required: true, message: t('error.field_required') as string }]}
                        >
                            <Textfield
                                label={t('jobNameEN') as string}
                                disabled={modalStatus.Type == 'view'}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item
                            name="Department_Job_Type_Id"
                            rules={[{ required: true, message: t('error.field_required') as string }]}
                        >
                            <Selectfield
                                label={t('jobType') as string}
                                options={jobTypesList}
                                disabled={modalStatus.Type == 'view'}
                                defaultValue={modalStatus?.Data?.Department_Job_Type_Id}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item
                            name="Department_Job_Signature_Text"
                        // rules={[{ required: true, message: t('error.field_required') as string }]}
                        >
                            <Textfield
                                label={t('jobSignature') as string}
                                disabled={modalStatus.Type == 'view'}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                {!modalStatus?.Open &&
                    <Row style={{ justifyContent: 'end' }}>
                        <Col>
                            <Button title={t('save') as string} onClick={() => form.submit()} />
                        </Col>
                    </Row>
                }

                {modalStatus?.Type !== 'add' &&
                    <Row>
                        <Col xs={24} md={24}>
                            <CustomAntdTab
                                defaultActiveKey="1"
                                hideAdd={true}
                                tabBarGutter={4}
                                items={[
                                    {
                                        key: '1',
                                        label: t('jobSections'),
                                        children: <JobSectionsList departmentJobId={modalStatus?.Data?.Department_Job_Id} modalType={modalStatus.Type} />,
                                    },
                                    {
                                        key: '2',
                                        label: t('jobGroupWorkersName'),
                                        children: <JobGroupWorkersNameList departmentJobId={modalStatus?.Data?.Department_Job_Id} />,
                                    }
                                ]}
                            />
                        </Col>
                    </Row>
                }
            </Form>
        )
    } else {
        myForm = departmentJobId && (<JobSectionsList departmentJobId={departmentJobId} modalType={modalStatus.Type} />)
    }

    return (
        <>
            <TabModal
                children={myForm}
                title={'مدخلات وظائف البلدية / Municipal Jobs Form'}
                link={'/department-jobs-form'}
                formData={formData}
                onOk={() => { departmentJobId == null ? form.submit() : dispatch(triggerModalTab({ Open: false, Data: {} })) }}
            />

            {showTabForm && myForm}
        </>
    )
}
