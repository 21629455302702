import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { getMyTasksList } from 'api/tasks/tasks'
import { useAppSelector, useAppDispatch } from 'store'
import GridExpress from 'components/GridExpress/GridExpress'
import CustomStore from 'devextreme/data/custom_store'
import { triggerModalTab } from 'features/utils/modalTabSlice'
import MyTasksForm from './MyTasksForm'
import moment from 'moment'

export default function MyTasksList(props: any) {

    const { t, i18n } = useTranslation()
    const [showModal, setShowModal] = useState<any>(false)
    const currentUser = useAppSelector((state: any) => state.currentUser)
    const modalStatus = useAppSelector(state => state.modalTab)
    const dispatch = useAppDispatch()

    useEffect(() => {
        setShowModal(modalStatus?.Open)
    }, [modalStatus])

    const columnsList = () => {
        let MissionTypeName = 'Mission_Type_Name_EN',
            MissionFrom = 'Mission_from_User_Name_EN',
            MissionTo = 'Mission_to_User_Name_EN'

        if (i18n.language == 'ar') {
            MissionTypeName = 'Mission_Type_Name_AR'
            MissionFrom = 'Mission_from_User_Name_AR'
            MissionTo = 'Mission_to_User_Name_AR'
        }

        return [
            {
                dataField: "Mission_No",
                caption: t('missionNo'),
            },
            {
                dataField: "Mission_Date",
                caption: t('missionDate'),
                cellRender: (data: any) => moment(data.Mission_Date).format('HH:mm YYYY-MM-DD')
            },
            {
                dataField: MissionFrom,
                caption: t('missionFrom'),
            },
            // {
            //     dataField: MissionTo,
            //     caption: t('missionTo'),
            // },
            {
                dataField: MissionTypeName,
                caption: t('missionTypeName'),
            },
            {
                dataField: "Mission_Title",
                caption: t('missionTitle'),
            },
        ]
    }

    const jsonDataSource = new CustomStore({
        key: "Mission_Id",
        loadMode: 'raw',
        load: async () => {
            if (props.dataList) {
                let data = props.dataList;
                return data;
            } else {
                const endpoint = await props.endpoint
                const data = endpoint ? endpoint.Data : getMyTasksList(currentUser.Data.Data.access_token)
                return data;
            }
        }
    })

    return (
        <>
            <Row>
                <Col>
                    <GridExpress
                        allowSelectAll
                        allowView={true}
                        allowEdit={false}
                        columnsList={columnsList()}
                        dataSource={jsonDataSource}
                        // handleEdit={(data) => dispatch(triggerModalTab({ Open: true, Data: data, Type: 'edit' }))}
                        handleView={(data) => dispatch(triggerModalTab({ Open: true, Data: data, Type: 'view' }))}
                        allowAdd={false}
                        gridTitle={t('myTasks') as string}
                        groupPanelVisibility={false}
                        xlsxFileName={t('myTasks') as string}
                    >
                    </GridExpress>
                </Col>
            </Row >

            {showModal && <MyTasksForm />}
        </>
    )
}