import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { getTasksList, changeTasksStatus } from 'api/tasks/tasks'
import { useAppSelector, useAppDispatch } from 'store'
import GridExpress from 'components/GridExpress/GridExpress'
import Switch from 'components/Switch'
import { triggerLoading } from 'features/utils/loadingSlice'
import CustomStore from 'devextreme/data/custom_store'
import { triggerModalTab } from 'features/utils/modalTabSlice'
import TasksForm from './TasksForm'
import moment from 'moment'

export default function TasksList() {

    const { t, i18n } = useTranslation()
    const [showModal, setShowModal] = useState<any>(false)
    const currentUser = useAppSelector((state: any) => state.currentUser)
    const modalStatus = useAppSelector(state => state.modalTab)
    const dispatch = useAppDispatch()

    useEffect(() => {
        setShowModal(modalStatus?.Open)
    }, [modalStatus])

    // const customizeCellCheckbox = (data: any) => {
    //     return (
    //         <Switch
    //             checked={data.data.Active_Status_Id}
    //             onChange={async () => {
    //                 let at = currentUser.Data.Data.access_token
    //                 let status = data.data.Active_Status_Id ? 0 : 1
    //                 let id = data.data.Department_Id
    //                 dispatch(triggerLoading({ status: true }))
    //                 await changeTasksStatus(
    //                     at,
    //                     id,
    //                     status
    //                 ).then(() => {
    //                     dispatch(triggerLoading({ status: false }))
    //                 })
    //             }}
    //         />
    //     )
    // }

    // const handleExportCustomization = (options: any) => {
    //     if (options.gridCell.rowType === 'data' && options.gridCell.column.dataField == "Active_Status_Id") {
    //         if (options.gridCell.value == 1) {
    //             options.excelCell.value = "true"
    //         } else {
    //             options.excelCell.value = "false"
    //         }
    //     }
    // }

    const columnsList = () => {
        let MissionTypeName = 'Mission_Type_Name_EN',
            MissionFrom = 'Mission_from_User_Name_EN',
            MissionTo = 'Mission_to_User_Name_EN'

        if (i18n.language == 'ar') {
            MissionTypeName = 'Mission_Type_Name_AR'
            MissionFrom = 'Mission_from_User_Name_AR'
            MissionTo = 'Mission_to_User_Name_AR'
        }

        return [
            {
                dataField: "Mission_No",
                caption: t('missionNo'),
            },
            {
                dataField: "Mission_Date",
                caption: t('missionDate'),
                cellRender: (data: any) => moment(data.Mission_Date).format('HH:mm YYYY-MM-DD')
            },
            {
                dataField: MissionTo,
                caption: t('missionTo'),
            },
            {
                dataField: MissionTypeName,
                caption: t('missionTypeName'),
            },
            {
                dataField: "Mission_Title",
                caption: t('missionTitle'),
            },
            // {
            //     dataField: "Active_Status_Id",
            //     cellRender: customizeCellCheckbox,
            //     caption: t('status'),
            //     width: '120px'
            // }
        ]
    }

    const jsonDataSource = new CustomStore({
        key: "Mission_Id",
        loadMode: 'raw',
        load: () => {
            return getTasksList(currentUser.Data.Data.access_token)
        }
    })

    return (
        <>
            <Row>
                <Col>
                    <GridExpress
                        allowSelectAll
                        allowView={false}
                        allowEdit={true}
                        columnsList={columnsList()}
                        dataSource={jsonDataSource}
                        handleAdd={(data) => dispatch(triggerModalTab({ Open: true, Data: data, Type: 'add' }))}
                        handleEdit={(data) => dispatch(triggerModalTab({ Open: true, Data: data, Type: 'edit' }))}
                        allowAdd={true}
                        gridTitle={t('assignedTasks') as string}
                        groupPanelVisibility={false}
                        xlsxFileName={t('assignedTasks') as string}
                    // handleWordCustomization={(options: any) => handleExportCustomization(options)}
                    >
                    </GridExpress>
                </Col>
            </Row >

            {showModal && <TasksForm />}
        </>
    )
}