import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import {
    getDepartmentsSectionsList,
    setDepartmentSectionStatus,
    deleteDepartmentSection
} from 'api/departments/departments'
import { useAppSelector, useAppDispatch } from 'store'
import GridExpress from 'components/GridExpress/GridExpress'
import Switch from 'components/Switch'
import { triggerLoading } from 'features/utils/loadingSlice'
import CustomStore from 'devextreme/data/custom_store'
import { triggerModalTab } from 'features/utils/modalTabSlice'
import SectionsForm from './SectionsForm'

export default function SectionsList() {
    const { t, i18n } = useTranslation()
    const currentUser = useAppSelector(state => state.currentUser)
    const modalStatus = useAppSelector(state => state.modalTab)
    const [showModal, setShowModal] = useState<any>(false)
    const dispatch = useAppDispatch()

    useEffect(() => {
        setShowModal(modalStatus?.Open)
    }, [modalStatus])

    const customizeCellCheckbox = (data: any) => {
        return (
            <Switch
                checked={data.data.Active_Status_Id}
                onChange={async () => {
                    let at = currentUser.Data.Data.access_token
                    let status = data.data.Active_Status_Id ? 0 : 1
                    let id = data.data.Department_Section_Id
                    dispatch(triggerLoading({ status: true }))
                    await setDepartmentSectionStatus(
                        at,
                        id,
                        status
                    ).then(() => {
                        dispatch(triggerLoading({ status: false }))
                    })
                }}
            />
        )
    }

    const handleExportCustomization = (options: any) => {
        if (options.gridCell.rowType === 'data' && options.gridCell.column.dataField == "Active_Status_Id") {
            if (options.gridCell.value == 1) {
                options.excelCell.value = "true"
            } else {
                options.excelCell.value = "false"
            }
        }
    }

    const columnsList = () => {
        let DepSectionNameKey = 'Department_Section_Name_EN'


        if (i18n.language == 'ar') {
            DepSectionNameKey = 'Department_Section_Name_AR'

        }

        return [
            {
                dataField: "Department_Section_No",
                caption: t('sectionNo'),
                width: '200px'
            },
            {
                dataField: DepSectionNameKey,
                caption: t('sectionName'),
            },
            {
                dataField: "Active_Status_Id",
                cellRender: customizeCellCheckbox,
                caption: t('status'),
                width: '120px'
            }
        ]
    }

    const onDelete = async (data: any) => {
        let at = currentUser.Data.Data.access_token
        let id = data.Department_Section_Id
        dispatch(triggerLoading({ status: true }))
        await deleteDepartmentSection(
            at,
            id
        ).then(() => {
            dispatch(triggerLoading({ status: false }))
        })
    }

    const jsonDataSource = new CustomStore({
        key: "Department_Section_Id",
        loadMode: 'raw',
        load: () => {
            return getDepartmentsSectionsList(
                currentUser.Data.Data.access_token,
                currentUser.Data.Department_Id,
            )
        }
    })

    return (
        <>
            <Row>
                <Col>
                    <GridExpress
                        allowSelectAll
                        allowView={true}
                        allowEdit={true}
                        allowDelete={true}
                        columnsList={columnsList()}
                        dataSource={jsonDataSource}
                        handleDelete={(data) => onDelete(data)}
                        handleView={(data) => dispatch(triggerModalTab({ Open: true, Data: data, Type: 'view' }))}
                        handleEdit={(data) => dispatch(triggerModalTab({ Open: true, Data: data, Type: 'edit' }))}
                        handleAdd={(data) => dispatch(triggerModalTab({ Open: true, Data: data, Type: 'add' }))}
                        allowAdd={true}
                        gridTitle={t('departmentSections') as string}
                        groupPanelVisibility={false}
                        xlsxFileName={t('departmentSections') as string}
                        handleWordCustomization={(options: any) => handleExportCustomization(options)}
                    >
                    </GridExpress>
                </Col>
            </Row>

            {showModal && <SectionsForm />}
        </>
    )
}