import { FC } from "react";
import styled from "styled-components";
import { Alert, AlertProps } from "antd";

interface IAlertCustomProps extends AlertProps {
  colorType?: "solid" | "pale" | "outline" | "simple";
}

interface IThemeProvider {
  theme: any;
}

const prefixCls = "ant-alert";

const Toast: FC<IAlertCustomProps> = (props) => {
  return <Alert {...props} />;
};

export default styled(Toast)`
  &.${prefixCls} {
    text-align: start;
    border-radius: 1rem;
    font-family: "Montserrat";
  }

  &.${prefixCls}-success {
    box-shadow: 0px 2px 26px rgba(82, 215, 0, 0.1);
  }
  &.${prefixCls}-error {
    box-shadow: 0px 2px 26px rgba(215, 0, 0, 0.1);
  }
  &.${prefixCls}-warning {
    box-shadow: 0px 2px 26px rgba(215, 0, 0, 0.1);
  }

  ${(props: IAlertCustomProps & IThemeProvider) =>
    props.colorType === "solid"
      ? `
      .${prefixCls}-message, .${prefixCls}-icon {
        color: white;
      }

      
      &.${prefixCls}-success {
        background-color: #6FCF97;
        border: 1px solid #6fcf97;
        color: white;

        .anticon-close {
          color : white
         }
         
      }
    
      &.${prefixCls}-error {
        background-color: ${props.theme.antd.colorError};
        border: 1px solid #eb5757;
        color: white;

        .anticon-close {
          color : white
         }
       
      }

      &.${prefixCls}-warning {
        background-color: ${props.theme.antd.colorPrimary};
        border: 1px solid ${props.theme.antd.colorPrimary};
        color: white;

        .anticon-close {
          color : white
         }

      }

      &.${prefixCls}-info {
        background-color: ${props.theme.antd.colorPrimary};
        border: 1px solid ${props.theme.antd.colorPrimary};
        color: white;

        .anticon-close {
          color : white
         }

      }
        `
      : props.colorType === "pale"
      ? `
      
      &.${prefixCls}-success {
        background-color: #6fcf97;
        border: 1px solid #6fcf97;
        color: white;

        .${prefixCls}-message, .${prefixCls}-icon, .anticon-close{
         color: white;
        }
      }
      
      &.${prefixCls}-error {
        background-color: #fee2e2;
        border: 1px solid #eb5757;
        color: black;
    
        .${prefixCls}-message, .${prefixCls}-icon {
          color: ${props.theme.antd.colorError};
        }

        .anticon-close {
          color: #7C2424;
        }

      }

      &.${prefixCls}-warning {
        background-color: #f8efd9;
        border: 1px solid ${props.theme.antd.colorPrimary};
        color: black;
    
        .${prefixCls}-message, .${prefixCls}-icon, .anticon-close{
          color: ${props.theme.antd.colorPrimary};
         }

      }
      &.${prefixCls}-info {
        background-color: #6fcf97;
        border: 1px solid black;
        color: black;
    
        .${prefixCls}-message {
          color: black;
        }
      }
        `
      : props.colorType === "outline"
      ? `

      
      &.${prefixCls}-success {
        background-color: white;
        border: 1px solid #6fcf97;
        color: #4D4D4D;


      .anticon-close {
        color : #828282;
      }
      .${prefixCls}-icon {
          color: #6fcf97;
      }

      }
      &.${prefixCls}-error {
        background-color: white;
        border: 1px solid #eb5757;
        color: #4D4D4D;
    
        .${prefixCls}-message {
          color: ${props.theme.antd.colorError};
        }
        .anticon-close {
          color : #828282;
        }
        .${prefixCls}-icon {
            color: #EB5757;
        }
        
      }

      &.${prefixCls}-warning {
        background-color: white;
        border: 1px solid ${props.theme.antd.colorPrimary};
        color: #4d4d4d;
    
        .${prefixCls}-message {
          color: ${props.theme.antd.colorPrimary};
        }
        .anticon-close {
          color : #828282;
        }
        .${prefixCls}-icon {
            color:  ${props.theme.antd.colorPrimary};
        }

      }
      &.${prefixCls}-info {
        background-color: white;
        border: 1px solid #6fcf97;
        color: black;
      }
       `
      : `
      &.${prefixCls}{
        background-color : white;
        border: none;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
      }
      
       `}
`;
