import React from "react";
import { Switch as AntdSwitch } from "antd";
import styled, { CSSProp, CSSProperties } from "styled-components";

const prefixCls = "ant-switch";

const AntSwitch = styled(AntdSwitch) <{}>`
  & .${prefixCls}-handle::before {
  background - color: ${(props) => (props.checked || props.defaultChecked ? "#fff;" : "#999999;")}
}
    & .${prefixCls} -inner {
  background - color: ${props => (!props.checked || !props.defaultChecked) && "#fff;"}
  border: ${props => (!props.checked || !props.defaultChecked) && "1px solid #999999;"}
}
`;
const SwitchWrapper = styled.div<{ title: string }>`
  display: ${props => props.title ? 'flex' : 'block'}
`;
const SwitchDetails = styled.div`
margin: 0 12px;
font - weight: 600;
fontSize: 16px;
line - height: 19px;
text - align: start;
`;
const SwitchTitle = styled.div`
color: #999999;
`;
const SwitchDesc = styled.div`
color: #cfd4d9;
`;
const SwitchLabel = styled.div<{ formfield: boolean }>`
  font-weight: ${props => props.formfield ? '600' : '400'};
  color: ${props => props.formfield ? props.theme.antd.colorTextLabel : '#000'};
  text-align: start;
  padding: 12px;
`

type CustomSwitchProps = {
  title?: string
  description?: string
  label?: string
  checked?: boolean
  disabled?: boolean
  size?: "default" | "small"
  defaultChecked?: boolean
  onChange?: (checked: boolean, event: any) => void
  formfield?: boolean
};

export default function Switch({
  title = '',
  size,
  label,
  description,
  onChange = () => { },
  formfield = true,
  ...rest
}: CustomSwitchProps) {
  return (
    <>
      {label ? <SwitchLabel formfield={formfield}>{label}</SwitchLabel> : null}
      <SwitchWrapper title={title}>
        <AntSwitch
          size="default"
          onChange={onChange}
          {...rest}
        />
        <SwitchDetails>
          <SwitchTitle>{title}</SwitchTitle>
          <SwitchDesc>{description}</SwitchDesc>
        </SwitchDetails>
      </SwitchWrapper>
    </>
  );
}
