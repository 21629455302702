import { ThemeConfig } from "antd/es/config-provider/context";

/* Customize/Override Ant Design components default theme */
export const theme: ThemeConfig = {
    token: {
        fontFamily: [
            "Almarai",
            "-apple-system", "BlinkMacSystemFont", 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            "sans-serif"
        ].join(","),
        colorPrimary: '#D8B86A',
        colorError: '#EF4444',
        colorSuccess: '#4E6B31',
        colorInfo: '#011224',
        colorSplit: '#C4C4C4',
        colorTextLabel: '#49afc7'
    },
    components: {
        Checkbox: {
            fontSize: 16
        },
        Radio: {
            fontSize: 16
        }
    }
}