function NewTabOutlined() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="256.000000pt" height="256.000000pt" viewBox="0 0 256.000000 256.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M329 2196 c-74 -26 -136 -85 -171 -161 l-23 -50 0 -700 c0 -663 1
-703 19 -749 23 -60 74 -118 135 -154 l46 -27 945 0 945 0 46 27 c61 36 112
94 135 154 18 46 19 86 19 744 0 658 -1 698 -19 744 -23 60 -74 118 -135 154
l-46 27 -930 2 c-743 2 -937 0 -966 -11z m1063 -358 c3 -199 5 -216 27 -265
27 -61 84 -116 150 -147 44 -20 64 -21 374 -24 l327 -3 0 -387 c0 -359 -2
-390 -19 -429 -36 -78 28 -73 -971 -73 -999 0 -935 -5 -971 73 -18 40 -19 75
-19 694 0 724 -1 715 64 754 29 18 58 19 533 19 l502 0 3 -212z m814 193 c55
-34 65 -80 62 -286 l-3 -180 -290 -3 c-178 -2 -304 1 -325 7 -20 6 -49 24 -65
41 l-30 31 -3 205 -3 204 313 0 c291 0 315 -1 344 -19z"/>
            </g>
        </svg>
    )
}

export default NewTabOutlined