import { Col } from "antd";
import DashCard from "components/DashCard";
import { BottomMargin, RowWrapper } from "./DashboartStyledComponents";
import { useTranslation } from "react-i18next";

const cardList = [
  {
    key: 1,
    number: "15",
    title: "معاملات جديدة",
    star: true,
    disabled: false,
    bgcolor: "#CCE5EB",
  },
  {
    key: 2,
    number: "55",
    title: "معاملات قيد  الدراسة",
    star: true,
    disabled: false,
    bgcolor: "#F0E9DA",
  },
  {
    key: 3,
    number: "30",
    title: "معاملات مستحقة الدفع",
    star: true,
    disabled: false,
    bgcolor: "#C6CFD4",
  },
  {
    key: 4,
    number: "55",
    title: "معاملات معادة من الإدارة",
    star: true,
    disabled: false,
    bgcolor: "#CCE5EB",
  },
  {
    key: 5,
    number: "0",
    title: "معاملات منتهية",
    star: false,
    disabled: true,
    bgcolor: "#D0B166",
  },
  {
    key: 6,
    number: "0",
    title: "معاملات مرفوضة",
    star: false,
    disabled: true,
    bgcolor: "#C6CFD4",
  },
  {
    key: 7,
    number: "15",
    title: "معاملات جديدة",
    star: false,
    disabled: false,
    bgcolor: "#CCE5EB",
  },
  {
    key: 8,
    number: "55",
    title: "معاملات قيد الدراسة",
    star: false,
    disabled: false,
    bgcolor: "#d0b16633",
  },
  {
    key: 9,
    number: "15",
    title: "معاملات جديدة",
    star: false,
    disabled: false,
    bgcolor: "#CCE5EB",
  },
  {
    key: 10,
    number: "55",
    title: "معاملات قيد الدراسة",
    star: false,
    disabled: false,
    bgcolor: "#d0b16633",
  },
  {
    key: 11,
    number: "30",
    title: "معاملات مستحقة الدفع",
    star: false,
    disabled: false,
    bgcolor: "#C6CACD",
  },
  {
    key: 12,
    number: "55",
    title: "معاملات معادة من الإدارة",
    star: false,
    disabled: false,
    bgcolor: "#CCE5EB",
  }
];

const DashboardFavouriteCards = () => {
  const { t, i18n } = useTranslation();

  return (
    <BottomMargin>
      <p style={{ fontSize: '22px', fontWeight: '700' }}>{t('serviceProviderTrans')}</p>
      <RowWrapper gutter={[20, 20]} justify={"space-between"} align={"middle"}>
        {cardList.map(({ key, number, title, star, bgcolor, disabled }) => (
          <Col sm={24} md={6} key={key}>
            <DashCard
              number={number}
              title={title}
              star={star}
              disabled={disabled}
              bgcolor={bgcolor}
            />
          </Col>
        ))}
      </RowWrapper>
    </BottomMargin>
  );
};

export default DashboardFavouriteCards;
