import React, { useState, useEffect, ReactNode, CSSProperties } from "react";
import { Card as AntdCard } from "antd";
import styled from "styled-components";

const StyledCard = styled(AntdCard)<{
  radiussize: string;
  children: ReactNode;
  bgcolor: string;
  boxshadow: boolean;
}>`
  border: none;
  ${(props) => props.radiussize == "default" && "border-radius: 20px;"}
  ${(props) => props.radiussize == "small" && "border-radius: 15px;"}
    ${(props) => props.radiussize == "large" && "border-radius: 49px;"}
    ${(props) =>
    props.boxshadow == true
      ? "box-shadow: 0px 0px 30px rgba(1, 18, 36, 0.06)!important;"
      : "box-shadow: none!important;"}
    ${(props) => `background: ${props.bgcolor}`}
`;

type CardProps = {
  //default = 20px, small = 15px, large = 49
  radiussize?: "default" | "small" | "large";
  loading?: boolean;
  children?: ReactNode;
  bgcolor?: string;
  boxshadow?: boolean;
  className?: string;
  style?: CSSProperties | undefined;
  bodyStyle?: CSSProperties | undefined;
};

export default function Card({
  radiussize = "default",
  loading = false,
  children,
  bgcolor = "#F7F7F7",
  boxshadow = false,

  ...rest
}: CardProps) {
  return (
    <StyledCard
      radiussize={radiussize}
      loading={loading}
      bgcolor={bgcolor}
      boxshadow={boxshadow}
      {...rest}
    >
      {children}
    </StyledCard>
  );
}
