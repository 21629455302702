import styled from "styled-components";
import { Row } from "antd";

export const RowWrapper = styled(Row)`
  margin: 0 !important;
`;

export const BottomMargin = styled.div`
  margin-bottom: 30px;
`;
