import React from 'react'

function FilesOutlined() {
  return (
    <svg width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.4799 30H6.84991C6.13648 30 5.45227 29.7166 4.94779 29.2121C4.44332 28.7076 4.15991 28.0234 4.15991 27.31V2.69C4.15991 1.97657 4.44332 1.29236 4.94779 0.787883C5.45227 0.28341 6.13648 0 6.84991 0H14.4799C15.1629 0.00139376 15.8214 0.254129 16.3299 0.71L25.2299 8.71C25.5226 8.97175 25.7567 9.29231 25.9169 9.65074C26.0772 10.0092 26.16 10.3974 26.1599 10.79V27.31C26.1599 28.0217 25.8779 28.7044 25.3756 29.2086C24.8733 29.7128 24.1916 29.9974 23.4799 30ZM6.84991 2C6.66691 2 6.49141 2.0727 6.36201 2.2021C6.23261 2.3315 6.15991 2.507 6.15991 2.69V27.31C6.15991 27.493 6.23261 27.6685 6.36201 27.7979C6.49141 27.9273 6.66691 28 6.84991 28H23.4799C23.6629 28 23.8384 27.9273 23.9678 27.7979C24.0972 27.6685 24.1699 27.493 24.1699 27.31V10.74C24.1697 10.6305 24.1465 10.5222 24.1017 10.4223C24.0569 10.3224 23.9915 10.233 23.9099 10.16L15.0099 2.16C14.8588 2.04365 14.6702 1.98673 14.4799 2H6.84991Z" fill="white" fillOpacity="0.5" />
      <path d="M25.1599 10.94H16.3499C15.7691 10.94 15.2121 10.7093 14.8013 10.2986C14.3906 9.88786 14.1599 9.33082 14.1599 8.75V1.56H16.1599V8.75C16.1599 8.80039 16.1799 8.84872 16.2156 8.88435C16.2512 8.91998 16.2995 8.94 16.3499 8.94H25.1599V10.94Z" fill="white" fillOpacity="0.5" />
      <path d="M19.5 23H11.5C10.9477 23 10.5 23.4477 10.5 24C10.5 24.5523 10.9477 25 11.5 25H19.5C20.0523 25 20.5 24.5523 20.5 24C20.5 23.4477 20.0523 23 19.5 23Z" fill="white" fillOpacity="0.5" />
      <path d="M13.5 18.69H11.5C10.9477 18.69 10.5 19.1377 10.5 19.69C10.5 20.2423 10.9477 20.69 11.5 20.69H13.5C14.0523 20.69 14.5 20.2423 14.5 19.69C14.5 19.1377 14.0523 18.69 13.5 18.69Z" fill="white" fillOpacity="0.5" />
      <path d="M2.49 25.66C1.82859 25.6521 1.1968 25.3845 0.730959 24.9149C0.265116 24.4454 0.00257805 23.8114 5.20824e-08 23.15V6.85C-7.56225e-05 6.47924 0.0823147 6.1131 0.241203 5.77812C0.400091 5.44313 0.631499 5.14767 0.918664 4.91316C1.20583 4.67864 1.54156 4.51093 1.90154 4.42217C2.26152 4.33342 2.63673 4.32585 3 4.4L5.15 4.76L4.82 6.76L2.65 6.37C2.57156 6.34872 2.48927 6.34578 2.40951 6.36138C2.32975 6.37699 2.25464 6.41073 2.19 6.46C2.13107 6.50656 2.08339 6.5658 2.0505 6.63332C2.01761 6.70084 2.00035 6.77489 2 6.85V23.15C2.00147 23.2246 2.0193 23.298 2.05222 23.3649C2.08513 23.4319 2.13234 23.4908 2.19051 23.5375C2.24868 23.5843 2.31639 23.6176 2.38888 23.6353C2.46136 23.6531 2.53684 23.6546 2.61 23.64L4.87 23.34L5.13 25.34L2.94 25.63C2.7915 25.656 2.64064 25.6661 2.49 25.66Z" fill="white" fillOpacity="0.5" />
    </svg>

  )
}

export default FilesOutlined