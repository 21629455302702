import { useEffect, useState } from 'react'
import TabModal from 'components/TabModal'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'
import { updateDepartments } from 'api/departments/departments'
import { useAppSelector, useAppDispatch } from 'store'
import { triggerLoading } from 'features/utils/loadingSlice'
import Textfield from "components/Textfield"
import Button from 'components/Button'
import Map from 'components/Map'
import Htmleditor from 'components/Htmleditor'
import ImageUploader from 'components/ImageUploader'
import { triggerModalTab } from 'features/utils/modalTabSlice'
import Switch from 'components/Switch'
import { apiConfig } from '../../../api/apiConfig'
import { Item } from 'devextreme-react/html-editor'

export default function DepartmentsForm() {
    const { t, i18n } = useTranslation()
    const [showTabForm, setShowTabForm] = useState<any>(false)
    const [isChecked, setIsChecked] = useState<any>()
    const [formData, setFormData] = useState<any>()
    const [mapPosition, setMapPosition] = useState<any>({
        lat: 0,
        lng: 0
    })
    const [depLogo, setDepLogo] = useState<any>()
    const currentUser = useAppSelector((state: any) => state.currentUser)
    const currentactiveTab = useAppSelector(state => state.activeTab.Data)
    const modalStatus = useAppSelector(state => state.modalTab)
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()

    useEffect(() => {
        setFormData(modalStatus?.Data)
    }, [])

    useEffect(() => {
        setIsChecked(modalStatus?.Data?.Active_Status_Id)
        setMapPosition({
            lat: modalStatus?.Data?.Department_latitudes,
            lng: modalStatus?.Data?.Department_longitudes
        })
    }, [modalStatus])

    useEffect(() => {
        if (currentactiveTab == '/department-form') {
            setShowTabForm(true)
            setTimeout(() => dispatch(triggerLoading({ status: false })), 1000)
        }
    }, [currentactiveTab])

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(getPosition, null, {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            });
        }
    }

    const getPosition = (position: any) => {
        setMapPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        })
    }

    const handleUpload = async (options: any) => {
        const { onSuccess, onError, file, onProgress } = options
        setDepLogo(file)
    }

    const handleSubmit = async (values: any) => {
        dispatch(triggerLoading({ status: true }))
        const bodyFormData = new FormData(),
            at = currentUser.Data.Data.access_token,
            url = '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS/UPD_DEPARTMENT_LOGO',
            bodyData = {
                Department_Id: modalStatus?.Data?.Department_Id,
            }

        bodyFormData.append('PARAMETERS', JSON.stringify(bodyData))
        bodyFormData.append('Department_Logo', depLogo)

        const response = await apiConfig.post(
            url,
            bodyFormData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        )

        await updateDepartments(
            at,
            modalStatus?.Data?.Department_Id,
            values.Department_No,
            values.Department_Code,
            values.Department_Name_AR,
            values.Department_Name_EN,
            values.Department_Address_AR,
            values.Department_Address_EN,
            values.Department_Description_AR,
            values.Department_Description_EN,
            isChecked,
            values.Department_Email,
            values.Department_Facebook,
            values.Department_Telephone,
            values.Department_Instagram,
            values.Department_Website,
            values.Department_Twitter,
            mapPosition.lat,
            mapPosition.lng
        ).then(() => {
            dispatch(triggerLoading({ status: false }))
            dispatch(triggerModalTab({ Open: false, Data: {} }))
        })
    }

    const myForm = (
        <Form
            form={form}
            style={{ width: '100%' }}
            onFinish={handleSubmit}
            initialValues={{
                "Department_No": modalStatus?.Data?.Department_No,
                "Department_Code": modalStatus?.Data?.Department_Code,
                "Department_Name_AR": modalStatus?.Data?.Department_Name_AR,
                "Department_Name_EN": modalStatus?.Data?.Department_Name_EN,
                "Department_Address_AR": modalStatus?.Data?.Department_Address_AR,
                "Department_Address_EN": modalStatus?.Data?.Department_Address_EN,
                "Department_Description_AR": modalStatus?.Data?.Department_Description_AR,
                "Department_Description_EN": modalStatus?.Data?.Department_Description_EN,
                "Active_Status_Id": modalStatus?.Data?.Active_Status_Id,
                "Department_Email": modalStatus?.Data?.Department_Email,
                "Department_Facebook": modalStatus?.Data?.Department_Facebook,
                "Department_Telephone": modalStatus?.Data?.Department_Telephone,
                "Department_Instagram": modalStatus?.Data?.Department_Instagram,
                "Department_Website": modalStatus?.Data?.Department_Website,
                "Department_Twitter": modalStatus?.Data?.Department_Twitter,
                "Department_Logo_Url": modalStatus?.Data?.Department_Logo_Url
            }}
        >
            <Row gutter={[12, 0]}>
                <Col xs={24} md={12}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item name="Department_No">
                                <Textfield
                                    label={t('departmentID') as string}
                                    disabled={modalStatus.Type == 'view'}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={8}>
                            <Form.Item name="Department_Code">
                                <Textfield
                                    label={t('DepartmentCode') as string}
                                    disabled={modalStatus.Type == 'view'}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={8} push={1}>
                            <Form.Item>
                                <Switch
                                    label={t('status') as string}
                                    disabled={modalStatus.Type == 'view'}
                                    defaultChecked={modalStatus?.Data?.Active_Status_Id == 1 ? true : false}
                                    onChange={(state) => setIsChecked(state == true ? 1 : 0)}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item name="Department_Name_AR">
                                <Textfield
                                    label={t('DepartmentNameAr') as string}
                                    disabled={modalStatus.Type == 'view'}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item name="Department_Name_EN">
                                <Textfield
                                    label={t('DepartmentNameEn') as string}
                                    disabled={modalStatus.Type == 'view'}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item name="Department_Address_AR">
                                <Textfield
                                    label={t('DepartmentAddAr') as string}
                                    disabled={modalStatus.Type == 'view'}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item name="Department_Address_EN">
                                <Textfield
                                    label={t('DepartmentAddEN') as string}
                                    disabled={modalStatus.Type == 'view'}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item name="Department_Email">
                                <Textfield
                                    label={t('mail') as string}
                                    disabled={modalStatus.Type == 'view'}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item name="Department_Facebook">
                                <Textfield
                                    label={t('facebook') as string}
                                    disabled={modalStatus.Type == 'view'}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item name="Department_Telephone">
                                <Textfield
                                    label={t('phone') as string}
                                    disabled={modalStatus.Type == 'view'}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item name="Department_Instagram">
                                <Textfield
                                    label={t('instagram') as string}
                                    disabled={modalStatus.Type == 'view'}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item name="Department_Website">
                                <Textfield
                                    label={t('website') as string}
                                    disabled={modalStatus.Type == 'view'}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item name="Department_Twitter">
                                <Textfield
                                    label={t('twitter') as string}
                                    disabled={modalStatus.Type == 'view'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} md={12}>
                    <Row gutter={[0, 12]}>
                        <Col xs={24} md={24}>
                            <Form.Item name="Department_Logo_Url">
                                <ImageUploader
                                    label={t('departmentAvatar') as string}
                                    disabled={modalStatus.Type == 'view'}
                                    defaultImg={modalStatus?.Data?.Department_Logo_Url}
                                    handleUpload={(options: any) => handleUpload(options)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <button
                                style={{
                                    margin: '8px',
                                    borderRadius: '10px',
                                    padding: '6px 12px',
                                    border: '0',
                                    cursor: 'pointer',
                                    display: modalStatus.Type == 'view' ? 'none' : 'block'
                                }}
                                type='button'
                                onClick={() => getCurrentLocation()}
                            >
                                {t('knowMyLocation')}
                            </button>
                            <Map
                                center={{
                                    lat: parseFloat(mapPosition.lat),
                                    lng: parseFloat(mapPosition.lng)
                                }}
                                defaultMarkerPos={{
                                    lat: parseFloat(mapPosition.lat),
                                    lng: parseFloat(mapPosition.lng)
                                }}
                                onMarkerDragEnd={(e) => {
                                    setMapPosition({
                                        lat: e?.latLng?.lat(),
                                        lng: e?.latLng?.lng()
                                    })
                                }}
                                draggable={modalStatus.Type == 'view' ? false : true}
                                viewMode={modalStatus.Type == 'view' ? true : false}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row >

            <Row gutter={[12, 0]}>
                <Col xs={24} md={12}>
                    <Form.Item name="Department_Description_AR">
                        <Htmleditor
                            label={t('departmentDescAr') as string}
                            initialValues={modalStatus?.Data?.Department_Description_AR}
                            onValueChanged={(e: any) => form.setFieldsValue({ 'Department_Description_AR': e.value })}
                            disabled={modalStatus.Type == 'view'}
                        >
                            <Item name="undo" />
                            <Item name="redo" />
                            <Item name="separator" />
                            <Item name="bold" />
                            <Item name="italic" />
                            <Item name="underline" />
                            <Item name="strike" />
                            <Item name="separator" />
                            <Item name="link" />
                            <Item name="header" acceptedValues={[false, 1, 2, 3, 4, 5]} />
                            <Item name="size" acceptedValues={['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']} />
                            <Item name="orderedList" />
                            <Item name="bulletList" />
                            <Item name="alignLeft" />
                            <Item name="alignCenter" />
                            <Item name="alignRight" />
                        </Htmleditor>
                    </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                    <Form.Item name="Department_Description_EN">
                        <Htmleditor
                            label={t('departmentDescEn') as string}
                            initialValues={modalStatus?.Data?.Department_Description_EN}
                            onValueChanged={(e: any) => form.setFieldsValue({ 'Department_Description_EN': e.value })}
                            disabled={modalStatus.Type == 'view'}
                        >
                            <Item name="undo" />
                            <Item name="redo" />
                            <Item name="separator" />
                            <Item name="bold" />
                            <Item name="italic" />
                            <Item name="underline" />
                            <Item name="strike" />
                            <Item name="separator" />
                            <Item name="link" />
                            <Item name="header" acceptedValues={[false, 1, 2, 3, 4, 5]} />
                            <Item name="size" acceptedValues={['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']} />
                            <Item name="orderedList" />
                            <Item name="bulletList" />
                            <Item name="alignLeft" />
                            <Item name="alignCenter" />
                            <Item name="alignRight" />
                        </Htmleditor>
                    </Form.Item>
                </Col>
            </Row>

            {!modalStatus?.Open &&
                <Row style={{ justifyContent: 'end' }}>
                    <Col>
                        <Button title={t('save') as string} onClick={() => form.submit()} />
                    </Col>
                </Row>
            }

        </Form >
    )

    return (
        <>
            <TabModal
                children={myForm}
                title={'مدخلات البلدية / Department Form'}
                link={'/department-form'}
                formData={formData}
                onOk={() => { form.submit() }}
            />

            {showTabForm && myForm}
        </>
    )
}
