import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ILoadingInitialState {
  status: boolean;
  style?: string;
  zIndex?: number;
}

const initialState: ILoadingInitialState = {
  status: false,
  style: "light",
  zIndex: 900,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    triggerLoading: (state, action: PayloadAction<ILoadingInitialState>) => {
      state.status = action.payload.status;
      state.style = action.payload.style;
      state.zIndex = action.payload.zIndex;
    },
  },
});

export default loadingSlice.reducer;
export const { triggerLoading } = loadingSlice.actions;
