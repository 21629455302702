import { useEffect, useState } from 'react'
import TabModal from 'components/TabModal'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'
import {
    getMissionAttachmentList,
    getMissionAttachmentsResList,
    onCompleteTheMission,
    onOpenTheMission
} from 'api/tasks/tasks'
import { useAppSelector, useAppDispatch } from 'store'
import { triggerLoading } from 'features/utils/loadingSlice'
import Textfield from "components/Textfield"
import Button from 'components/Button'
import moment from 'moment'
import Textarea from "components/Textarea"
import FilesUploadedList from 'components/FilesUploadedList'
import { apiConfig } from '../../../api/apiConfig'
import { triggerModalTab } from 'features/utils/modalTabSlice'
import FileUploader from 'components/FileUploader'

export default function MyTasksForm() {

    const { t, i18n } = useTranslation()
    const [showTabForm, setShowTabForm] = useState<any>(false)
    const [formData, setFormData] = useState<any>()
    const currentactiveTab = useAppSelector(state => state.activeTab.Data)
    const [missionAttachment, setMissionAttachment] = useState<any>()
    const [attachmentList, setAttachmentList] = useState<any>([])
    const modalStatus = useAppSelector(state => state.modalTab)
    const currentUser = useAppSelector((state: any) => state.currentUser)
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()

    useEffect(() => {
        setFormData(modalStatus?.Data)
        getAttachment()
    }, [])

    useEffect(() => {
        if (currentactiveTab == '/my-tasks-form') {
            setShowTabForm(true)
            setTimeout(() => dispatch(triggerLoading({ status: false })), 1000)
        }
    }, [currentactiveTab])

    const getAttachment = async () => {
        await getMissionAttachmentsResList(currentUser.Data.Data.access_token, modalStatus?.Data?.Mission_Id)
            .then(res => {
                let data = res?.data?.Data
                data?.map((file: any) => {
                    setAttachmentList((oldArray: any) => [...oldArray, file])
                })
            })
        await getMissionAttachmentList(currentUser.Data.Data.access_token, modalStatus?.Data?.Mission_Id)
            .then(res => {
                res?.map((file: any) => setAttachmentList((oldArray: any) => [...oldArray, file]))
            })
    }

    const handleOnView = async (data: any) => {
        window.open(data.Mission_Attach_File, "_blank", "noopener")
    }

    const handleFileUploader = async (data: any) => {
        let bodyFormData = new FormData(),
            at = currentUser.Data.Data.access_token,
            bodyData = { mission_Id: modalStatus?.Data.Mission_Id }
        bodyFormData.append("PARAMETERS", JSON.stringify(bodyData))
        bodyFormData.append('Mission_Attach_File', data?.file)
        await apiConfig.post('/API/ADMINISTRATION/MISSIONS/ADD_MISSION_ATTACHMENT',
            bodyFormData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
                onUploadProgress: event => {
                    if (event?.loaded && event?.total) {
                        let percent = Math.floor((event?.loaded / event?.total) * 100);
                        data.onProgress({ percent: percent })
                        if (percent === 100) {
                            setTimeout(() => percent = 0, 1000);
                        }
                    }
                }
            })
            .then((res: any) => {
                if (res.data.Status) {
                    data.onSuccess()
                    getAttachment()
                } else {
                    data.onError()
                }
            })
    }

    const handleOnMission = async () => {
        if (modalStatus?.Data?.Mission_Type_Id === 1) {
            dispatch(triggerLoading({ status: true }))
            await onOpenTheMission(
                currentUser.Data.Data.access_token,
                modalStatus?.Data.Mission_Id
            ).then(res => {
                dispatch(triggerLoading({ status: false }))
                dispatch(triggerModalTab({ Open: false, Data: {} }))
            })
        } else if (modalStatus?.Data?.Mission_Type_Id === 2) {
            if (await form.validateFields(['Mission_Response_Description'])) {
                dispatch(triggerLoading({ status: true }))
                let at = currentUser.Data.Data.access_token
                await onCompleteTheMission(
                    currentUser.Data.Data.access_token,
                    modalStatus?.Data.Mission_Id,
                    form.getFieldValue(['Mission_Response_Description'])
                )
            }
        }
    }

    const myForm = (
        <Form
            form={form}
            style={{ width: '100%' }}
            // onFinish={handleSubmit}
            initialValues={{
                "Mission_Date": moment(modalStatus?.Data?.Mission_Date).format('HH:mm YYYY-MM-DD'),
                "Mission_Type_Name_AR": modalStatus?.Data?.Mission_Type_Name_AR,
                "Mission_Type_Name_EN": modalStatus?.Data?.Mission_Type_Name_EN,
                "Mission_from_User_Name_AR": modalStatus?.Data?.Mission_from_User_Name_AR,
                "Mission_from_User_Name_EN": modalStatus?.Data?.Mission_from_User_Name_EN,
                "Mission_Title": modalStatus?.Data?.Mission_Title,
                "Mission_Description": modalStatus?.Data?.Mission_Description,
                "Mission_Response_Description": modalStatus?.Data?.Mission_Response_Description
            }}
        >
            {modalStatus?.Data?.Mission_Type_Id === 3 &&
                <Row>
                    <Col xs={24}>
                        <p
                            style={{
                                textAlign: 'center',
                                color: 'red',
                                fontSize: '14px',
                                margin: '0.5rem',
                                fontWeight: 'bold'
                            }}
                        >{t('taskDone')}</p>
                    </Col>
                </Row>
            }

            <Row gutter={[12, 0]}>
                <Col xs={24} md={6}>
                    <Form.Item name="Mission_Date">
                        <Textfield
                            label={t('missionDate') as string}
                            disabled={true}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                    <Form.Item name={i18n.language == 'ar' ? "Mission_from_User_Name_AR" : "Mission_from_User_Name_EN"}>
                        <Textfield
                            label={t('missionFrom') as string}
                            disabled={true}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                    <Form.Item name={i18n.language == 'ar' ? "Mission_Type_Name_AR" : "Mission_Type_Name_EN"}>
                        <Textfield
                            label={t('missionType') as string}
                            disabled={true}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                    <Form.Item name="Mission_Title">
                        <Textfield
                            label={t('missionTitle') as string}
                            disabled={true}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                    <Form.Item name="Mission_Description">
                        <Textarea
                            label={t('missionDesc') as string}
                            disabled={true}
                        />
                    </Form.Item>
                </Col>

                {modalStatus?.Data?.Mission_Type_Id !== 1 &&
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="Mission_Response_Description"
                            rules={[{ required: modalStatus?.Data?.Mission_Type_Id === 2 && true, message: t('error.field_required') as string }]}
                        >
                            <Textarea
                                label={t('notes') as string}
                                disabled={modalStatus?.Data?.Mission_Type_Id == 3 || modalStatus?.Type == 'view' && true}
                            />
                        </Form.Item>
                    </Col>
                }


                {attachmentList.length > 0 &&
                    <Col xs={24} md={12}>
                        <FilesUploadedList
                            label={t('attachmentList') as string}
                            attachmentList={attachmentList}
                            fileName='Mission_Attach_File_Name'
                            canView={true}
                            onView={(data: any) => handleOnView(data)}
                        />
                    </Col>
                }

                {modalStatus?.Data?.Mission_Type_Id == 2 &&
                    <Col xs={24} md={8}>
                        <Form.Item
                            name="Mission_Attach_File"
                            rules={[{ required: true, message: t('error.field_required') as string }]}
                        >
                            <FileUploader
                                label={t('taskAttachmentsResponse') as string}
                                formfield={true}
                                // handleUpload={(options: any) => handleUpload(options)}
                                handleUpload={(data: any) => handleFileUploader(data)}
                            />
                        </Form.Item>
                    </Col>
                }
            </Row>

            <Row style={{ justifyContent: 'end' }}>
                {!modalStatus?.Open && modalStatus?.Data?.Mission_Type_Id === 2 &&
                    <>
                        <Col>
                            <Button btnsize='small' title={t('save') as string} onClick={() => form.submit()} />
                        </Col>
                        <Col>
                            <Button title={t('compeleteTask') as string} onClick={() => handleOnMission()} />
                        </Col>
                    </>
                }
                {!modalStatus?.Open && modalStatus?.Data?.Mission_Type_Id === 1 &&
                    <Col>
                        <Button title={t('handleTask') as string} onClick={() => handleOnMission()} />
                    </Col>
                }
            </Row>

        </Form >
    )

    const bonusBtn = {
        btncolor: "secondary",
        title: modalStatus?.Data?.Mission_Type_Id === 2 ? t('compeleteTask') as string : t('handleTask') as string,
        onClick: () => handleOnMission()
    }

    return (
        <>
            <TabModal
                children={myForm}
                title={'مدخلات قائمة التكليفات / Tasks List Form'}
                link={'/my-tasks-form'}
                formData={formData}
                onOk={() => { form.submit() }}
                bonusBtn={
                    modalStatus?.Type == 'edit' &&
                        modalStatus?.Data?.Mission_Type_Id === 2 || modalStatus?.Data?.Mission_Type_Id === 1
                        ? bonusBtn :
                        {}
                }
                // showSave={modalStatus?.Data?.Mission_Type_Id === 1 && false}
                showSave={false}
            />

            {showTabForm && myForm}
        </>
    )
}