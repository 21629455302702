import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface IActiveTabInitialState {
    Data: any
}

const initialState: IActiveTabInitialState = {
    Data: ''
}

const activeTabSlice = createSlice({
    name: "activeTab",
    initialState,
    reducers: {
        triggerActiveTab: (state, action: PayloadAction<IActiveTabInitialState>) => {
            state.Data = action.payload.Data
        }
    }
})

export default activeTabSlice.reducer
export const { triggerActiveTab } = activeTabSlice.actions