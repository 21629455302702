import React from 'react'

function ArrowOutlinedLeft() {
    return (
        <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.3209 0.75119C10.4971 0.911924 10.6038 1.12836 10.6205 1.35868C10.6371 1.589 10.5623 1.81693 10.4109 1.99858L10.3209 2.09618L3.75863 8.10763H21.9192C22.1822 8.09809 22.439 8.1857 22.6343 8.35171C22.8297 8.51772 22.9483 8.74902 22.9648 8.99617C22.9813 9.24332 22.8945 9.4868 22.7228 9.67453C22.5511 9.86227 22.3081 9.97941 22.0458 10.001H21.9192L1.26365 10.001C1.06097 10.0019 0.86245 9.9467 0.692854 9.84231C0.523258 9.73792 0.390074 9.58896 0.309841 9.41389C0.242132 9.26093 0.218805 9.09379 0.242403 8.92959C0.266003 8.76538 0.335512 8.61002 0.44397 8.47945L0.535625 8.38474L8.86653 0.748387C9.05992 0.572156 9.31785 0.472542 9.5871 0.470137C9.85636 0.467733 10.1164 0.562675 10.3133 0.735421L10.327 0.748387L10.3209 0.75119ZM5.61744 11.7206L5.71971 11.8038L10.3209 16.0182C10.4165 16.1054 10.4925 16.2098 10.5444 16.3252C10.5964 16.4406 10.6231 16.5646 10.6231 16.6899C10.6231 16.8152 10.5964 16.9392 10.5444 17.0546C10.4925 17.17 10.4165 17.2745 10.3209 17.3617C10.1426 17.5253 9.90829 17.624 9.65976 17.64C9.41122 17.6561 9.16457 17.5885 8.96415 17.4493L8.8574 17.3632L4.26236 13.1531C4.1667 13.0657 4.09056 12.9612 4.03863 12.8457C3.98669 12.7302 3.96001 12.606 3.96001 12.4806C3.96001 12.3552 3.98669 12.231 4.03863 12.1155C4.09056 12 4.1667 11.8955 4.26236 11.8081C4.44026 11.6444 4.6743 11.5455 4.92258 11.5292C5.17085 11.5129 5.41708 11.5803 5.61744 11.7192V11.7206Z" fill="white" />
        </svg>
    )
}

export default ArrowOutlinedLeft
