import React, { ReactNode } from "react";
import styled from "styled-components";
import { Select, Space } from "antd";

const prefixCls = "ant-select";

const AntdSelectField = styled(Select) <{ status: string, formfield: boolean, disabled: boolean }>`
  width: 100%;
  border-radius: 15px;
  ${props => props.status == 'error' && `
    border: 1px solid ${props.theme.antd.colorError}!important;
  `} 

  ${props => props.disabled && `
    .${prefixCls}-selector {
        color: #000000e0!important;
    }
  `}
  
  .${prefixCls}-selector {
    background: #f5f5f5 !important;
    border-radius: 15px !important;
    padding: 7px 11px !important;
    border: 1px solid #e3e0e0;
    height: 38px !important;
    ${props => props.status == 'error' && `
      border: 1px solid ${props.theme.antd.colorError}!important;
    `} 
    ${props => props.formfield && `
      &:hover {
        border: 1px solid ${props.theme.antd.colorTextLabel}!important;
      }
    `}
  }

  .${prefixCls}-arrow path {
    fill: ${(props) => props.theme.antd.colorPrimary};
  }

  .${prefixCls}-selection-placeholder {
    color: #b5b5c3;
    fontSize: 16px;
    font-weight: 400;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .${prefixCls}-selection-search {
    margin-inline-start: unset;
  }

  .${prefixCls}-selection-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

const Label = styled.div <{ formfield: boolean }>`
  font-family: "Almarai";
  font-style: normal;
  font-weight: ${props => props.formfield ? '600' : '400'};
  fontSize: 16px;
  color: ${props => props.formfield ? props.theme.antd.colorTextLabel : '#000'};
  text-align: start;
  padding: 12px;
`;

const arrow = (
  <svg
    width="13"
    height="7"
    viewBox="0 0 13 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.357959 0.289878C0.524877 0.12283 0.748111 0.0210334 0.985993 0.00346965C1.22388 -0.0140941 1.46009 0.0538275 1.65066 0.194482L1.76143 0.289878L6.06577 4.61732L10.3701 0.289878C10.5371 0.122512 10.7606 0.0205277 10.9987 0.0029599C11.2369 -0.0146079 11.4734 0.0534965 11.6641 0.194482L11.7748 0.289878C11.9433 0.458335 12.0454 0.679876 12.063 0.915127C12.0806 1.15038 12.0126 1.38415 11.871 1.57489L11.7748 1.68464L6.76817 6.71124C6.60125 6.87829 6.37802 6.98009 6.14014 6.99765C5.90226 7.01521 5.66597 6.94736 5.4754 6.80671L5.36462 6.71124L0.357959 1.68464C0.171721 1.49841 0.0673828 1.24806 0.0673828 0.987294C0.0673828 0.726522 0.171721 0.476106 0.357959 0.289878Z"
      fill="#D0B166"
    />
  </svg>
);

type CustomSelectProps = {
  label?: string;
  status?: "error" | "warning" | "";
  defaultValue?: string | number;
  options?: { label: string; value: string | number }[];
  mode?: "multiple" | "tags";
  disabled?: boolean;
  allowClear?: boolean;
  showSearch?: boolean;
  placeholder?: string;
  loading?: boolean;
  showArrow?: boolean;
  suffixIcon?: ReactNode;
  optionFilterProp?: string;
  formfield?: boolean;
  onChange?: (e: any) => void;
};

export default function Selectfield({
  label,
  status = "",
  defaultValue,
  options,
  mode,
  disabled = false,
  allowClear = false,
  showSearch = false,
  placeholder,
  loading = false,
  showArrow = true,
  suffixIcon = arrow,
  optionFilterProp = "children",
  formfield = true,
  onChange = () => { },
}: CustomSelectProps) {

  return (
    <div>
      {label && <Label formfield={formfield}>{label}</Label>}
      <AntdSelectField
        status={status}
        defaultValue={defaultValue}
        options={options}
        mode={mode}
        disabled={disabled}
        allowClear={allowClear}
        showSearch={showSearch}
        placeholder={placeholder}
        loading={loading}
        showArrow={true}
        suffixIcon={arrow}
        optionFilterProp={optionFilterProp}
        formfield={formfield}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        onChange={onChange}
      />
    </div>
  );
}
