import React from 'react'

function SearchOutlined() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_300_1646)">
        <path d="M19.7552 18.5332L16.3185 15.1857L16.2373 15.0633C16.0855 14.9145 15.8815 14.8312 15.669 14.8312C15.4564 14.8312 15.2524 14.9145 15.1006 15.0633C13.6714 16.3548 11.8303 17.0976 9.90498 17.1595C7.97962 17.2214 6.09471 16.5984 4.58547 15.4013C3.09379 14.2295 2.09242 12.5437 1.7769 10.6732C1.46138 8.80275 1.85438 6.88184 2.87912 5.28553C3.93327 3.67536 5.52665 2.49348 7.37344 1.95195C9.22023 1.41043 11.1995 1.54467 12.9563 2.33061C14.7019 3.08134 16.1081 4.45111 16.9045 6.17632C17.7009 7.90153 17.8311 9.86028 17.2701 11.6757C17.2285 11.8085 17.2235 11.9501 17.2556 12.0855C17.2877 12.2209 17.3557 12.3451 17.4525 12.4451C17.5507 12.5468 17.6735 12.6216 17.8089 12.6621C17.9443 12.7027 18.0879 12.7077 18.2258 12.6767C18.3628 12.6477 18.4897 12.5834 18.5939 12.4899C18.698 12.3965 18.7758 12.2773 18.8195 12.1443V12.1443C19.4914 9.98822 19.3543 7.66107 18.4338 5.59879C17.5133 3.53651 15.8725 1.88049 13.8188 0.94101C11.7506 -0.0386303 9.40425 -0.26184 7.18838 0.310186C4.97251 0.882213 3.02746 2.21331 1.69184 4.07165C0.389344 5.9103 -0.19177 8.16363 0.0590538 10.4029C0.309877 12.6421 1.37514 14.711 3.05216 16.2159C4.75383 17.75 6.93825 18.641 9.22745 18.7345C11.5166 18.8281 13.7665 18.1184 15.5878 16.7283L18.6318 19.6925C18.7847 19.8391 18.9883 19.921 19.2002 19.921C19.412 19.921 19.6156 19.8391 19.7685 19.6925C19.8421 19.6209 19.9009 19.5355 19.9413 19.4412C19.9818 19.3468 20.0033 19.2454 20.0044 19.1427C20.0055 19.04 19.9863 18.9381 19.9478 18.8428C19.9094 18.7476 19.8525 18.661 19.7805 18.5878L19.7685 18.5771L19.7552 18.5332Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_300_1646">
          <rect width="20" height="19.9215" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SearchOutlined