import { Row } from "antd";
import DashboardFavouriteCards from "./DashboardFavouriteCards";
import DashboardUtils from "./DashboardUtils";
import DashboardCharts from "./DashboardCharts";
import Button from "components/Button";
import styled from "styled-components";
import i18n from "i18n";
import DashboardSearchToolbar from "./DashboardSearchToolbar";
import DashboardListAndVideo from "./DashboardListAndVideo";

const DashboardWrapper = styled.div`
  // background-color: #f7f7f7;
`;

const HomeTrial = () => {
  return (
    <DashboardWrapper>
      <DashboardSearchToolbar />
      <DashboardFavouriteCards />
      {/* <DashboardUtils /> */}
      <DashboardCharts />
      <DashboardListAndVideo />
    </DashboardWrapper>
  );
};

export default HomeTrial;
