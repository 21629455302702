import { Layout, Menu, Row, Typography } from 'antd'
import styled from 'styled-components'
import type { MenuProps } from 'antd';
import {
  CampaignOutlined,
  CategoryOutlined,
  HomeOutlined,
  ProjectOutlined,
  FilesOutlined,
  SearchOutlined,
  SettingsOutlined,
  ArrowOutlined,
  ArrowOutlinedLeft,
  LogoutArrowOutlined
} from 'components/icons';
import Textfield from 'components/Textfield';
import IconButton from 'components/IconButton';
import Icon from '@ant-design/icons'
import type { CustomIconComponentProps, IconComponentProps } from '@ant-design/icons/lib/components/Icon'
import React, { useEffect } from 'react';
import LogoIcon from 'app/images/Logo_Icon.svg'
import { useTranslation } from "react-i18next";
import { Logout } from "features/user";
import { setCurrentProfile } from "features/user/setCurrentProfile";
import { useAppDispatch, useAppSelector } from "store";
import { triggerLoading } from "features/utils/loadingSlice";
import { triggerTabs } from "features/utils/tabsSlice";
import { triggerActiveTab } from "features/utils/activeTabSlice";
import defaultProfileImg from 'app/images/UserImg.png';
import { useNavigate } from 'react-router-dom';

const { Sider } = Layout
const { Title, Text } = Typography

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

type DynamicIconProps = Partial<CustomIconComponentProps> & {
  component: IconComponentProps['component']
}

const DynamicIcon = (props: DynamicIconProps) => (
  <Icon
    {...props}
  />
)

let items: MenuItem[] = []

const SideMenu = styled(Sider) <{ lang?: string, collapsed?: boolean }>`
  position: sticky!important;
  top: 0;
  bottom: 0;
  z-index: 99;
  height: 100vh;

  .ant-menu-item, .ant-menu-submenu-title {
    display: flex;
    align-items: center;
  }

  .ant-menu-item-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .ant-menu-item.ant-menu-item-only-child, .ant-menu-item.ant-menu-item-selected, .ant-menu-item {
    transition: unset!important;
  }

  .ant-menu-submenu-title:active {
    background-color: transparent!important;
  }

  .ant-menu-title-content {
    text-transform: capitalize;
    display: flex;
    align-items: center;
    width: 220px!important;
    flex: none!important;
    text-overflow: clip;
    white-space: pre-wrap;
    line-height: initial;
    height: 40px;
  }

  // .ant-menu-item-selected[role="menuitem"]::before {
  //   content: '';
  //   width: 7px;
  //   height: 50px;
  //   position: fixed;
  //   background: #FFFFFF;
  //  ${props => props.lang == 'ar' ? `right: 0; border-radius: 15px 0px 0px 15px;` : `left: 0; border-radius: 0px 15px 15px 0px;`}
  // ${props => props.collapsed && 'transform: translateY(1px);'}
  // }

  li.ant-menu-item.ant-menu-item-only-child {
    ${props => props.lang == 'en' ?
    `padding-left: 10px!important;`
    : null}
  }
`
const SearchInput = styled(Textfield)`
  background: rgba(255, 255, 255, 0.1);
  border: none!important;

  .ant-input {
    background: transparent;
  }

  .ant-input::placeholder {
    color:#fff;
  }
`
const SidebarLogoBox = styled(Row)`
  padding: 27px 0 4rem 0;
  overflow: hidden;
`
const SearchIcon = styled.div`
  width: 43px;
  height: 43px;
  border-radius: 15px;
  background: rgba(255,255,255,0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`
const SidebarSearchBox = styled(Row)`
  margin: 0 13.5px 35px;

  input {
    color: #fff;
  }
`
const SidebarUserBox = styled(Row)`
  & .ant-row div:first-child {
    background:#C6C7C8;
    padding: 2px;
    border-radius: 14px;

    img {
      border-radius: 14px;
      width: 45px;
      height: 45px;
      object-fit: cover;
      object-position: top;
    }
  }
  & .ant-row div:last-child {
    padding-inline-start: 8px;

    h5 {
      color: #fff;
      margin-bottom: 0px;
      fontSize: 13px;
      width: 145px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: capitalize;
    }

    span {
      display: block;
      color: ${props => props.theme.antd.colorPrimary};
      width: 145px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: capitalize;
    }
  }
`
const SidebarContentBox = styled.div<{ offset?: number, collapsed?: boolean }>``
const SettingDropDown = styled.div<{ showSetting: boolean, lang?: string }>`
  background: ${props => props.theme.antd.colorInfo};
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  width: 400px;
  height: 100vh;
  position: absolute;
  bottom: 0;
  z-index: 999;
  padding: .5rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // overflow-Y: scroll;
  ${props => props.showSetting ? 'display: flex;' : 'display: none;'}
  ${props => props.lang == 'ar' ?
    `right: 92%;
    border-radius: 15px 15px 0px 15px;`
    :
    `left: 92%;
    border-radius: 15px 15px 15px 0px;`
  }

  img {
    width: 45px!important;
    height: 45px!important;
  }
`
const MainBadge = styled.div<{ lang?: string }>`
  background: ${props => props.theme.antd.colorPrimary};
  width: 40px;
  height: 20px;
  border-radius: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  ${props => props.lang == 'ar' ?
    `left: 5px;`
    :
    `right: 5px;`
  }
  top: 50%;
  transform: translateY(-50%);
  fontSize: 12px;
`
const SettingUserList = styled(Row) <{ selected?: string }>`
  margin: 8px 0; 
  position: relative;
  padding: 5px; 
  flex-wrap: nowrap;
  align-items: center;
  background: ${props => props.selected == '1' && '#f5f5f5'};
  border-radius: 14px;

  &:hover {
    cursor: pointer;
    background: #f5f5f5;
    h5 {
      color: ${props => props.theme.antd.colorInfo}!important;
    }
  }

  ${props => props.selected == '1' && `
    background: #f5f5f5;
    h5 {
      color: ${props.theme.antd.colorInfo}!important;
    }
  `}
`
const UserListOverlay = styled.div<{ showSetting: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: transparent;
  ${props => props.showSetting ? 'display: block;' : 'display: none;'}
`

export default function MainSidebar() {
  const [collapsed, setCollapsed] = React.useState(false);
  const [showSetting, setShowSetting] = React.useState(false);
  const [openSubMenu, setOpenSubMenu] = React.useState<any>(null);
  const [searchVal, setSearchVal] = React.useState<any>(null);
  const [selectedKeys, setSelectedKeys] = React.useState(['1']);
  const [tabsArr, setTabsArr] = React.useState<any>(JSON.parse(localStorage.getItem('Tabs') || '[]'));
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(state => state.currentUser);
  const currentProfile = useAppSelector(state => state.currentProfile);
  const currentTabs = useAppSelector(state => state.tabs.Data);
  const currentActiveTab = useAppSelector(state => state.activeTab.Data)
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()

  const allowedScreens = currentUser?.Data?.Data?.User_Permissions
  const accountProfiles = currentUser?.Data?.Data?.Account_Profiles

  // Search
  useEffect(() => {
    let searchedItem: any = items?.filter((i: any) => i?.label.indexOf(searchVal) === 0)
    let indexOfSubMenu: any
    let isPage = !!searchedItem?.find((o: any) => o.key == '1' || o.key == '2')
    if (searchedItem?.length == 0 || !isPage) {
      searchedItem = items?.map((i: any) => i?.children?.filter((c: any) => c?.label.indexOf(searchVal) === 0))
      searchedItem = searchedItem?.find((a: any) => a?.length > 0)
      let searcheditemArr = items?.map((i: any) => i?.children?.filter((c: any) => c?.label.indexOf(searchVal) === 0))
      let lengths = searcheditemArr?.map((a: any, i: any) => a?.length)
      let firstArr = lengths?.filter((x: any) => x != undefined && x != 0)[0]
      indexOfSubMenu = lengths.indexOf(firstArr)
    }
    setSelectedKeys([searchedItem && searchedItem[0]?.key.toString()])
    if (indexOfSubMenu == 2) {
      setOpenSubMenu('sub1')
    } else if (indexOfSubMenu == 3) {
      setOpenSubMenu('sub2')
    } else if (indexOfSubMenu == 4) {
      setOpenSubMenu('sub3')
    } else if (indexOfSubMenu == 5) {
      setOpenSubMenu('sub4')
    } else {
      setOpenSubMenu(null)
    }
  }, [searchVal])

  useEffect(() => {
    accountProfiles.map((p: any) => {
      p.Selected_Profile == 1 && dispatch(setCurrentProfile({ userId: p?.User_Id, token: currentUser?.Data?.Data.access_token }))
    })
  }, [accountProfiles])

  useEffect(() => {
    dispatch(triggerTabs({ Data: tabsArr }))
  }, [tabsArr])

  useEffect(() => {
    setTabsArr(currentTabs)
  }, [currentTabs])

  useEffect(() => {
    document.querySelectorAll('.ant-menu-title-content').forEach((item: any) => {
      if (i18n.language == 'ar') {
        item.innerHTML = item.innerHTML.split('/')[0]
      } else if (i18n.language == 'en') {
        item.innerHTML = item.innerHTML.split('/').pop()
      }
    })
  }, [items])

  let allowedMainDataPages = allowedScreens?.map((item: any) => {
    if (item.User_Screen_Code == "departments" || item.User_Screen_Code === "municipality-management" ||
      item.User_Screen_Code === "municipality-sections" || item.User_Screen_Code === "municipality-centers" ||
      item.User_Screen_Code === "municipality-jobs" || item.User_Screen_Code === "all-tasks" ||
      item.User_Screen_Code === "assigned-tasks" || item.User_Screen_Code === "my-tasks" ||
      item.User_Screen_Code === "manage-suspensions" || item.User_Screen_Code === "suspensions") {
      // return i18n.language == 'ar' ? getItem(item.User_Screen_Name_AR, item.User_Screen_URL) : getItem(item.User_Screen_Name_EN, item.User_Screen_URL)
      return getItem(`${item.User_Screen_Name_AR}/${item.User_Screen_Name_EN}`, item.User_Screen_URL)
    }
  })
  let allowedManageServicesPages = allowedScreens?.map((item: any) => {
    if (item.User_Screen_Code == "holiday" || item.User_Screen_Code == "manage-system-modules" ||
      item.User_Screen_Code == "system-modules-apis" || item.User_Screen_Code == "manage-modules" ||
      item.User_Screen_Code == "services" || item.User_Screen_Code == "services/categories" ||
      item.User_Screen_Code == "services/drop-downs" || item.User_Screen_Code == "services/municipalities-services" ||
      item.User_Screen_Code == "invoices-list" || item.User_Screen_Code == "servicies-requests" ||
      item.User_Screen_Code == "manage-payments") {
      // return i18n.language == 'ar' ? getItem(item.User_Screen_Name_AR, item.User_Screen_URL) : getItem(item.User_Screen_Name_EN,)
      return getItem(`${item.User_Screen_Name_AR}/${item.User_Screen_Name_EN}`, item.User_Screen_URL)
    }
  })
  let allowedManagePortalPages = allowedScreens?.map((item: any) => {
    if (item.User_Screen_Code == "support-request" || item.User_Screen_Code == "accounts" ||
      item.User_Screen_Code == "slider" || item.User_Screen_Code == "suggestionsAndComplains" ||
      item.User_Screen_Code == "decision-categories" || item.User_Screen_Code == "decisions" ||
      item.User_Screen_Code == "government-entities" || item.User_Screen_Code == "faq-list" ||
      item.User_Screen_Code == "housing-departments") {
      // return i18n.language == 'ar' ? getItem(item.User_Screen_Name_AR, item.User_Screen_URL) : getItem(item.User_Screen_Name_EN, item.User_Screen_URL)
      return getItem(`${item.User_Screen_Name_AR}/${item.User_Screen_Name_EN}`, item.User_Screen_URL)
    }
  })
  let allowedManageSystemPages = allowedScreens?.map((item: any) => {
    if (item.User_Screen_Code == "customer-services" || item.User_Screen_Code == "users-areas" ||
      item.User_Screen_Code == "settings" || item.User_Screen_Code == "Users" ||
      item.User_Screen_Code == "Users_Groups" || item.User_Screen_Code == "manage-notifications" ||
      item.User_Screen_Code == "employee-transactions" || item.User_Screen_Code == "used-devices" ||
      item.User_Screen_Code == "types-of-documents" || item.User_Screen_Code == "types-of-stamps" ||
      item.User_Screen_Code == "users-dashboard-list" || item.User_Screen_Code == "Control-panel-card-settings") {
      // return i18n.language == 'ar' ? getItem(item.User_Screen_Name_AR, item.User_Screen_URL) : getItem(item.User_Screen_Name_EN, item.User_Screen_URL)
      return getItem(`${item.User_Screen_Name_AR}/${item.User_Screen_Name_EN}`, item.User_Screen_URL)
    }
  })

  items[0] = getItem(i18n.language == 'ar' ? 'لوحة التحكم' : 'Dashboard', '/dashboard', <DynamicIcon component={() => <CategoryOutlined />} />)
  items[1] = getItem(i18n.language == 'ar' ? 'ملف تنفيذ المشروع' : 'Project Execution File', '/project-execution-file')
  // <DynamicIcon component={() => <FilesOutlined />} />
  items[2] = getItem(i18n.language == 'ar' ? 'البيانات الرئيسية' : 'Main Data', 'sub1', <DynamicIcon component={() => <HomeOutlined />} />, allowedMainDataPages)
  items[3] = getItem(i18n.language == 'ar' ? 'إدارة الخدمات' : 'Manage Services', 'sub2', <DynamicIcon component={() => <CategoryOutlined />} />, allowedManageServicesPages)
  items[4] = getItem(i18n.language == 'ar' ? 'إدارة السلايدر' : 'Manage Portal', 'sub3', <DynamicIcon component={() => <ProjectOutlined />} />, allowedManagePortalPages)
  items[5] = getItem(i18n.language == 'ar' ? 'اداره النظام' : 'System Administration', 'sub4', <DynamicIcon component={() => <FilesOutlined />} />, allowedManageSystemPages)

  return (
    <SideMenu collapsed={collapsed} width={260} collapsedWidth={70} lang={i18n.language}>

      <SidebarContentBox collapsed={collapsed}>
        <SidebarLogoBox align="top" justify="center">
          {collapsed ? <img src={LogoIcon} width='50' height='50' /> : <img src='images/logo.svg' width='200' height='50' />}
        </SidebarLogoBox>
      </SidebarContentBox>

      <SidebarContentBox style={{ height: 'calc(100vh - 141px)' }}>
        <SidebarSearchBox>
          {collapsed ?
            <SearchIcon>
              <SearchOutlined />
            </SearchIcon>
            :
            <SearchInput
              prefixcolor='#fff'
              placeholder={t('searchFor') as string}
              value={searchVal}
              onChange={(e) => setSearchVal(e.target.value)}
              prefix={<SearchOutlined />}
              formfield={false}
            />
          }
        </SidebarSearchBox>

        <div
          id='menuContainer'
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'calc(100vh - 68px - 10rem)',
            overflowY: 'scroll'
          }}>
          <Menu
            theme="dark"
            defaultSelectedKeys={['1']}
            selectedKeys={[currentActiveTab]}
            mode="inline"
            items={items}
            openKeys={[openSubMenu]}
            forceSubMenuRender={true}
            onOpenChange={openKeys => setOpenSubMenu(openKeys.slice(-1).pop() == undefined ? null : openKeys.slice(-1).pop())}
            onClick={({ key }) => {
              dispatch(triggerActiveTab({ Data: key }))
              navigate(key)
            }}
            onSelect={({ item, key, keyPath, selectedKeys, domEvent }) => {
              if (!(tabsArr?.filter((a: any) => a.key === key).length > 0)) {
                items.find((L: any) => {
                  if (L.key == key) {
                    setTabsArr((oldArray: any) => [...oldArray, L])
                  }
                  L?.children?.find((t: any) => {
                    if (t?.key == key) {
                      setTabsArr((oldArray: any) => [...oldArray, t])
                    }
                  })
                })
              }
            }}
          />
          <SidebarUserBox align="middle" style={{ paddingTop: '2rem', justifyContent: collapsed ? 'space-around' : 'flex-end' }}>
            {collapsed ?
              <>
                <IconButton
                  icon={<LogoutArrowOutlined />}
                  btncolor="primary"
                  iconstyle='outline'
                  onClick={() => { dispatch(Logout()) }}
                />
              </>
              :
              <>
                <Row align="middle">
                  <div>
                    <img src={currentProfile?.Data?.Data?.User_Image ? currentProfile?.Data?.Data?.User_Image : defaultProfileImg} />
                  </div>
                  <div>
                    <Title level={5}>
                      {i18n.language == 'ar' ? currentProfile?.Data?.Data?.User_Name_AR : currentProfile?.Data?.Data?.User_Name_EN}
                    </Title>
                    <Text>{i18n.language == 'ar' ? currentProfile?.Data?.Data?.Department_Name_AR : currentProfile?.Data?.Data?.Department_Name_EN}</Text>
                  </div>
                </Row>

                <IconButton
                  icon={<SettingsOutlined />}
                  onClick={() => setShowSetting(!showSetting)}
                />

                <SettingDropDown showSetting={showSetting} lang={i18n.language}>
                  <div style={{ overflowY: 'scroll' }}>
                    {accountProfiles?.map((acc: any, i: any) => (
                      <SettingUserList
                        key={i}
                        selected={
                          (Object.keys(currentProfile?.Data).length !== 0 && acc?.User_Id == currentProfile?.Data?.Data?.User_Id) ? '1'
                            : Object.keys(currentProfile?.Data).length == 0 && acc?.Selected_Profile
                        }
                        onClick={() => {
                          setShowSetting(false)
                          dispatch(triggerLoading({ status: true }));
                          dispatch(setCurrentProfile({ userId: acc?.User_Id, token: currentUser?.Data?.Data.access_token }))
                        }}
                      >
                        <div>
                          <img src={acc?.Department_Logo_Url ? acc?.Department_Logo_Url : defaultProfileImg} />
                        </div>
                        <div>
                          <Title level={5} style={{ fontSize: '14px', width: '100%', whiteSpace: 'pre-wrap' }}>
                            {i18n.language == 'ar' ? acc?.Department_Job_Name_AR : acc?.Department_Job_Name_EN}
                            {
                              i18n.language == 'ar' && acc?.Department_Job_Name_AR && acc?.Department_Name_AR ? ' - '
                                : i18n.language == 'en' && acc?.Department_Job_Name_EN && acc?.Department_Name_EN ? ' - '
                                  : null
                            }
                            {i18n.language == 'ar' ? acc?.Department_Name_AR : acc?.Department_Name_EN}
                          </Title>
                          {acc?.Main_Profile ? <MainBadge lang={i18n.language}>Main</MainBadge> : null}
                          <Text style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {i18n.language == 'ar' ? acc?.User_Group_Name_AR : acc?.User_Group_Name_EN}
                          </Text>
                        </div>
                      </SettingUserList>
                    ))}
                  </div>

                  <div>
                    <div
                      style={{
                        height: '1px',
                        width: '100%',
                        background: '#16283A',
                        margin: '10px 0'
                      }}
                    ></div>

                    <div
                      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                      onClick={() => dispatch(Logout())}
                    >
                      <span style={{ transform: i18n.language == 'en' ? 'scaleX(-1)' : 'none' }}>
                        <IconButton
                          icon={<LogoutArrowOutlined />}
                          btncolor="primary"
                          onClick={() => { }}
                          iconstyle='outline'
                          width='40px'
                          height='40px'
                        />
                      </span>
                      <span
                        style={{
                          color: '#fff',
                          fontSize: '16px',
                          fontWeight: '700',
                          paddingInlineStart: '8px'
                        }}
                      >
                        {t('logout')}
                      </span>
                    </div>
                  </div>
                </SettingDropDown>
              </>
            }
          </SidebarUserBox>
        </div>
      </SidebarContentBox>

      <div
        style={{
          position: 'absolute',
          right: `${i18n.language == "en" ? '-20px' : 'unset'}`,
          left: `${i18n.language == "ar" ? '-20px' : 'unset'}`,
          top: '5.5rem'
        }}
      >
        <IconButton
          icon={
            collapsed && i18n.language == "ar" ? <ArrowOutlinedLeft />
              : !collapsed && i18n.language == "ar" ? <ArrowOutlined />
                : collapsed && i18n.language == "en" ? <ArrowOutlined />
                  : !collapsed && i18n.language == "en" ? <ArrowOutlinedLeft />
                    : null
          }
          btncolor="primary"
          onClick={() => setCollapsed(!collapsed)}
        />
      </div>

      <UserListOverlay
        showSetting={showSetting}
        onClick={() => {
          setShowSetting(false)
        }}
      />

    </SideMenu>
  )
}
