import { useState, useEffect, ReactNode } from 'react';
import Modal from './Modal'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { MaximizeOutlined, MinimizeOutlined, NewTabOutlined } from 'components/icons'
import { useTranslation } from 'react-i18next'
import { triggerModalTab } from 'features/utils/modalTabSlice'
import { useAppSelector, useAppDispatch } from 'store'
import { triggerLoading } from 'features/utils/loadingSlice'
import { triggerTabs } from 'features/utils/tabsSlice'
import { triggerActiveTab } from 'features/utils/activeTabSlice'
import { useNavigate } from 'react-router-dom'
import Button from 'components/Button'

const prefixCls = "ant-modal"

const ModalIcon = styled.span`
    cursor: pointer;
    padding: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    svg {
        width: 20px;
        height: 20px;
    }

    &:nth-child(2) svg {
        width: 24px;
        height: 24px;
    }

    path {
        fill: gray;    
    }

    &:hover {
        background: #d9d9d9;
    }

    &:last-child:hover {
        background: #ef4444;
    }

    &:last-child:hover path {
        fill: #fff;
    }
`
const ModalTitle = styled.span`
    cursor: default;
    color: gray;
`
const AntdModal = styled(Modal)`
    .${prefixCls}-body {
        height: 100%;
    }

    .${prefixCls}-footer {
        display: flex;
        align-items: baseline;
        justify-content: end;
    }
`

type CustomTabModalProps = {
    children?: ReactNode
    title?: string
    link?: string
    formData?: object
    onOk?: () => void
    bonusBtn?: {
        btncolor?: "success" | "primary" | "secondary" | "danger" | "info" | "white" | any,
        btnsize?: "small" | "default" | "large",
        icon?: ReactNode,
        title?: string,
        onClick?: () => void
    }
    showSave?: boolean
}

export default function TabModal({
    children,
    title,
    link,
    formData,
    onOk,
    bonusBtn,
    showSave = true
}: CustomTabModalProps) {

    const { t, i18n } = useTranslation()
    const [isOpen, setIsOpen] = useState<any>(false)
    const [screenMode, setScreenMode] = useState('min')
    const modalStatus = useAppSelector(state => state.modalTab)
    const currentTabs = useAppSelector(state => state.tabs.Data)
    const currentactiveTab = useAppSelector(state => state.activeTab.Data)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        setIsOpen(modalStatus?.Open)
    }, [modalStatus])

    const onCreateTab = () => {
        let existentTab = currentTabs.some((t: any) => t.key == link)
        if (!existentTab) {
            dispatch(triggerLoading({ status: true }))
            let currentTabIndex = currentTabs.findIndex((O: any) => O.key == currentactiveTab)
            let newTab = { key: link, label: title }
            let newTabs = currentTabs.slice(0)
            newTabs.splice(currentTabIndex + 1, 0, newTab)
            dispatch(triggerTabs({ Data: newTabs }))
            dispatch(triggerActiveTab({ Data: link }))
            navigate(link as string)
            dispatch(triggerModalTab({ Open: false, Data: formData }))
        }
    }

    const handleOnCancel = () => {
        setIsOpen(false)
        setScreenMode('min')
        dispatch(triggerModalTab({ Open: false, Data: {} }))
    }

    return (
        <>
            <AntdModal
                open={isOpen}
                fullScreen={screenMode == 'max' && true}
                closable={false}
                title={
                    <>
                        <ModalTitle>{title}</ModalTitle>
                        <div style={{ display: 'flex' }}>
                            {screenMode == 'min' ?
                                <Tooltip title={t('maximize')} trigger="hover" placement="bottom">
                                    <ModalIcon
                                        onClick={() => setScreenMode('max')}
                                    >
                                        <MaximizeOutlined />
                                    </ModalIcon>
                                </Tooltip>
                                :
                                <Tooltip title={t('minimize')} trigger="hover" placement="bottom">
                                    <ModalIcon
                                        onClick={() => setScreenMode('min')}
                                    >
                                        <MinimizeOutlined />
                                    </ModalIcon>
                                </Tooltip>
                            }

                            <Tooltip placement="bottom" title={currentTabs.some((t: any) => t.key == link) ? t('tabIsOpened') : t('newtab')}>
                                <ModalIcon
                                    onClick={() => onCreateTab()}
                                    style={{
                                        opacity: currentTabs.some((t: any) => t.key == link) ? '.5' : '1',
                                        cursor: currentTabs.some((t: any) => t.key == link) ? 'not-allowed' : 'pointer'
                                    }}
                                >
                                    <NewTabOutlined />
                                </ModalIcon>
                            </Tooltip>

                            <ModalIcon onClick={() => {
                                setIsOpen(false)
                                dispatch(triggerModalTab({ Open: false, Data: {} }))
                            }}>
                                <Tooltip title={t('close')}>
                                    <CloseOutlined />
                                </Tooltip>
                            </ModalIcon>
                        </div>
                    </>
                }
                width={'95%'}
                height={'95%'}
                footer={[
                    <Button
                        btncolor="white"
                        btnsize='small'
                        title={t('cancel') as string}
                        onClick={() => handleOnCancel()}
                    />,
                    modalStatus?.Type != 'view' && showSave &&
                    <Button
                        btncolor="primary"
                        btnsize='small'
                        title={t('save') as string}
                        onClick={onOk}
                    />,
                    bonusBtn?.title &&
                    <Button
                        btncolor={bonusBtn?.btncolor}
                        title={bonusBtn?.title}
                        btnsize={bonusBtn?.btnsize}
                        icon={bonusBtn?.icon}
                        onClick={bonusBtn?.onClick}
                    />
                ]}
            >
                {children}
            </AntdModal >
        </>
    )
}
