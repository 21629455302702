function CategoryOutlined() {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 11.75H4.25C2.125 11.75 0.5 10.125 0.5 8V4.25C0.5 2.125 2.125 0.5 4.25 0.5H8C10.125 0.5 11.75 2.125 11.75 4.25V8C11.75 10 10 11.75 8 11.75ZM4.25 2.375C3.25 2.375 2.375 3.25 2.375 4.25V8C2.375 9 3.25 9.875 4.25 9.875H8C9 9.875 9.875 9 9.875 8V4.25C9.875 3.25 9 2.375 8 2.375H4.25Z" fill="white" fillOpacity="0.5" />
            <path d="M6.125 25.5C3 25.5 0.5 23 0.5 19.875C0.5 16.75 3 14.25 6.125 14.25C9.25 14.25 11.75 16.75 11.75 19.875C11.75 23 9.125 25.5 6.125 25.5ZM6.125 16.125C4.125 16.125 2.375 17.75 2.375 19.875C2.375 22 4 23.625 6.125 23.625C8.25 23.625 9.875 22 9.875 19.875C9.875 17.75 8.125 16.125 6.125 16.125Z" fill="white" fillOpacity="0.5" />
            <path d="M19.875 11.75C16.75 11.75 14.25 9.25 14.25 6.125C14.25 3 16.75 0.5 19.875 0.5C23 0.5 25.5 3 25.5 6.125C25.5 9.25 23 11.75 19.875 11.75ZM19.875 2.375C17.875 2.375 16.125 4 16.125 6.125C16.125 8.25 17.75 9.875 19.875 9.875C22 9.875 23.625 8.25 23.625 6.125C23.625 4 22 2.375 19.875 2.375Z" fill="white" fillOpacity="0.5" />
            <path d="M21.75 25.5H18C15.875 25.5 14.25 23.875 14.25 21.75V18C14.25 15.875 15.875 14.25 18 14.25H21.75C23.875 14.25 25.5 15.875 25.5 18V21.75C25.5 23.875 23.875 25.5 21.75 25.5ZM18 16.125C17 16.125 16.125 17 16.125 18V21.75C16.125 22.75 17 23.625 18 23.625H21.75C22.75 23.625 23.625 22.75 23.625 21.75V18C23.625 17 22.75 16.125 21.75 16.125H18Z" fill="white" fillOpacity="0.5" />
        </svg>

    )
}

export default CategoryOutlined