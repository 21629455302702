import { theme } from 'antd'
import { ThemeProvider } from "styled-components"
import React from 'react'

export default function StyledThemeProvider({ children }: React.PropsWithChildren) {
  const { token } = theme.useToken()
  return (
    <ThemeProvider theme={{ antd: token }}>
      {children}
    </ThemeProvider>
  )
}
