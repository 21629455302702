import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitialState {
  Open: boolean;
  success?: null;
  error?: null;
  messages?: null;
}

const initialState: IInitialState = {
  Open: false,
  success: null,
  error: null,
  messages: null,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    triggerNotification: (state, action: PayloadAction<IInitialState>) => {
      state.Open = action.payload.Open;
      state.success = action.payload.success;
      state.error = action.payload.error;
      state.messages = action.payload.messages;
    },
  },
});

export default notificationSlice.reducer;
export const { triggerNotification } = notificationSlice.actions;
