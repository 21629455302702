import { Layout, Typography } from 'antd'
import styled from 'styled-components'
import { useTranslation } from "react-i18next";

const { Footer } = Layout
const { Title } = Typography

const CustomFooter = styled(Footer) <{ lang: string, login?: boolean }>`
  position: relative;
  ${props => props.login ?
    `margin-bottom: 10px;
    background: transparent;
    @media screen and ( max-height: 595px ) {
      margin-bottom: 0;
    }
    `
    :
    `border-radius: ${props.lang == 'ar' ? '0px 0px 65px 0px!important;' : '0px 0px 0px 65px!important;'}`}
  h5 {
    text-align: center;
    margin-bottom: 0;
    color: ${props => props.login ? '#fff;' : 'inherit;'}
    span {
      color: ${props => props.login ? 'inherit' : '#219EBC!important;'}
    }
  }

  div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${props => props.login ?
    `${props.lang == 'en' ? `right: 0px;` : `left: 0px;`}`
    :
    `${props.lang == 'en' ? `right: 10px;` : `left: 10px;`}`}

    img {
      width: 55px;
      height: 55px;
    }
}
`
type CustomFooterProps = {
  login?: boolean
}

export default function MainFooter({
  login = false
}: CustomFooterProps) {
  const { t, i18n } = useTranslation();

  return (
    <CustomFooter
      lang={i18n.language}
      login={login}
    >
      <Title level={5}>
        {t('footer_part2')} © <span>{t('footer_brandName')}</span>
      </Title>
      <div>
        <img src="images/slogan.svg" />
      </div>
    </CustomFooter>
  )
}
