import React from 'react'
import styled from 'styled-components'
import { Input } from 'antd';

const { TextArea } = Input;

const prefixCls = "ant-input"

const TextAreaWrapper = styled.div``

const Label = styled.div<{ formfield: boolean }>`
    font-family: 'Almarai';
    font-style: normal;
    font-weight: ${props => props.formfield ? '600' : '400'};
    fontSize: 16px;
    color: ${props => props.formfield ? props.theme.antd.colorTextLabel : '#000'};
    text-align: start;
    padding: 12px;
`
const AntdTextArea = styled(TextArea) <{ formfield: boolean; status: string, disabled: boolean }>`
    background: #F5F5F5;
    border-radius: 15px;
    border: none;
    fontSize: 16px;
    font-weight: 400;
    border: 1px solid #e3e0e0;
    ${props => props.status == 'error' && `
        border: 1px solid ${props.theme.antd.colorError};
    `}  
    ${props => props.formfield && `
        &:hover {
            border: 1px solid ${props.theme.antd.colorTextLabel}!important;
        }
    `}
    ${props => props.disabled && `
        color: #000000e0!important;
    `}
`

type CustomTextAreaProps = {
    rows?: number
    placeholder?: string
    label?: string
    defaultValue?: string
    value?: string
    disabled?: boolean
    onChange?: (event: any) => void
    status?: 'error' | ''
    formfield?: boolean
}

export default function Textarea({
    label,
    placeholder,
    rows = 6,
    defaultValue,
    value,
    disabled = false,
    onChange = () => { },
    status = '',
    formfield = true,
    ...rest
}: CustomTextAreaProps) {
    return (
        <TextAreaWrapper>
            <Label formfield={formfield}>{label}</Label>
            <AntdTextArea
                rows={rows}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
                disabled={disabled}
                status={status}
                formfield={formfield}
                {...rest}
            />
        </TextAreaWrapper>
    )
}