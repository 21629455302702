import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { triggerLoading } from "../utils/loadingSlice"
import { triggerNotification } from "../utils/notificationSlice"
import { useAppDispatch } from "store"
import { apiConfig } from "api/apiConfig"
import { persistor } from "store"

interface IFormValues {
  username: string;
  password: string;
}

interface IInitialState {
  Status: boolean;
  Data: any;
}

const initialState: IInitialState = {
  Status: false,
  Data: {},
}

export const userLogin = createAsyncThunk(
  "user/login",
  async ({ username, password }: IFormValues, { dispatch }) => {
    const data = await apiConfig.post("/API/SYSTEM/LOGIN/DIRECT_USERS_LOGIN", {
      Login_Name: username,
      Login_Password: password,
    })
    const finalPayload = data
    if (!finalPayload.data.Status) {
      dispatch(triggerNotification({ Open: true, error: finalPayload.data.Error_AR, messages: finalPayload.data.Error_EN }))
    } else {
      dispatch(triggerNotification({ Open: false }))
    }
    dispatch(triggerLoading({ status: false }))
    return finalPayload;
  }
)

const currentUserReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    Logout: (state) => {
      state.Status = false;
      state.Data = {};
      // persistor.pause();
      // persistor.flush().then(() => {
      //   return persistor.purge();
      // });
      // persistor.persist();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state) => { });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      if (!action.payload.data.Status) {
        const data = {
          ...action.payload.data
        }
        // Promise.reject(action.payload);
        state.Data = data;
        state.Status = data.Status;
      } else {
        const data = {
          ...action.payload.data,
          resetPass:
            action.payload.data.Data.Reset_Password === 0 ? false : true,
          verifyMail:
            action.payload.data.Data.User_Mail_Verified === 1 ? true : false,
          verifyPhone:
            action.payload.data.Data.User_Phone_Verified === 1 ? true : false,
        };
        state.Data = data;
        state.Status = data.Status;
      }
    });
    builder.addCase(userLogin.rejected, (state, action) => { });
  },
})

export const { Logout } = currentUserReducer.actions
export default currentUserReducer.reducer
