import { useEffect, useState } from 'react'
import TabModal from 'components/TabModal'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'
import { getMissionAttachmentList, getMissionAttachmentsResList } from 'api/tasks/tasks'
import { useAppSelector, useAppDispatch } from 'store'
import { triggerLoading } from 'features/utils/loadingSlice'
import Textfield from "components/Textfield"
import Button from 'components/Button'
import moment from 'moment'
import Textarea from "components/Textarea"
import FilesUploadedList from 'components/FilesUploadedList'

export default function TasksListForm(props: any) {

    const { t, i18n } = useTranslation()
    const [showTabForm, setShowTabForm] = useState<any>(false)
    const [formData, setFormData] = useState<any>()
    const currentactiveTab = useAppSelector(state => state.activeTab.Data)
    const [attachmentList, setAttachmentList] = useState<any>([])
    const modalStatus = useAppSelector(state => state.modalTab)
    const currentUser = useAppSelector((state: any) => state.currentUser)
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()

    useEffect(() => {
        setFormData(modalStatus?.Data)
        getAttachment()
    }, [])

    useEffect(() => {
        if (currentactiveTab == '/all-tasks-form') {
            setShowTabForm(true)
            setTimeout(() => dispatch(triggerLoading({ status: false })), 1000)
        }
    }, [currentactiveTab])

    const getAttachment = async () => {
        await getMissionAttachmentsResList(currentUser.Data.Data.access_token, modalStatus?.Data?.Mission_Id)
            .then(res => {
                let data = res?.data?.Data
                data?.map((file: any) => {
                    setAttachmentList((oldArray: any) => [...oldArray, file])
                })
            })
        await getMissionAttachmentList(currentUser.Data.Data.access_token, modalStatus?.Data?.Mission_Id)
            .then(res => {
                res?.map((file: any) => setAttachmentList((oldArray: any) => [...oldArray, file]))
            })
    }

    const handleOnView = async (data: any) => {
        window.open(data.Mission_Attach_File, "_blank", "noopener")
    }

    const myForm = (
        <Form
            form={form}
            style={{ width: '100%' }}
            initialValues={{
                "Mission_Date": moment(modalStatus?.Data?.Mission_Date).format('HH:mm YYYY-MM-DD'),
                "Mission_Type_Name_AR": modalStatus?.Data?.Mission_Type_Name_AR,
                "Mission_Type_Name_EN": modalStatus?.Data?.Mission_Type_Name_EN,
                "Mission_from_User_Name_AR": modalStatus?.Data?.Mission_from_User_Name_AR,
                "Mission_from_User_Name_EN": modalStatus?.Data?.Mission_from_User_Name_EN,
                "Mission_Title": modalStatus?.Data?.Mission_Title,
                "Mission_Description": modalStatus?.Data?.Mission_Description
            }}
        >
            <Row gutter={[12, 0]}>

                <Col xs={24} md={6}>
                    <Form.Item name="Mission_Date">
                        <Textfield
                            label={t('missionDate') as string}
                            disabled={modalStatus.Type == 'view'}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                    <Form.Item name={i18n.language == 'ar' ? "Mission_from_User_Name_AR" : "Mission_from_User_Name_EN"}>
                        <Textfield
                            label={t('missionFrom') as string}
                            disabled={modalStatus.Type == 'view'}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                    <Form.Item name={i18n.language == 'ar' ? "Mission_Type_Name_AR" : "Mission_Type_Name_EN"}>
                        <Textfield
                            label={t('missionType') as string}
                            disabled={modalStatus.Type == 'view'}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                    <Form.Item name="Mission_Title">
                        <Textfield
                            label={t('missionTitle') as string}
                            disabled={modalStatus.Type == 'view'}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                    <Form.Item name="Mission_Description">
                        <Textarea
                            label={t('missionDesc') as string}
                            disabled={modalStatus.Type == 'view'}
                        />
                    </Form.Item>
                </Col>

                {attachmentList.length > 0 &&
                    <Col xs={24} md={12}>
                        <FilesUploadedList
                            label={t('attachmentList') as string}
                            attachmentList={attachmentList}
                            fileName='Mission_Attach_File_Name'
                            canView={true}
                            onView={(data: any) => handleOnView(data)}
                        />
                    </Col>
                }
            </Row>

            {!modalStatus?.Open &&
                <Row style={{ justifyContent: 'end' }}>
                    <Col>
                        <Button title={t('save') as string} onClick={() => form.submit()} />
                    </Col>
                </Row>
            }

        </Form >
    )

    return (
        <>
            <TabModal
                children={myForm}
                title={'مدخلات التكليفات / Assigned TasksForm Form'}
                link={'/all-tasks-form'}
                formData={formData}
                onOk={() => { form.submit() }}
            />

            {showTabForm && myForm}
        </>
    )
}