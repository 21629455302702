import { useEffect, useState } from "react";
import Card from "components/Card";
import { Col, Row, Form } from "antd";
import Logo from "app/images/Logo.svg";
import Login_BG from "app/images/Login_BG.png";
import Login_Overlay from "app/images/Login_Overlay.png";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Textfield from "components/Textfield";
import MainFooter from "layout/footer";
import IconButton from "components/IconButton";
import Checkbox from "components/Checkbox";
import MainButton from "components/MainButton";
import { useAppDispatch, useAppSelector } from "store";
import { userLogin } from "features/user";
import { triggerLoading } from "features/utils/loadingSlice";
import { useNavigate } from 'react-router-dom';

const LoginWrapper = styled.div`
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: url(${Login_BG});
  background-size: 85% 100%;
  background-repeat: no-repeat;
  background-position: ${(props) => props.dir == "rtl" ? `top right` : `top left`};
  background-color: #031425;
  &:before {
    content: "";
    position: absolute;
    background: url(${Login_Overlay});
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @media screen and ( max-height: 595px ) {
    height: auto;
  }

`;

const InputSvg = styled.span<{
  showPassIcon?: boolean;
  showCheckIcon?: boolean;
  dir: string;
}>`
  position: absolute;
  z-index: 99;
  top: 8px;
  ${(props) => (props.dir == "rtl" ? `left: 12px` : `right: 12px`)};
  & path {
    fill: ${(props) => props.theme.antd.colorPrimary}!important;
  }
  display: ${(props) => (props.showCheckIcon ? "block" : "none")};
`;

export default function Login(props: any) {
  const { t, i18n } = useTranslation();
  const [showPassIcon, setShowPassIcon] = useState(false);
  const [showCheckIcon, setShowCheckIcon] = useState(false);
  const dispatch = useAppDispatch();
  const logedIn = useAppSelector(state => state.currentUser);
  const navigate = useNavigate()

  const handleLogin = async (values: any) => {
    dispatch(triggerLoading({ status: true }));
    dispatch(userLogin({ username: values.loginName, password: values.password })).finally(() => {
      navigate('/dashboard')
    })
  };

  return (
    <LoginWrapper dir={i18n.language === "en" ? "ltr" : "rtl"}>
      <Row>
        <Col span={24} style={{ display: "flex", justifyContent: "end" }}>
          <IconButton
            btncolor="primary"
            onClick={() =>
              i18n.changeLanguage(i18n.dir() === "rtl" ? "en" : "ar")
            }
            icon={<span style={{ color: "#fff" }}>{t('langBtn')}</span>}
          />
        </Col>
      </Row>

      <Row justify={"center"} align={"middle"}>
        <Col xs={24} md={10}>
          <Card radiussize="large" boxshadow={true}>
            <Form onFinish={handleLogin}>
              <Row justify={"center"}>
                <Col xs={15} md={10}>
                  <img src={Logo} />
                </Col>
              </Row>

              <Row justify={"center"} style={{ margin: "5px auto" }}>
                <Col xs={24} md={16}>
                  <Form.Item
                    name="loginName"
                    rules={[
                      { required: true, message: t('error.field_required') as string },
                    ]}
                  >
                    <Textfield
                      label={t('userName') as string}
                      style={{
                        border: "1px solid rgba(1, 18, 36, 0.1)",
                        background: "#F7F7F7",
                        direction: 'inherit'
                      }}
                      prefixcolor="transparent"
                      onChange={(e) =>
                        setShowCheckIcon(e.target.value == "" ? false : true)
                      }
                      formfield={false}
                      prefix={
                        <>
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="12.5"
                              cy="10"
                              r="5"
                              stroke="#022F45"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                            <path
                              d="M19.7444 25.5589C19.3171 23.9641 18.3755 22.5549 17.0657 21.5499C15.7559 20.5448 14.151 20 12.5 20C10.849 20 9.24412 20.5448 7.93429 21.5498C6.62446 22.5549 5.68287 23.9641 5.25556 25.5589"
                              stroke="#022F45"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                          <InputSvg
                            showCheckIcon={showCheckIcon}
                            dir={i18n.language === "en" ? "ltr" : "rtl"}
                          >
                            <svg
                              width="27"
                              height="27"
                              viewBox="0 0 27 27"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M13.5 26.625C20.7487 26.625 26.625 20.7487 26.625 13.5C26.625 6.25126 20.7487 0.375 13.5 0.375C6.25126 0.375 0.375 6.25126 0.375 13.5C0.375 20.7487 6.25126 26.625 13.5 26.625ZM12.8099 18.5152L20.1016 9.76518L18.5651 8.48482L11.9745 16.3936L8.37378 12.7929L6.95956 14.2071L11.3346 18.5821L12.1089 19.3564L12.8099 18.5152Z"
                                fill="#D0B166"
                              />
                            </svg>
                          </InputSvg>
                        </>
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify={"center"} style={{ margin: "5px auto" }}>
                <Col xs={24} md={16}>
                  <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: t('error.field_required') as string },
                    ]}
                  >
                    <Textfield
                      label={t('password') as string}
                      style={{
                        border: "1px solid rgba(1, 18, 36, 0.1)",
                        background: "#F7F7F7",
                      }}
                      prefixcolor="transparent"
                      onChange={(e) =>
                        setShowPassIcon(e.target.value == "" ? false : true)
                      }
                      typePassword={true}
                      visibilityToggle={showPassIcon ? true : false}
                      formfield={false}
                      prefix={
                        <>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4 13.0002C4 11.1146 4 10.1718 4.58579 9.58598C5.17157 9.0002 6.11438 9.0002 8 9.0002H16C17.8856 9.0002 18.8284 9.0002 19.4142 9.58598C20 10.1718 20 11.1146 20 13.0002V15.0002C20 17.8286 20 19.2428 19.1213 20.1215C18.2426 21.0002 16.8284 21.0002 14 21.0002H10C7.17157 21.0002 5.75736 21.0002 4.87868 20.1215C4 19.2428 4 17.8286 4 15.0002V13.0002Z"
                              stroke="#022F45"
                              strokeWidth="2"
                            />
                            <path
                              d="M18.4999 9.00001L18.5775 8.37942C18.8364 6.30783 17.9043 4.26745 16.1688 3.10705V3.10705C14.1023 1.72538 11.3673 1.89568 9.48819 3.523L8.66986 4.23169"
                              stroke="#022F45"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                            <circle cx="12" cy="15" r="2" fill="#022F45" />
                          </svg>
                        </>
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify={"center"} style={{ margin: "10px auto" }}>
                <Col xs={24} md={16}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Checkbox checked={true} title={t('rememberMe') as string} />
                    <div>{t('forgetPass')}</div>
                  </div>
                </Col>
              </Row>

              <Row justify={"center"} style={{ margin: "10px auto" }}>
                <Col xs={18} md={10}>
                  <MainButton
                    title={t('signin') as string}
                    btncolor="info"
                    width="100%"
                    height="60px"
                    htmlType="submit"
                  />
                </Col>
              </Row>
            </Form>

            <Row justify={"center"} style={{ marginTop: "10px" }}>
              <Col xs={24} md={14}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <span>{t('help')}</span>
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      background: "#D0B166",
                      borderRadius: "50%",
                      margin: "auto 10px",
                    }}
                  ></div>
                  <span>{t('terms_and_privacy')}</span>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <MainFooter login={true} />
    </LoginWrapper>
  );
}

