import { apiConfig } from '../apiConfig'

export const getTasksList = async (at: string) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/MISSIONS/GET_MISSION_LIST',
        {
            PageIndex: 1,
            PageCount: 1000
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );


    if (response.data.Status) {
        return response.data.Data.Data;
    }

    return [];
}

export const changeTasksStatus = async (at: string, departmentId: string, status: number) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS/SET_DEPARTMENT_STATUS',
        {
            Department_Id: departmentId,
            Active_Status_Id: status
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
    );

    return response.data;
}

export const getAllTasksList = async (at: string, data: object) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/MISSIONS/GET_MISSION_ALL_LIST',
        {
            PageIndex: 1,
            PageCount: 1000,
            ...data
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );


    if (response.data.Status) {
        return response.data.Data.Data;
    }

    return [];
}

export const getMyTasksList = async (at: string) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/MISSIONS/GET_MISSION_MY_LIST',
        {
            PageIndex: 1,
            PageCount: 1000,
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );


    if (response.data.Status) {
        return response.data.Data.Data;
    }

    return [];
}

export const getDepUsersList = async (at: string) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/MISSIONS/GET_DEPARTMENT_USERS', {},
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )
    if (response.data.Status) {
        return response.data
    }

    return []
}

export const addMission = async (
    at: string,
    missionToUserId: string,
    missionTitle: string,
    missionDescription: string,
) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/MISSIONS/ADD_MISSION',
        {
            Mission_to_User_Id: missionToUserId,
            Mission_Title: missionTitle,
            Mission_Description: missionDescription,

        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
    );

    return response.data;
}

export const updateMission = async (
    at: string,
    missionId: string,
    missionToUserId: string,
    missionTitle: string,
    missionDescription: string,
) => {
    const response = await apiConfig.post(
        'API/ADMINISTRATION/MISSIONS/UPD_MISSION',
        {
            Mission_Id: missionId,
            Mission_to_User_Id: missionToUserId,
            Mission_Title: missionTitle,
            Mission_Description: missionDescription,

        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
    );

    return response.data;
}

export const cancelMission = async (at: string, missionId: string) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/MISSIONS/CANCEL_MISSION', {
        Mission_Id: missionId,
    },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )

    return response
}

export const getMissionAttachmentList = async (at: string, MissionId: number) => {
    const response = await apiConfig.post(
        'API/ADMINISTRATION/MISSIONS/GET_MISSION_ATTACHMENT_LIST',
        {
            Mission_Id: MissionId,
        },
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );


    if (response.data.Status) {
        return response.data.Data;
    }

    return []
}

export const deleteMissionAttachment = async (at: string, MissionAttachId: string) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/MISSIONS/DEL_MISSION_ATTACHMENT',
        {
            Mission_Attach_Id: MissionAttachId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    ).catch((error) => {
        return error.response
    })

    return response
}

export const getMissionAttachmentsResList = async (at: string, MissionId: number) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/MISSIONS/GET_MISSION_RESPONSE_ATTACHMENT_LIST',
        {
            Mission_Id: MissionId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    ).catch((error) => {
        return error.response
    })

    return response
}

export const onCompleteTheMission = async (at: string, MissionId: number, MissionResponseDescription: string) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/MISSIONS/COMPELET_MISSION',
        {
            Mission_Id: MissionId,
            Mission_Response_Description: MissionResponseDescription
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    ).catch((error) => {
        return error.response
    })

    return response
}

export const onOpenTheMission = async (at: string, MissionId: number) => {
    const response = await apiConfig.post(
        'API/ADMINISTRATION/MISSIONS/OPEN_MISSION',
        {
            Mission_Id: MissionId,
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    ).catch((error) => {
        return error.response
    })

    return response
}