import HtmlEditor, { Toolbar } from 'devextreme-react/html-editor'
import styled from 'styled-components'

const HtmleditorLabel = styled.div`
    font-family: Almarai;
    font-style: normal;
    font-weight: bold;
    color: ${props => props.theme.antd.colorTextLabel};
    text-align: start;
    padding: 12px;
`
const Htmleditorwrapper = styled.div`
    cursor: not-allowed!important;
    .dx-state-disabled .dx-widget, .dx-state-disabled.dx-widget {
        opacity: 1!important;
    }
`

type CustomHtmlEditor = {
    height?: string
    initialValues?: string
    name?: string[]
    fontSize?: string[]
    headerSize?: any[]
    valueType?: any
    onValueChanged?: (e: any) => void
    label?: string
    children?: any
    disabled?: boolean
}

export default function Htmleditor({
    height = '300px',
    initialValues = '',
    valueType = 'html',
    label,
    onValueChanged = () => { },
    children,
    disabled = false
}: CustomHtmlEditor) {

    return (
        <Htmleditorwrapper>
            {label && <HtmleditorLabel>{label}</HtmleditorLabel>}
            <HtmlEditor
                height={height}
                defaultValue={initialValues}
                valueType={valueType}
                onValueChanged={onValueChanged}
                disabled={disabled}
                className=''
            >
                <Toolbar multiline={true}>
                    {children}
                </Toolbar>
            </HtmlEditor>
        </Htmleditorwrapper>
    )
}