import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'
import logo from './../assets/images/ShMunAnimatedGif.gif'

const SpinnerContainer = styled.div<{ isLoading: boolean }>`
    ${props => props.isLoading == true ? `
        position: fixed;
        width: 100%;
        height: 100%;
        right: 0;
        top: 0%;
        z-index: 9;
        // background: #fff3;
        // background: rgba(21, 23, 19, 0.9);
        // background: #f7f7f75c;
        background: #181515;
        z-index: 9999;
    ` : `
        display: none;
    `}
`
const LoadingSpinner = styled.div<{ spinnerColor: string, spinnerType: string }>`
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    // Spinner Type is Default
    ${props => props.spinnerType == 'default' ? `
        border-radius: 50%;
        width: 67px;
        height: 67px;
        left: 50%;
        animation: spinner 1.5s linear infinite;
        @keyframes spinner {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
        ${props.spinnerColor == 'primary' ? `
        border: 10px solid ${convertHexToRGBA(props.theme.antd.colorInfo)}; 
        border-top: 10px solid ${props.theme.antd.colorInfo};`
            : props.spinnerColor == 'success' ? `
        border: 10px solid ${convertHexToRGBA(props.theme.antd.colorSuccess)}; 
        border-top: 10px solid ${props.theme.antd.colorSuccess};`
                : props.spinnerColor == 'warning' ? `
        border: 10px solid ${convertHexToRGBA(props.theme.antd.colorPrimary)};
        border-top: 10px solid ${props.theme.antd.colorPrimary};`
                    : props.spinnerColor == 'error' ? `
        border: 10px solid ${convertHexToRGBA(props.theme.antd.colorError)};
        border-top: 10px solid ${props.theme.antd.colorError};`
                        : props.spinnerColor == 'default' ? `
        border: 10px solid ${convertHexToRGBA(props.theme.antd.colorSplit)};
        border-top: 10px solid ${props.theme.antd.colorSplit};`
                            : null}
    ` : `left: 50%;`}
`

const convertHexToRGBA = (hexCode: string, opacity = 0.3) => {
    let hex = hexCode.replace('#', '');
    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    if (opacity > 1 && opacity <= 100) {
        opacity = opacity / 100;
    }
    return `rgba(${r},${g},${b},${opacity})`;
}


const SharjahLogo = (
    <img src={logo} width='115' />
)

type CustomSpinnerProps = {
    isLoading?: boolean
    spinnerColor?: 'primary' | 'success' | 'warning' | 'error' | 'default'
    spinnerType?: 'logo' | 'default'
}

export default function Spinner({
    isLoading = false,
    spinnerColor = 'primary',
    spinnerType = 'logo'
}: CustomSpinnerProps) {

    return (
        <SpinnerContainer isLoading={isLoading}>
            <LoadingSpinner
                spinnerColor={spinnerColor}
                spinnerType={spinnerType}
            >
                {spinnerType == 'logo' && SharjahLogo}
            </LoadingSpinner>
        </SpinnerContainer>
    )

}