import React from 'react'

function MinimizeOutlined() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M4825 5105 c-22 -8 -42 -15 -45 -15 -2 0 -370 -366 -817 -812 l-813
                -813 0 472 0 471 -28 53 c-40 77 -102 114 -192 114 -90 0 -152 -37 -192 -114
                l-28 -53 0 -722 c0 -798 -1 -784 63 -868 20 -27 55 -54 92 -73 l59 -30 740 -3
                741 -3 55 28 c75 38 112 93 118 172 2 32 -1 76 -8 96 -17 51 -85 117 -136 133
                -30 9 -167 12 -505 12 l-464 0 807 808 c443 444 813 822 822 839 44 88 28 184
                -43 254 -66 67 -141 84 -226 54z"/>
                <path d="M675 2391 c-76 -34 -135 -123 -135 -201 0 -78 59 -167 135 -201 37
                -17 77 -19 510 -19 l470 0 -807 -807 c-443 -445 -813 -823 -822 -840 -44 -88
                -28 -184 43 -254 70 -71 166 -87 254 -43 17 9 395 379 840 822 l807 807 0
                -470 c0 -434 2 -473 19 -510 65 -141 234 -177 346 -74 80 73 76 31 73 860 l-3
                735 -30 59 c-19 37 -46 72 -73 92 -84 64 -70 63 -866 63 -676 0 -724 -2 -761
                -19z"/>
            </g>
        </svg>
    )
}

export default MinimizeOutlined