import { Col } from "antd";
import styled from "styled-components";
import { BottomMargin, RowWrapper } from "./DashboartStyledComponents";
import ListIcon from './../../assets/images/Icon.png'

const ItemContainer = styled.div`
  background-color: white;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: 0px 0px 30px rgba(1, 18, 36, 0.06);
`;

const Item = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    direction: ltr;
    margin: 15px 0;
`

const DashboardListAndVideo = () => {
    return (
        <BottomMargin>
            <RowWrapper gutter={[20, 20]} justify={"space-between"} align={"middle"}>
                <Col sm={24} md={12}>
                    <ItemContainer>
                        <p style={{ fontSize: '22px', fontWeight: '600', textAlign: 'end' }}>Video - Tutoria How you can use the  dashboard</p>
                        <iframe
                            width="100%"
                            height="250"
                            src="https://www.youtube.com/embed/chSpeRjXIZE"
                            style={{
                                border: '0px',
                                borderRadius: '12px'
                            }}
                        />
                    </ItemContainer>
                </Col>
                <Col sm={24} md={12}>
                    <ItemContainer>
                        <p style={{ fontSize: '22px', fontWeight: '600', textAlign: 'end', marginBottom: '5px' }}>Last Updates</p>
                        <p style={{ fontSize: '12px', fontWeight: '500', direction: 'ltr', color: 'rgba(181, 181, 195, 1)' }}>24 Update</p>

                        <Item>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <img src={ListIcon} style={{ marginRight: '8px' }} />
                                <div>
                                    <p style={{
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        marginBottom: '0px',
                                        fontFamily: 'sans-serif'
                                    }}>18.6 V</p>
                                    <p style={{
                                        fontSize: '13px',
                                        fontWeight: '500',
                                        color: 'rgba(181, 181, 195, 1)',
                                        marginBottom: '0px'
                                    }}>New enhancment in notification feature</p>
                                </div>
                            </div>
                            <div style={{
                                fontSize: '13px',
                                fontWeight: '600',
                                color: 'rgba(128, 128, 143, 1)',
                                background: 'rgba(243, 246, 249, 1)',
                                padding: '8px 10px',
                                borderRadius: '6px',
                                fontFamily: 'sans-serif'
                            }}>8 SEP 2021</div>
                        </Item>

                        <Item>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <img src={ListIcon} style={{ marginRight: '8px' }} />
                                <div>
                                    <p style={{
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        marginBottom: '0px',
                                        fontFamily: 'sans-serif'
                                    }}>18.7 V</p>
                                    <p style={{
                                        fontSize: '13px',
                                        fontWeight: '500',
                                        color: 'rgba(181, 181, 195, 1)',
                                        marginBottom: '0px'
                                    }}>New Enhancment in Messages</p>
                                </div>
                            </div>
                            <div style={{
                                fontSize: '13px',
                                fontWeight: '600',
                                color: 'rgba(128, 128, 143, 1)',
                                background: 'rgba(243, 246, 249, 1)',
                                padding: '8px 10px',
                                borderRadius: '6px',
                                fontFamily: 'sans-serif'
                            }}>8 SEP 2021</div>
                        </Item>

                        <Item>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <img src={ListIcon} style={{ marginRight: '8px' }} />
                                <div>
                                    <p style={{
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        marginBottom: '0px',
                                        fontFamily: 'sans-serif'
                                    }}>Top Authors</p>
                                    <p style={{
                                        fontSize: '13px',
                                        fontWeight: '500',
                                        color: 'rgba(181, 181, 195, 1)',
                                        marginBottom: '0px'
                                    }}>Most Successful Fellas</p>
                                </div>
                            </div>
                            <div style={{
                                fontSize: '13px',
                                fontWeight: '600',
                                color: 'rgba(128, 128, 143, 1)',
                                background: 'rgba(243, 246, 249, 1)',
                                padding: '8px 10px',
                                borderRadius: '6px',
                                fontFamily: 'sans-serif'
                            }}>8 SEP 2021</div>
                        </Item>

                        <Item>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <img src={ListIcon} style={{ marginRight: '8px' }} />
                                <div>
                                    <p style={{
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        marginBottom: '0px',
                                        fontFamily: 'sans-serif'
                                    }}>Top Authors</p>
                                    <p style={{
                                        fontSize: '13px',
                                        fontWeight: '500',
                                        color: 'rgba(181, 181, 195, 1)',
                                        marginBottom: '0px'
                                    }}>Most Successful Fellas</p>
                                </div>
                            </div>
                            <div style={{
                                fontSize: '13px',
                                fontWeight: '600',
                                color: 'rgba(128, 128, 143, 1)',
                                background: 'rgba(243, 246, 249, 1)',
                                padding: '8px 10px',
                                borderRadius: '6px',
                                fontFamily: 'sans-serif'
                            }}>8 SEP 2021</div>
                        </Item>
                    </ItemContainer>
                </Col>
            </RowWrapper>
        </BottomMargin >
    )
}

export default DashboardListAndVideo