function MaximizeOutlined() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="256.000000pt" height="256.000000pt" viewBox="0 0 256.000000 256.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M1780 2428 c-40 -22 -54 -60 -36 -103 8 -19 16 -36 18 -37 2 -1 88
-4 193 -7 l189 -6 -584 -558 c-322 -308 -600 -575 -617 -595 -21 -23 -33 -46
-33 -64 0 -39 40 -78 80 -78 35 0 -25 -55 830 763 l445 425 5 -195 c5 -178 7
-196 24 -209 34 -24 82 -21 111 7 19 18 20 33 20 237 l0 217 -28 57 c-30 60
-79 107 -144 135 -51 23 -434 31 -473 11z"/>
                <path d="M383 2420 c-94 -14 -184 -82 -226 -170 l-22 -45 0 -920 c0 -763 2
-927 14 -960 19 -55 101 -138 164 -166 l52 -24 915 0 915 0 53 24 c61 28 144
113 164 168 10 29 13 120 13 374 0 312 -1 338 -18 357 -10 11 -29 23 -43 26
-32 8 -82 -18 -89 -47 -3 -12 -6 -161 -7 -332 -3 -287 -4 -312 -23 -342 -10
-18 -35 -41 -55 -50 -33 -17 -94 -18 -910 -18 -837 0 -877 1 -913 19 -77 38
-72 -26 -72 971 0 858 1 891 19 918 41 60 50 62 407 65 309 3 328 4 348 23 32
30 29 93 -6 116 -24 16 -58 18 -328 20 -165 0 -323 -3 -352 -7z"/>
            </g>
        </svg>
    )
}

export default MaximizeOutlined