import { Checkbox as AntdCheckbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'


type CustomCheckboxProps = {
  title?: string
  className?: string
  checked?: boolean
  onChange?: (e: CheckboxChangeEvent) => void
}

export default function Checkbox({ title, ...rest }: CustomCheckboxProps) {
  return (
    <AntdCheckbox {...rest}>{title}</AntdCheckbox>
  )
}
