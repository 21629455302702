import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IModalTabInitialState {
    Open?: boolean;
    Data?: any;
    Type?: string;
}

const initialState: IModalTabInitialState = {
    Open: false,
    Data: {},
    Type: ''
}

const modalTabSlice = createSlice({
    name: "modalTab",
    initialState,
    reducers: {
        triggerModalTab: (state, action: PayloadAction<IModalTabInitialState>) => {
            state.Open = action.payload.Open;
            state.Data = action.payload.Data;
            state.Type = action.payload.Type;
        }
    }
})

export default modalTabSlice.reducer
export const { triggerModalTab } = modalTabSlice.actions