import React, { ReactNode, useRef } from 'react'
import styled from 'styled-components'
import { Input, InputRef } from 'antd'
import { useTranslation } from 'react-i18next'

const prefixCls = "ant-input"

const TextFieldWrapper = styled.div``

const Label = styled.div<{ formfield: boolean }>`
    font-family: 'Almarai';
    font-style: normal;
    font-weight: ${props => props.formfield ? '600' : '400'};
    fontSize: 16px;
    color: ${props => props.formfield ? props.theme.antd.colorTextLabel : '#000'};
    text-align: start;
    padding: 12px;
`
const AntdPasswordField = styled(Input.Password) <{ formfield: boolean, status: string, prefixcolor: string, placeholdercolor: string, disabled: boolean }>`
    display: flex;
    align-items: center;
    background: #F5F5F5;
    border-radius: 15px;
    border: none;
    height: 43px;
    ${props => props.status == 'error' && `
    border: 1px solid ${props.theme.antd.colorError};
    `}  
    ${props => props.formfield && `
        &:hover {
            border: 1px solid ${props.theme.antd.colorTextLabel}!important;
        }
    `}
    ${props => props.disabled && `
        .${prefixCls} {
            color: #000000e0!important;
        }
    `}
    .${prefixCls}::placeholder {
        color: ${props => props.placeholdercolor == 'light' ? '#B5B5C3' : '#707070'};
        fontSize: 16px;
        font-weight: 400;
        height: 18px;
    }
    .${prefixCls} {
        background: #F5F5F5;
        font-weight: 400;
    }
    .${prefixCls}-prefix {
        margin: 0 9px;
    }
    .${prefixCls}-prefix path {
        fill: ${props => props.prefixcolor ? props.prefixcolor : props.theme.antd.colorPrimary};
    }
`
const AntdTextField = styled(Input) <{ formfield: boolean; status: string, prefixcolor: string, placeholdercolor: string, lang: string, disabled: boolean }>`
    direction: ${props => props.lang == 'ar' ? 'ltr' : 'rtl'};
    display: flex;
    align-items: center;
    background: #F5F5F5;
    border-radius: 15px;
    border: none;
    height: 43px;
    border: 1px solid #e3e0e0;
    ${props => props.status == 'error' && `
        border: 1px solid ${props.theme.antd.colorError}!important;
    `}  

    ${props => props.formfield && `
        &:hover {
            border: 1px solid ${props.theme.antd.colorTextLabel}!important;
        }
    `}

    ${props => props.disabled && `
        .${prefixCls} {
            color: #000000e0!important;
        }
    `}

    .${prefixCls}::placeholder {
        color: ${props => props.placeholdercolor == 'light' ? '#B5B5C3' : '#707070'};
        fontSize: 16px;
        font-weight: 400;
        height: 18px;
    }
    .${prefixCls} {
        background: #F5F5F5;
        font-weight: 400;
        direction: ${props => props.lang == 'ar' ? 'rtl' : 'ltr'};
    }
    .${prefixCls}-prefix {
        margin: 0 9px;
    }
    .${prefixCls}-prefix path {
        fill: ${props => props.prefixcolor ? props.prefixcolor : props.theme.antd.colorPrimary};
    }
`

const EmailIcon = (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.4219 0.0625H2.57812C1.15655 0.0625 0 1.21905 0 2.64062V4.76736L9.46133 11.8048C9.92148 12.1471 10.4607 12.3182 11 12.3182C11.5393 12.3182 12.0785 12.1471 12.5387 11.8048L22 4.76736V2.64062C22 1.21905 20.8435 0.0625 19.4219 0.0625ZM20.2812 3.90373L11.5129 10.4257C11.2061 10.6539 10.7939 10.6539 10.4871 10.4257L1.71875 3.90373V2.64062C1.71875 2.16677 2.10427 1.78125 2.57812 1.78125H19.4219C19.8957 1.78125 20.2812 2.16677 20.2812 2.64062V3.90373ZM20.2812 8.18785L22 6.90944V15.3594C22 16.781 20.8435 17.9375 19.4219 17.9375H2.57812C1.15655 17.9375 0 16.781 0 15.3594V6.90944L1.71875 8.18785V15.3594C1.71875 15.8332 2.10427 16.2188 2.57812 16.2188H19.4219C19.8957 16.2188 20.2812 15.8332 20.2812 15.3594V8.18785Z" fill="#D0B166" />
    </svg>
)

const PaperIcon = (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.691 20.315H3.04395V21.9326H14.691V20.315Z" fill="#D0B166" />
        <path d="M10.9634 7.29294H6.88248C6.51697 7.29294 6.2207 7.65504 6.2207 8.10178C6.2207 8.54853 6.51697 8.91063 6.88248 8.91063H10.9634C11.3289 8.91063 11.6251 8.54853 11.6251 8.10178C11.6251 7.65504 11.3289 7.29294 10.9634 7.29294Z" fill="#D0B166" />
        <path d="M14.0516 11.0674H6.88248C6.51697 11.0674 6.2207 11.4295 6.2207 11.8763C6.2207 12.323 6.51697 12.6851 6.88248 12.6851H14.0516C14.4171 12.6851 14.7134 12.323 14.7134 11.8763C14.7134 11.4295 14.4171 11.0674 14.0516 11.0674Z" fill="#D0B166" />
        <path d="M17.61 0.165889C17.3724 0.0353926 17.0947 0.0869121 16.8997 0.297459L15.0466 2.30118L13.1912 0.273439C12.9399 -0.00130274 12.5601 -0.00130274 12.3088 0.273439L10.4559 2.29873L8.60273 0.273439C8.35147 -0.00130274 7.97186 -0.00130274 7.72059 0.273439L5.86522 2.30118L4.01207 0.297459C3.8173 0.0871699 3.53936 0.0356504 3.30177 0.165889C3.06443 0.296643 2.91178 0.585135 2.91178 0.903275V15.3003H0.661781C0.296262 15.3003 0 15.6624 0 16.1091V18.0773C0 20.2031 1.41507 21.9327 3.15439 21.9327V20.315C2.14478 20.315 1.32353 19.311 1.32353 18.0773V16.918H11.6912V18.0773C11.6912 20.2031 13.1062 21.9327 14.8456 21.9327C16.5849 21.9327 18 20.2032 18 18.0773V0.903232C18 0.585092 17.8474 0.296643 17.61 0.165889ZM16.6765 18.0773C16.6765 19.311 15.855 20.315 14.8456 20.315C13.8362 20.315 13.0147 19.311 13.0147 18.0773V16.1091C13.0147 15.6624 12.7185 15.3003 12.353 15.3003H4.23531V2.69855L5.42936 3.98972C5.68083 4.2615 6.0589 4.26012 6.30882 3.98676L8.16177 1.96147L10.0147 3.9865C10.266 4.26124 10.6458 4.26124 10.8971 3.9865L12.7502 1.96121L14.6032 3.9865C14.8533 4.25987 15.2314 4.26094 15.4826 3.98946L16.6765 2.6986V18.0773V18.0773Z" fill="#D0B166" />
    </svg>
)

const passwordToggle = (
    <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.1667 27C21.6225 27 27.6667 20.9558 27.6667 13.5C27.6667 6.04416 21.6225 0 14.1667 0C6.71083 0 0.666672 6.04416 0.666672 13.5C0.666672 20.9558 6.71083 27 14.1667 27ZM9.36224 11.5535C8.9263 11.2144 8.29802 11.293 7.95895 11.7289C7.61988 12.1649 7.69841 12.7931 8.13436 13.1322L8.59974 13.4942C8.70151 13.5733 8.80416 13.6497 8.90766 13.7234C8.90442 13.7326 8.90131 13.7418 8.89831 13.7511L8.34729 15.4654C8.17829 15.9912 8.46752 16.5545 8.99331 16.7235C9.5191 16.8925 10.0823 16.6032 10.2514 16.0774L10.6969 14.6912C11.1744 14.8737 11.6623 15.0031 12.1565 15.0776V17.4857C12.1565 18.038 12.6042 18.4857 13.1565 18.4857C13.7087 18.4857 14.1565 18.038 14.1565 17.4857V15.0776C14.6506 15.0031 15.1385 14.8737 15.616 14.6912L16.0616 16.0774C16.2306 16.6032 16.7938 16.8925 17.3196 16.7235C17.8454 16.5545 18.1346 15.9912 17.9656 15.4654L17.4146 13.7511C17.4116 13.7418 17.4085 13.7326 17.4053 13.7234C17.5088 13.6498 17.6114 13.5733 17.7132 13.4942L18.1786 13.1322C18.6145 12.7931 18.6931 12.1649 18.354 11.7289C18.0149 11.293 17.3866 11.2144 16.9507 11.5535L16.4853 11.9155C15.3979 12.7612 14.2587 13.1527 13.1565 13.1527C12.0542 13.1527 10.915 12.7612 9.82763 11.9155L9.36224 11.5535Z" fill="#D0B166" />
    </svg>
)

type CustomTextFieldProps = {
    placeholder?: string
    placeholdercolor?: 'light' | 'dark'
    label?: string
    defaultValue?: string
    value?: string
    disabled?: boolean
    size?: 'large'
    prefix?: ReactNode
    type?: 'text' | 'email'
    onChange?: (event: any) => void
    status?: 'error' | 'warning' | ''
    className?: string
    style?: object
    prefixcolor?: string
    typePassword?: boolean
    visibilityToggle?: boolean
    visible?: boolean
    iconRender?: ReactNode
    formfield?: boolean
}

export default function Textfield({
    label,
    placeholder,
    placeholdercolor = 'dark',
    defaultValue,
    value,
    disabled = false,
    size = 'large',
    type,
    prefix = PaperIcon,
    onChange = () => { },
    status = '',
    style,
    prefixcolor = '',
    typePassword = false,
    visibilityToggle = false,
    visible = false,
    iconRender,
    formfield = true,
    ...rest
}: CustomTextFieldProps) {
    const { t, i18n } = useTranslation()

    return (
        <TextFieldWrapper>
            {label && <Label formfield={formfield}>{label}</Label>}
            {typePassword ?
                <AntdPasswordField
                    placeholdercolor={placeholdercolor}
                    prefixcolor={prefixcolor}
                    style={style}
                    placeholder={placeholder}
                    value={value}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    size='large'
                    onChange={onChange}
                    prefix={type == 'text' ? PaperIcon : type == 'email' ? EmailIcon : prefix}
                    status={status}
                    visibilityToggle={visibilityToggle}
                    iconRender={(visible) => passwordToggle}
                    lang={i18n.language}
                    formfield={formfield}
                    {...rest}
                />
                :
                <AntdTextField
                    placeholdercolor={placeholdercolor}
                    prefixcolor={prefixcolor}
                    style={style}
                    placeholder={placeholder}
                    value={value}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    size='large'
                    onChange={onChange}
                    prefix={type == 'text' ? PaperIcon : type == 'email' ? EmailIcon : prefix}
                    status={status}
                    lang={i18n.language}
                    formfield={formfield}
                    {...rest}
                />
            }

        </TextFieldWrapper>
    )
}


