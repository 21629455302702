import Selectfield from "components/Selectfield";
import { Col } from "antd";
import { BottomMargin, RowWrapper } from "./DashboartStyledComponents";
import Datebox from "components/Datebox";
import { useTranslation } from "react-i18next";

const DashboardSearchToolbar = () => {
  const { t, i18n } = useTranslation()

  return (
    <BottomMargin>
      <RowWrapper align={"middle"} justify={"space-between"}>
        <Col sm={24} md={19}>
          <RowWrapper gutter={[10, 0]}>
            <Col sm={24} md={4}>
              <Selectfield
                placeholder={t('municipal') as string}
                allowClear={true}
                showSearch={true}
              />
            </Col>
            <Col sm={24} md={4}>
              <Selectfield
                placeholder={t('department') as string}
                allowClear={true}
                showSearch={true}
              />
            </Col>
            <Col sm={24} md={4}>
              <Selectfield
                placeholder={t('section') as string}
                allowClear={true}
                showSearch={true}
              />
            </Col>
            <Col sm={24} md={5}>
              <Datebox placeholder={t('fromDate') as string} variant="date" onChange={(d) => { }} />
            </Col>
            <Col sm={24} md={5}>
              <Datebox placeholder={t('toDate') as string} variant="date" onChange={(d) => { }} />
            </Col>
          </RowWrapper>
        </Col>

        <Col sm={24} md={5}>
          <RowWrapper gutter={[10, 0]}>
            <Col sm={24} md={4} style={{ margin: 'auto' }}>
              {
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.6112 6.83722H1.3888C0.623576 6.83722 0 7.2849 0 7.83427V9.82854C0 10.3779 0.623576 10.8256 1.3888 10.8256H23.6112C24.3764 10.8256 25 10.3779 25 9.82854V7.83427C25 7.2849 24.3764 6.83722 23.6112 6.83722V6.83722Z"
                    fill="#C6CACD"
                  />
                  <path
                    d="M23.6112 0H1.3888C0.623576 0 0 0.447676 0 0.997047V2.99132C0 3.5407 0.623576 3.98837 1.3888 3.98837H23.6112C24.3764 3.98837 25 3.5407 25 2.99132V0.997047C25 0.447676 24.3764 0 23.6112 0V0Z"
                    fill="#C6CACD"
                  />
                  <path
                    d="M23.6112 13.6744H1.3888C0.623576 13.6744 0 14.1221 0 14.6715V16.6658C0 17.2151 0.623576 17.6628 1.3888 17.6628H23.6112C24.3764 17.6628 25 17.2151 25 16.6658V14.6715C25 14.1221 24.3764 13.6744 23.6112 13.6744V13.6744Z"
                    fill="#C6CACD"
                  />
                  <path
                    d="M23.6112 20.5116H1.3888C0.623576 20.5116 0 20.9593 0 21.5086V23.5029C0 24.0523 0.623576 24.5 1.3888 24.5H23.6112C24.3764 24.5 25 24.0523 25 23.5029V21.5086C25 20.9593 24.3764 20.5116 23.6112 20.5116V20.5116Z"
                    fill="#C6CACD"
                  />
                </svg>
              }
            </Col>
            <Col sm={24} md={5} style={{ margin: 'auto', textAlign: 'center' }}>
              {
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_817_814)">
                    <path
                      d="M5.20833 0H1.04167C0.466667 0 0 0.466667 0 1.04167V5.20833C0 5.78333 0.466667 6.25 1.04167 6.25H5.20833C5.78333 6.25 6.25 5.78333 6.25 5.20833V1.04167C6.25 0.466667 5.78333 0 5.20833 0Z"
                      fill="#C6CACD"
                    />
                    <path
                      d="M5.20833 9.375H1.04167C0.466667 9.375 0 9.84167 0 10.4167V14.5833C0 15.1583 0.466667 15.625 1.04167 15.625H5.20833C5.78333 15.625 6.25 15.1583 6.25 14.5833V10.4167C6.25 9.84167 5.78333 9.375 5.20833 9.375Z"
                      fill="#C6CACD"
                    />
                    <path
                      d="M5.20833 18.75H1.04167C0.466667 18.75 0 19.2167 0 19.7917V23.9583C0 24.5333 0.466667 25 1.04167 25H5.20833C5.78333 25 6.25 24.5333 6.25 23.9583V19.7917C6.25 19.2167 5.78333 18.75 5.20833 18.75Z"
                      fill="#C6CACD"
                    />
                    <path
                      d="M14.5833 0H10.4167C9.84167 0 9.375 0.466667 9.375 1.04167V5.20833C9.375 5.78333 9.84167 6.25 10.4167 6.25H14.5833C15.1583 6.25 15.625 5.78333 15.625 5.20833V1.04167C15.625 0.466667 15.1583 0 14.5833 0Z"
                      fill="#C6CACD"
                    />
                    <path
                      d="M14.5833 9.375H10.4167C9.84167 9.375 9.375 9.84167 9.375 10.4167V14.5833C9.375 15.1583 9.84167 15.625 10.4167 15.625H14.5833C15.1583 15.625 15.625 15.1583 15.625 14.5833V10.4167C15.625 9.84167 15.1583 9.375 14.5833 9.375Z"
                      fill="#D0B166"
                    />
                    <path
                      d="M14.5833 18.75H10.4167C9.84167 18.75 9.375 19.2167 9.375 19.7917V23.9583C9.375 24.5333 9.84167 25 10.4167 25H14.5833C15.1583 25 15.625 24.5333 15.625 23.9583V19.7917C15.625 19.2167 15.1583 18.75 14.5833 18.75Z"
                      fill="#C6CACD"
                    />
                    <path
                      d="M23.9583 0H19.7917C19.2167 0 18.75 0.466667 18.75 1.04167V5.20833C18.75 5.78333 19.2167 6.25 19.7917 6.25H23.9583C24.5333 6.25 25 5.78333 25 5.20833V1.04167C25 0.466667 24.5333 0 23.9583 0Z"
                      fill="#C6CACD"
                    />
                    <path
                      d="M23.9583 9.375H19.7917C19.2167 9.375 18.75 9.84167 18.75 10.4167V14.5833C18.75 15.1583 19.2167 15.625 19.7917 15.625H23.9583C24.5333 15.625 25 15.1583 25 14.5833V10.4167C25 9.84167 24.5333 9.375 23.9583 9.375Z"
                      fill="#C6CACD"
                    />
                    <path
                      d="M23.9583 18.75H19.7917C19.2167 18.75 18.75 19.2167 18.75 19.7917V23.9583C18.75 24.5333 19.2167 25 19.7917 25H23.9583C24.5333 25 25 24.5333 25 23.9583V19.7917C25 19.2167 24.5333 18.75 23.9583 18.75Z"
                      fill="#C6CACD"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_817_814">
                      <rect width="25" height="25" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              }
            </Col>
            <Col sm={24} md={9} style={{ textAlign: 'center' }}>
              <div>{t('lastUpdated')}</div>
              <div>10:32</div>
            </Col>
            <Col sm={24} md={6}>
              <svg
                width="45"
                height="45"
                viewBox="0 0 45 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.4986 44.9753C10.079 44.9753 0.0109444 34.9073 0.0109444 22.4877C0.0109444 10.0681 10.079 0 22.4986 0C34.9182 0 44.9863 10.0681 44.9863 22.4877C44.9863 34.9073 34.9182 44.9753 22.4986 44.9753Z"
                  fill="url(#paint0_linear_215_74)"
                />
                <path
                  d="M31.7138 1.96765C43.0428 7.05694 48.101 20.3667 43.0117 31.6955C37.9224 43.0244 24.6128 48.0827 13.2838 42.9933C1.9548 37.9039 -3.10339 24.5944 1.98603 13.2654C4.19854 8.34038 8.10902 4.37579 13.0033 2.09593C15.039 2.6489 17.0319 3.34878 18.9663 4.19005C24.1122 6.40976 28.2358 8.1085 30.5849 8.1085C31.5027 8.21236 32.3885 7.73433 32.8055 6.91015C33.3882 5.67658 33.2397 4.22155 32.4195 3.1313L31.7138 1.96765Z"
                  fill="url(#paint1_linear_215_74)"
                />
                <path
                  d="M0.11771 20.2933C0.433139 17.0255 1.46452 13.8675 3.13859 11.0434C5.22307 7.52635 8.22235 4.64057 11.8169 2.69298C14.1467 3.25957 16.4231 4.02745 18.6203 4.98762C27.7588 8.92856 31.9134 10.4006 33.5662 7.32069C34.2935 5.81771 34.1369 4.0367 33.1586 2.68359C44.0942 8.57097 48.1864 22.2086 42.299 33.144C36.4117 44.0794 22.774 48.1718 11.8385 42.2844C4.55493 38.3631 0.0123101 30.7597 0.0108961 22.4877C0.0108961 21.7474 0.0465001 21.0159 0.11771 20.2933V20.2933Z"
                  fill="url(#paint2_linear_215_74)"
                />
                <path
                  d="M8.29012 5.05975C11.5358 6.57109 15.428 9.38296 21.9064 14.8813C23.9004 16.6204 26.1534 18.0379 28.5843 19.0827C30.3989 19.8639 32.349 20.2817 34.3242 20.313C36.509 20.3918 38.6444 19.6515 40.3116 18.2376C41.5429 17.1057 42.0787 15.3329 42.0057 13.0018C41.9813 12.2288 41.9392 11.5964 41.8943 11.1016C48.1824 21.8115 44.5978 35.5913 33.8879 41.8794C23.1779 48.1676 9.39798 44.5831 3.10982 33.8731C-2.5075 24.3057 -0.307844 12.0713 8.29012 5.05975V5.05975Z"
                  fill="url(#paint3_linear_215_74)"
                />
                <path
                  d="M0.117724 20.2933C0.433153 17.0255 1.46454 13.8675 3.1386 11.0434C4.32705 9.03834 5.81859 7.22918 7.56027 5.68005C10.9466 7.17918 15.0514 10.2029 21.3443 15.5437C29.7416 22.6695 37.3433 22.1476 40.9002 18.8766C42.5165 17.3905 42.9755 15.2082 42.8735 12.9623C48.1293 24.2149 43.268 37.5977 32.0152 42.8536C20.7624 48.1094 7.3798 43.248 2.12393 31.9953C0.733544 29.0185 0.0123252 25.7732 0.0110399 22.4877C0.0109113 21.7474 0.0465143 21.0159 0.117724 20.2933V20.2933Z"
                  fill="url(#paint4_linear_215_74)"
                />
                <path
                  d="M22.4986 44.9753C10.079 44.9763 0.0101814 34.9092 0.00915315 22.4896C0.00876754 18.0296 1.33463 13.6704 3.81796 9.96585C4.25781 9.88217 4.70448 9.84014 5.15218 9.84027H5.30577C7.25195 9.8796 9.09864 10.8765 11.295 13.0728C13.9373 15.9225 16.3792 18.9515 18.6034 22.1382C20.6734 25.0516 23.1895 27.6208 26.059 29.7512C28.5269 31.6603 31.531 32.7482 34.6492 32.862C36.299 32.854 37.9281 32.4944 39.4278 31.807C40.9826 31.1339 42.3148 30.0339 43.2694 28.6343C43.8018 27.8511 44.2751 27.0293 44.6852 26.1756C42.8793 37.023 33.4953 44.9744 22.4986 44.9753V44.9753Z"
                  fill="url(#paint5_linear_215_74)"
                />
                <path
                  d="M0.117735 20.2933C0.433165 17.0255 1.46455 13.8674 3.13861 11.0434C3.83078 10.8025 4.561 10.6894 5.29366 10.7098C8.80362 10.7801 11.9604 14.5992 17.9045 22.6545C23.5414 30.2993 31.5106 36.3213 39.7887 32.5987C41.4921 31.8618 42.9501 30.6546 43.992 29.1187C40.3401 40.9892 27.7566 47.6518 15.8861 43.9998C6.44342 41.0949 0.0026944 32.3671 0.0107922 22.4876C0.0109208 21.7474 0.0465256 21.0159 0.117735 20.2933Z"
                  fill="url(#paint6_linear_215_74)"
                />
                <path
                  d="M11.0224 24.2172C12.0074 23.8997 13.0634 24.4409 13.3808 25.426C15.0023 30.4593 20.3972 33.2253 25.4307 31.6038C27.3408 30.9884 29.0095 29.7884 30.2007 28.1732L29.6994 28.3681C28.7416 28.7602 27.6473 28.3019 27.255 27.344C26.8628 26.3863 27.3212 25.2919 28.279 24.8996C28.3001 24.891 28.3213 24.8828 28.3427 24.8749L33.0389 23.0497C34.0032 22.6752 35.0887 23.1528 35.4639 24.117L37.2854 28.8019C37.6721 29.7618 37.2075 30.8536 36.2476 31.2403C35.2876 31.6271 34.1958 31.1624 33.8091 30.2025C33.8033 30.1883 33.7978 30.174 33.7924 30.1596L33.6397 29.7679C29.622 35.9268 21.3722 37.6624 15.2135 33.6448C12.6551 31.9758 10.7484 29.4778 9.81317 26.57C9.49903 25.5865 10.0399 24.5344 11.0224 24.2172Z"
                  fill="white"
                />
                <path
                  d="M8.78022 13.7446C9.74476 13.3701 10.8304 13.8483 11.2052 14.8127L11.3579 15.2044C15.3756 9.0455 23.6253 7.30987 29.7841 11.3275C32.3424 12.9965 34.2492 15.4945 35.1844 18.4023C35.5016 19.3876 34.96 20.4435 33.9747 20.7607C32.9895 21.078 31.9336 20.5364 31.6163 19.5511C29.9949 14.5177 24.6 11.7517 19.5665 13.3732C17.6563 13.9886 15.9877 15.1887 14.7965 16.8037L15.2978 16.6089C16.2555 16.2167 17.3499 16.6751 17.7422 17.6329C18.1345 18.5908 17.676 19.685 16.7181 20.0773C16.6971 20.0859 16.6759 20.0942 16.6545 20.102L11.9583 21.9254C10.994 22.3 9.90852 21.8223 9.53332 20.8582L7.71183 16.1733C7.33547 15.2091 7.81195 14.1225 8.77598 13.7461C8.77752 13.7456 8.77894 13.7451 8.78022 13.7446V13.7446Z"
                  fill="white"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_215_74"
                    x1="-1.41517"
                    y1="22.4877"
                    x2="45.1971"
                    y2="22.4877"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FAAD37" />
                    <stop offset="1" stopColor="#F78B1F" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_215_74"
                    x1="22.1608"
                    y1="22.6478"
                    x2="39.7902"
                    y2="16.2071"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F4841A" />
                    <stop offset="1" stopColor="#F26F11" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_215_74"
                    x1="44.5992"
                    y1="23.8297"
                    x2="0.143034"
                    y2="23.8297"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F4841A" />
                    <stop offset="1" stopColor="#F26F11" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_215_74"
                    x1="17.5012"
                    y1="24.8223"
                    x2="40.1527"
                    y2="20.783"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F16430" />
                    <stop offset="1" stopColor="#E73D14" />
                  </linearGradient>
                  <linearGradient
                    id="paint4_linear_215_74"
                    x1="46.2136"
                    y1="25.3259"
                    x2="0.600252"
                    y2="25.3259"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F16430" />
                    <stop offset="1" stopColor="#E73D14" />
                  </linearGradient>
                  <linearGradient
                    id="paint5_linear_215_74"
                    x1="16.5367"
                    y1="27.7511"
                    x2="42.5726"
                    y2="30.5892"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.003" stopColor="#CE3814" />
                    <stop offset="0.999" stopColor="#D63914" />
                  </linearGradient>
                  <linearGradient
                    id="paint6_linear_215_74"
                    x1="45.1924"
                    y1="27.8537"
                    x2="0.58715"
                    y2="27.8537"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.003" stopColor="#DD3814" />
                    <stop offset="0.999" stopColor="#D63914" />
                  </linearGradient>
                </defs>
              </svg>
            </Col>
          </RowWrapper>
        </Col>
      </RowWrapper>
    </BottomMargin>
  );
};

export default DashboardSearchToolbar;
