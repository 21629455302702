import React from 'react'

function ArrowOutlined() {
  return (
    <svg width="31" height="24" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.6791 20.7054C13.5029 20.5369 13.3962 20.31 13.3795 20.0686C13.3629 19.8272 13.4377 19.5882 13.5891 19.3978L13.6791 19.2955L20.2414 12.9943L2.08084 12.9943C1.81775 13.0043 1.56104 12.9125 1.36568 12.7385C1.17031 12.5645 1.05173 12.322 1.0352 12.0629C1.01866 11.8039 1.10549 11.5487 1.27719 11.3519C1.44889 11.1551 1.69188 11.0323 1.95417 11.0097H2.08084L22.7364 11.0097C22.939 11.0087 23.1376 11.0666 23.3071 11.176C23.4767 11.2854 23.6099 11.4416 23.6902 11.6251C23.7579 11.7854 23.7812 11.9606 23.7576 12.1327C23.734 12.3049 23.6645 12.4677 23.556 12.6046L23.4644 12.7039L15.1335 20.7083C14.9401 20.893 14.6822 20.9974 14.4129 21C14.1436 21.0025 13.8836 20.903 13.6867 20.7219L13.673 20.7083L13.6791 20.7054ZM18.3826 9.20722L18.2803 9.11998L13.6791 4.70244C13.5835 4.61099 13.5075 4.50162 13.4556 4.38066C13.4036 4.2597 13.3769 4.1297 13.3769 3.99836C13.3769 3.86702 13.4036 3.73701 13.4556 3.61606C13.5075 3.4951 13.5835 3.38563 13.6791 3.29418C13.8574 3.12269 14.0917 3.01924 14.3402 3.00243C14.5888 2.98561 14.8354 3.05648 15.0359 3.20235L15.1426 3.29262L19.7376 7.70566C19.8333 7.79723 19.9094 7.90682 19.9614 8.0279C20.0133 8.14898 20.04 8.2791 20.04 8.41057C20.04 8.54203 20.0133 8.67215 19.9614 8.79323C19.9094 8.91432 19.8333 9.0239 19.7376 9.11548C19.5597 9.28708 19.3257 9.39069 19.0774 9.40778C18.8291 9.42487 18.5829 9.35425 18.3826 9.20869V9.20722Z" fill="white"/>
    </svg>
  )
}

export default ArrowOutlined