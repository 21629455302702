import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import {
    getDepartmentJobSectionsList,
    deleteDepartmentJobSection,
    addDepartmentJobSection
} from 'api/departments/departments'
import GridExpress from 'components/GridExpress/GridExpress'
import Switch from 'components/Switch'
import CustomStore from 'devextreme/data/custom_store'
import { triggerLoading } from 'features/utils/loadingSlice'
import { useAppSelector, useAppDispatch } from 'store'

export default function JobSectionsList(props: any) {

    const { t, i18n } = useTranslation()
    const currentUser = useAppSelector(state => state.currentUser)
    const dispatch = useAppDispatch()

    const customizeCellCheckbox = (data: any) => {
        return (
            <Switch
                disabled={props.modalType == 'view'}
                checked={data.data.Selected}
                onChange={async () => {
                    let at = currentUser.Data.Data.access_token,
                        status = data.data.Selected,
                        departmentJobId = data.data.Department_Job_Id,
                        jobSectionId = data.data.Department_Job_Section_Id,
                        departmentSectionId = data.data.Department_Section_Id,
                        response
                    dispatch(triggerLoading({ status: true }))
                    if (status) {
                        response = await deleteDepartmentJobSection(at, jobSectionId)
                            .then(() => {
                                dispatch(triggerLoading({ status: false }))
                            })
                    } else {
                        response = await addDepartmentJobSection(at, departmentJobId, departmentSectionId)
                            .then(() => {
                                dispatch(triggerLoading({ status: false }))
                            })
                    }
                }}
            />
        )
    }

    const handleExportCustomization = (options: any) => {
        if (options.gridCell.rowType === 'data' && options.gridCell.column.dataField == "Selected") {
            if (options.gridCell.value == 1) {
                options.excelCell.value = "true"
            } else {
                options.excelCell.value = "false"
            }
        }
    }

    const columnsList = () => {
        let SectionNameKey = "Department_Section_Name_EN"

        if (i18n.language == 'ar') {
            SectionNameKey = "Department_Section_Name_AR"
        }

        return [
            {
                dataField: "Selected",
                caption: t('status'),
                cellRender: customizeCellCheckbox,
                width: '100px'
            },
            {
                dataField: SectionNameKey,
                caption: t('sectionName'),
            },
        ]
    }

    const jsonDataSource = new CustomStore({
        key: "Department_Job_Section_Id",
        loadMode: 'raw',
        load: () => {
            return getDepartmentJobSectionsList(currentUser.Data.Data.access_token, props.departmentJobId)
        }
    })

    return (
        <>
            <Row>
                <Col>
                    <GridExpress
                        allowSelectAll={false}
                        allowView={false}
                        allowEdit={false}
                        allowDelete={false}
                        columnsList={columnsList()}
                        dataSource={jsonDataSource}
                        groupPanelVisibility={false}
                        gridTitle={''}
                        xlsxFileName={t('jobGroupWorkersName') as string}
                        handleWordCustomization={(options: any) => handleExportCustomization(options)}
                    >
                    </GridExpress>
                </Col>
            </Row>
        </>
    )
}