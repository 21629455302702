import React, { createRef, FC, LegacyRef, ComponentType, ReactNode } from "react";
import {
  DataGrid,
  Button,
  GroupPanel,
  Paging,
  Pager,
  FilterRow,
  Grouping,
  SearchPanel,
  HeaderFilter,
  ColumnChooser,
  Scrolling,
  Sorting,
  Selection,
  RowDragging,
  FilterPanel,
  ColumnFixing,
  LoadPanel,
  MasterDetail,
  Column,
  Export,
  KeyboardNavigation,
  StateStoring,
  IDataGridOptions,
} from "devextreme-react/data-grid";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import "./GridExpress.css";
import { useTranslation } from "react-i18next";
import IconButton from "components/IconButton";
import { myFont } from "./font";


interface IGridExpress extends IDataGridOptions {
  gridTitle?: string;
  columnsList?: {}[];
  pdfFileName?: string;
  xlsxFileName?: string;
  selectedRowsOnlyPDF?: any;
  handlePdfCustomization?: any;
  handleWordCustomization?: any;
  allowEdit?: boolean;
  allowAdd?: boolean;
  addButtonText?: string;
  isAddIcon?: boolean;
  allowDelete?: boolean;
  allowView?: boolean;
  allowEditException?: any;
  handleEdit?: (data: any) => any;
  handleDelete?: (data: any) => any;
  handleView?: (data: any) => any;
  handleAdd?: (data: any) => any;
  // Filter Pannel Props - starts here
  filterPanelVisibility?: any;
  filterPanelCustomizeText?: any;
  filterPanelEnabled?: any;
  filterPanelTexts?:
  | object
  | {
    clearFilter?: any;
    createFilter?: any;
    filterEnabledHint?: any;
  };
  defaultFilterEnabled?: any;
  onFilterEnabledChange?: (value: any) => void;
  // Filter Pannel Props - ends here
  // Paging Props - starts here
  pagingEnabled?: any;
  pagingIndex?: any;
  pagingSize?: any;
  // Paging Props - ends here
  // Pager Props - starts here
  showPager?: any;
  pageSizes?: any;
  displayMode?: any;
  showPageSizeSelector?: any;
  showPagerInfo?: any;
  pagerInfoText?: any;
  showNavigationButtons?: any;
  pagerLabel?: any;
  // Pager Props - ends here
  // Scrolling Props - starts here
  scrollMode?: any;
  columnRenderingMode?: any;
  scrollingPreloadEnabled?: any;
  scrollingRenderAsync?: any;
  scrollingRowRenderingMode?: any;
  scrollingScrollByContent?: any;
  scrollingScrollByThumb?: any;
  scrollingShowScrollbar?: any;
  scrollingUseNative?: any;
  // Scrolling Props - ends here
  // Search Panel Props - starts here
  SearchPanelVisibility?: any;
  highlightSearchText?: any;
  searchPanelPlaceholder?: any;
  searchPanelHighlightCaseSensitive?: any;
  searchPanelVisibleColumnsOnly?: any;
  searchPanelText?: any;
  searchPanelWidth?: any;
  // Search Panel Props - ends here
  // Sorting Props - starts here
  sortingMode?: any;
  sortingAscendingText?: any;
  sortingClearText?: any;
  sortingDescendingText?: any;
  sortingShowSortIndexes?: any;
  // Sorting Props - ends here
  // HeaderFilter Props - starts here
  headerFilterVisibility?: any;
  headerFilterSearch?: any;
  headerFilterHeight?: any;
  headerFilterSearchTimeOut?: any;
  headerFilterWidth?: any;
  headerFilterTexts?:
  | object
  | {
    cancel?: any;
    emptyValue?: any;
    ok?: any;
  };
  // HeaderFilter Props - ends here
  // Selection Props - starts here
  selectionMode?: any;
  selectAllMode?: any;
  showCheckBoxesMode?: any;
  allowSelectAll?: any;
  selectionDeferred?: any;
  // Selection Props - ends here
  // GroupPanel Props - ends here
  groupPanelVisibility?: any;
  groupingEmptyPanelText?: any;
  allowColumnDragging?: any;
  // GroupPanel Props - ends here
  // Grouping Props - ends here
  autoExpandAll?: any;
  allowCollapsing?: any;
  contextMenuEnabled?: any;
  groupingExpandMode?: any;
  groupTexts?:
  | object
  | {
    groupByThisColumn?: any;
    groupContinuedMessage?: any;
    groupContinuesMessage?: any;
    ungroup?: any;
    ungroupAll?: any;
  };
  // Grouping Props - ends here
  // RowDragging Props - starts here
  allowReordering?: any;
  onReorder?: any;
  showDragIcons?: any;
  allowDropInsideItem?: any;
  rowDraggingAutoScroll?: any;
  rowDraggingBoundary?: any;
  rowDraggingContainer?: any;
  rowDraggingDirection?: any;
  rowDraggingFilter?: any;
  handleOnDragChange?: any;
  handleOnDragEnd?: any;
  handleOnDragMove?: any;
  handleOnDragStart?: any;
  // RowDragging Props - ends here
  // IColumnChooser Props - starts here
  enabledColumnChooser?: any;
  searchInColumnChooser?: any;
  columnChooserEmptyPanelText?: any;
  columnChooserHeight?: any;
  columnChooserMode?: any;
  columnChooserSearchTimeOut?: any;
  columnChooserSortOrder?: any;
  columnChooserTitle?: any;
  columnChooserWidth?: any;
  // ColumnChooser Props - ends here
  // Column Fixing Props - starts here
  enabledFixingColumns?: any;
  columnFixingtexts?:
  | object
  | {
    fix?: any;
    leftPosition?: any;
    rightPosition?: any;
    unfix?: any;
  };
  // Column Fixing Props - ends here
  // Filter Row Props - starts here
  filterRowVisibility?: any;
  applyFilter?: any;
  applyFilterText?: any;
  betweenEndText?: any;
  betweenStartText?: any;
  resetOperationText?: any;
  filterRowShowAllText?: any;
  showOperationChooser?: any;
  operationDescriptions?:
  | object
  | {
    between?: any;
    contains?: any;
    endsWith?: any;
    equal?: any;
    greaterThan?: any;
    greaterThanOrEqual?: any;
    lessThan?: any;
    lessThanOrEqual?: any;
    notContains?: any;
    notEqual?: any;
    startsWith?: any;
  };
  // Filter Row Props - ends here
  // Load Panel Props - starts here
  LoadPanelEnabled?: any;
  indicatorSrc?: any;
  loadPanelHeight?: any;
  loadPanelShading?: any;
  loadPanelShadingColor?: any;
  loadPanelShowIndicator?: any;
  loadPanelShowPane?: any;
  loadPanelText?: any;
  loadPanelWidth?: any;
  // Load Panel Props - ends here
  // Master Detail Props - starts here
  MasterDetailEnabled?: any;
  masterDetailAutoExpandAll?: any;
  MasterDetailComponent?: ComponentType<any>;
  MasterDetailRender?: (...params: any) => ReactNode;
  masterDetailTemplate?: any;
  // Master Detail Props - ends here
  // Export Props - starts here
  enabledXlsxExport?: any;
  allowExportSelectedData?: any;
  exportFormats?: any;
  exportTexts?:
  | object
  | {
    exportAll?: any;
    exportSelectedRows?: any;
    exportTo?: any;
  };
  // Export Props - ends here
  // Keyboard Navigation Props - starts here
  editOnKeyPress?: any;
  keyboardNavigationEnabled?: any;
  enterKeyAction?: any;
  enterKeyDirection?: any;
  // Keyboard Navigation Props - ends here
  // State Storing Props - starts here
  stateStoringEnabled?: any;
  stateStoringType?: any;
  stateStoringKey?: any;
  stateStoringCustomLoad?: any;
  stateStoringCustomSave?: any;
  stateStoringSavingTimeOut?: any;
  // State Storing Props - ends here
}

const GridExpress: FC<IGridExpress> = (props) => {
  const {
    children,
    className = "",
    gridTitle = "Grid Title",
    columnsList = [],
    // summaryList = [],
    cellHintEnabled = true,
    cacheEnabled = true,
    accessKey = undefined,
    // Table data
    dataSource,
    remoteOperations = 'auto',
    // language direction
    // rtlEnabled,
    // Table border
    showBorders = false,
    // Table Scrollbar
    // showScrollbar = "always",
    // Show hover effect
    hoverStateEnabled = true,
    // Column reordering
    allowColumnReordering = true,
    // Allow horizontal line
    // ShowHorizontalLines = false,
    // Show column side border
    showColumnLines = false,
    // Show rows bottom border
    showRowLines = false,
    // How many rows to show
    pagingSize,
    // Allow pager
    showPager = true,
    // Show page size selector
    showPageSizeSelector = true,
    // 'full' show as buttons
    // 'compact' show as dropdown
    displayMode = "full",
    // page size choices
    pageSizes = [5, 10, 20, 50, 100],
    // show pages info
    showPagerInfo = true,
    // show pages navigations btns
    showNavigationButtons = true,
    pagerInfoText = "",
    width = "100%",
    height = "100%",
    // "virtual" Renders only those columns that get into the viewport.
    // "standard" Renders all columns at once.
    scrollMode = "",
    // Horizontal scrolling
    // "virtual" Renders only those columns that get into the viewport.
    // "standard" Renders all columns at once.
    columnRenderingMode = "virtual",
    // 'none'
    // 'single' Rows can be sorted by values of a single column only.
    // 'multiple' Rows can be sorted by values of several columns.
    sortingMode = "single",
    // Show header filter
    headerFilterVisibility = true,
    // Show header filter search
    headerFilterSearch = true,
    // Focused row enabled
    focusedRowEnabled = true,
    // Focus on new row
    autoNavigateToFocusedRow = true,
    // Focused Row Key
    keyExpr,
    // Focused Row key number by default
    focusedRowKey = 0,
    // Select rows by checkbox
    // 'none', 'single', 'multiple'
    selectionMode = "multiple",
    // 'allPages', 'page'
    selectAllMode = "allPages",
    // 'onClick', 'onLongTap', 'always'
    showCheckBoxesMode = "always",
    // Allow select all
    allowSelectAll = true,
    // Show group panel at the top to sort the grouping
    groupPanelVisibility = true,
    // Expand all groups by default
    autoExpandAll = true,
    // Allow groups to collapsing
    allowCollapsing = true,
    // Enabled word wrapping
    wordWrapEnabled = true,
    // Allow reordering
    allowReordering = false,
    // Always show drag icon
    showDragIcons = true,
    // Column resizing
    allowColumnResizing = true,
    // Allow column auto width
    columnAutoWidth = true,
    // Allow search in column chooser
    searchInColumnChooser = true,
    // Show column chooser
    enabledColumnChooser = true,
    // Allow fixed columns (fixed place)
    enabledFixingColumns = false,
    // 'nextColumn', 'widget'
    columnResizingMode = "nextColumn",
    // Column min width
    columnMinWidth = undefined,
    // Text to show when there is no data
    noDataText = "No Data",
    // Show filter
    filterRowVisibility = true,
    // Apply filter
    applyFilter = "auto",
    // Allow hiding columns to adapt to the screen or container size
    // IS ignored if allowColumnResizing is true and columnResizingMode is "widget"
    columnHidingEnabled = false,
    // Allow search panel
    SearchPanelVisibility = true,
    // Placeholder for search panel
    searchPanelPlaceholder = "Search",
    // Highlighted search result
    highlightSearchText = true,
    // For rendering function
    dataRowRender,
    // Show load panel
    LoadPanelEnabled = true,
    // Specifies a URL pointing to an image to be used as a loading indicator in load panel
    indicatorSrc,
    // PDF file name
    pdfFileName,
    // Word file name
    xlsxFileName,
    // Keep columns width in pdf export
    // keepColumnWidthsPDF = true,
    // Export select rows only to pdf
    selectedRowsOnlyPDF = false,
    // Allow master detail
    MasterDetailEnabled = false,
    // Master detail component
    MasterDetailComponent,
    // Master detail function
    MasterDetailRender,
    // Allow export to excel
    enabledXlsxExport = true,
    // Export all | Export selected only
    allowExportSelectedData = true,
    groupingEmptyPanelText = "Drag a column header here to group",
    columnChooserEmptyPanelText = "Drag a column here to hide it",
    columnChooserHeight = 260,
    // 'dragAndDrop', 'select'
    columnChooserMode = "dragAndDrop",
    columnChooserSearchTimeOut = 500,
    // 'asc', 'desc'
    columnChooserSortOrder = undefined,
    columnChooserTitle = "Column Chooser",
    columnChooserWidth = 250,
    columnFixingtexts = {
      fix: "Fix",
      leftPosition: "To the left",
      rightPosition: "To the right",
      unfix: "unfix",
    },
    activeStateEnabled = false,
    // 'auto'
    columnWidth = undefined,
    disabled = false,
    dateSerializationFormat,
    customizeColumns,
    dataRowComponent,
    dataRowTemplate,
    elementAttr = {},
    errorRowEnabled = true,
    // 'pdf', 'xlsx'
    exportFormats,
    exportTexts = {
      exportAll: "Export all data to {0}",
      exportSelectedRows: "Export selected rows to {0}",
      exportTo: "Export",
    },
    filterBuilder = {},
    filterBuilderPopup = {},
    filterPanelVisibility = false,
    filterPanelCustomizeText = null,
    filterPanelEnabled = true,
    filterPanelTexts = {
      clearFilter: "clear",
      createFilter: "Create Filter",
      filterEnabledHint: "Enable the filter",
    },
    defaultFilterEnabled,
    onFilterEnabledChange,
    showOperationChooser = true,
    filterRowShowAllText = "",
    betweenStartText = "Start",
    resetOperationText = "Reset",
    applyFilterText = "Apply filter",
    betweenEndText = "End",
    operationDescriptions = {
      between: "Between",
      contains: "Contains",
      endsWith: "Ends with",
      equal: "Equals",
      greaterThan: "Greater than",
      greaterThanOrEqual: "Greater than or equal to",
      lessThan: "Less than",
      lessThanOrEqual: "Less than or equal to",
      notContains: "Does not contain",
      notEqual: "Does not equal",
      startsWith: "Starts with",
    },
    filterSyncEnabled = "auto",
    filterValue = "",
    focusedColumnIndex,
    focusedRowIndex,
    // focusStateEnabled = false,
    contextMenuEnabled = true,
    groupingExpandMode = "buttonClick",
    groupTexts = {
      groupByThisColumn: "Group by This Column",
      groupContinuedMessage: "Continued from the previous page",
      groupContinuesMessage: "Continues on the next page",
      ungroup: "Ungroup",
      ungroupAll: "Ungroup All",
    },
    allowColumnDragging = true,
    headerFilterHeight = 325,
    headerFilterSearchTimeOut = 500,
    headerFilterTexts = {
      cancel: "Cancel",
      emptyValue: "",
      ok: "Ok",
    },
    headerFilterWidth = 252,
    highlightChanges = false,
    editOnKeyPress = false,
    keyboardNavigationEnabled = false,
    // 'startEdit', 'moveFocus'
    enterKeyAction = "startEdit",
    // 'none', 'column', 'row'
    enterKeyDirection = "none",
    loadPanelHeight = 90,
    loadPanelShading = false,
    loadPanelShadingColor = "",
    loadPanelShowIndicator = true,
    loadPanelShowPane = true,
    loadPanelText = "Loading...",
    loadPanelWidth = 200,
    masterDetailAutoExpandAll = false,
    masterDetailTemplate,
    pagerLabel = "Page Navigation",
    pagingEnabled = true,
    pagingIndex = 0,
    renderAsync = undefined,
    repaintChangesOnly = false,
    rowAlternationEnabled = false,
    scrollingPreloadEnabled = true,
    scrollingRenderAsync = undefined,
    // 'standard', 'virtual'
    scrollingRowRenderingMode = "",
    scrollingScrollByContent = true,
    scrollingScrollByThumb = true,
    // 'always', 'never', 'onHover', 'onScroll'
    scrollingShowScrollbar = "onHover",
    scrollingUseNative = "auto",
    searchPanelHighlightCaseSensitive = false,
    searchPanelVisibleColumnsOnly = false,
    searchPanelText = "",
    searchPanelWidth = 160,
    selectedRowKeys,
    selectionDeferred = false,
    selectionFilter,
    showColumnHeaders = true,
    sortingAscendingText = "Sort Ascending",
    sortingClearText = "Clear Sorting",
    sortingDescendingText = "Sort Descending",
    sortingShowSortIndexes = true,
    stateStoringEnabled = false,
    // 'custom', 'localStorage', 'sessionStorage'
    stateStoringType = "localStorage",
    stateStoringKey = null,
    stateStoringCustomLoad = null,
    stateStoringCustomSave = null,
    stateStoringSavingTimeOut = 2000,
    twoWayBindingEnabled,
    tabIndex,
    syncLookupFilterValues,
    allowDropInsideItem = false,
    rowDraggingAutoScroll = true,
    rowDraggingBoundary = undefined,
    rowDraggingContainer = undefined,
    rowDraggingDirection = "both",
    rowDraggingFilter = "> *",
    handlePdfCustomization,
    onDataErrorOccurred,
    onFocusedRowChanged,
    onAdaptiveDetailRowPreparing,
    onContentReady,
    onContextMenuPreparing,
    onFocusedCellChanged,
    onFocusedCellChanging,
    onFocusedRowChanging,
    onInitialized,
    onInitNewRow,
    onKeyDown,
    onOptionChanged,
    onRowClick,
    onRowCollapsed,
    onRowCollapsing,
    onRowDblClick,
    onRowExpanded,
    onRowExpanding,
    onRowInserted,
    onRowInserting,
    onRowPrepared,
    onRowRemoved,
    onRowRemoving,
    onRowUpdated,
    onRowUpdating,
    onRowValidating,
    onReorder,
    onSelectionChanged,
    onDisposing,
    handleOnDragChange,
    handleOnDragEnd,
    handleOnDragMove,
    handleOnDragStart,
    handleWordCustomization = () => { },
    //
    addButtonText,
    isAddIcon = false,
    allowAdd = false,
    allowEdit = false,
    allowDelete = false,
    allowView = false,
    allowEditException,
    handleEdit = () => { },
    handleDelete = () => { },
    handleView = () => { },
    handleAdd = () => { },
  } = props;

  const dataGridRef: LegacyRef<DataGrid<any, any>> = createRef();
  const { t, i18n } = useTranslation();
  const doc = new jsPDF('p', 'pt', 'a4');

  doc.addFileToVFS("Amiri-Regular.ttf", myFont);
  doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
  doc.setFont("Amiri");

  const onToolbarPreparing = (e: any) => {
    let toolbarItems = e.toolbarOptions.items;
    toolbarItems?.unshift(
      allowAdd ?
        {
          location: "after",
          widget: "dxButton",
          options: {
            icon: isAddIcon && "add",
            onClick: () => handleAdd({}),
            name: 'add',
            text: i18n.language == 'ar' ? 'إضافة جديد' : 'Add New Item',
          },
        } : '',
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "exportpdf",
          hint: i18n.language == 'ar' ? 'تصدير ملف PDF' : 'Export PDF',
          onClick: () => {
            const options = {
              jsPDFDocument: doc,
              component: dataGridRef?.current?.instance,
              autoTableOptions: {
                styles: {
                  font: 'Amiri',
                }
              }
            };
            exportDataGridToPdf(options).then(() => {
              doc.save(`${xlsxFileName}.pdf`);
            });
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "clearformat",
          onClick: () => dataGridRef?.current?.instance?.clearFilter(),
          hint: i18n.language == 'ar' ? 'مسح البحث' : "Clear Search",
        },
      },
      {
        location: "before",
        text: gridTitle,
        cssClass: i18n.language === "ar" ? "ps-5" : "pe-5",
      }
    );
  };

  const onExportxlsx = (e: any) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    exportDataGrid({
      component: dataGridRef?.current?.instance,
      worksheet,
      autoFilterEnabled: true,
      customizeCell: (options: any) => {
        options.excelCell.font = {
          family: 2,
          name: "Cairo",
          size: 10,
          color: { argb: "151713" },
        };
        options.excelCell.alignment = {
          indent: 1,
          wrapText: true,
          vertical: "middle",
        };
        if (options?.gridCell.rowType === "group") {
          options.excelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "d0b166" },
          };
          options.excelCell.alignment = { indent: 2 };
          options.excelCell.font = {
            family: 2,
            name: "Cairo",
            size: 10,
            color: { argb: "ffffff" },
            bold: true,
          };
        }
        if (options?.gridCell.rowType === "header") {
          options.excelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "58743a" },
          };
          options.excelCell.alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          options.excelCell.font = {
            family: 2,
            name: "Cairo",
            size: 12,
            color: { argb: "ffffff" },
            bold: true,
          };
        }
        handleWordCustomization(options)
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `${xlsxFileName}.xlsx`
        );
      });
    });
    e.cancel = true;
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-access-key
    <DataGrid
      id="dataGrid"
      className={i18n.language === "ar" ? 'grid-arabic' : 'grid-english'}
      ref={dataGridRef}
      width={width}
      height={height}
      accessKey={accessKey}
      cellHintEnabled={cellHintEnabled}
      activeStateEnabled={activeStateEnabled}
      // gridTitle={gridTitle}
      cacheEnabled={cacheEnabled}
      // className={className}
      dataSource={dataSource}
      // columnsList={columnsList}
      // summaryList={summaryList}
      remoteOperations={remoteOperations}
      showBorders={showBorders}
      showColumnHeaders={showColumnHeaders}
      wordWrapEnabled={wordWrapEnabled}
      twoWayBindingEnabled={twoWayBindingEnabled}
      // showScrollbar={showScrollbar}
      hoverStateEnabled={hoverStateEnabled}
      allowColumnReordering={allowColumnReordering}
      allowColumnResizing={allowColumnResizing}
      // ShowHorizontalLines={ShowHorizontalLines}
      showColumnLines={showColumnLines}
      showRowLines={showRowLines}
      focusedRowEnabled={focusedRowEnabled}
      keyExpr={keyExpr}
      focusedRowKey={focusedRowKey}
      autoNavigateToFocusedRow={autoNavigateToFocusedRow}
      columnAutoWidth={columnAutoWidth}
      columnResizingMode={columnResizingMode}
      columnMinWidth={columnMinWidth}
      columnWidth={columnWidth}
      noDataText={noDataText}
      columnHidingEnabled={columnHidingEnabled}
      disabled={disabled}
      dateSerializationFormat={dateSerializationFormat}
      customizeColumns={customizeColumns}
      dataRowComponent={dataRowComponent}
      dataRowTemplate={dataRowTemplate}
      elementAttr={elementAttr}
      errorRowEnabled={errorRowEnabled}
      filterBuilder={filterBuilder}
      filterBuilderPopup={filterBuilderPopup}
      filterSyncEnabled={filterSyncEnabled}
      filterValue={filterValue}
      focusedColumnIndex={focusedColumnIndex}
      focusedRowIndex={focusedRowIndex}
      // focusStateEnabled={focusStateEnabled}
      highlightChanges={highlightChanges}
      renderAsync={renderAsync}
      repaintChangesOnly={repaintChangesOnly}
      rowAlternationEnabled={rowAlternationEnabled}
      rtlEnabled={i18n.language === "ar" ? true : false}
      selectedRowKeys={selectedRowKeys}
      selectionFilter={selectionFilter}
      syncLookupFilterValues={syncLookupFilterValues}
      tabIndex={tabIndex}
      dataRowRender={dataRowRender}
      onSelectionChanged={onSelectionChanged}
      onFocusedRowChanged={onFocusedRowChanged}
      onExporting={onExportxlsx}
      onToolbarPreparing={(e) => onToolbarPreparing(e)}
      // handlePdfCustomization={handlePdfCustomization}
      onAdaptiveDetailRowPreparing={onAdaptiveDetailRowPreparing}
      onContentReady={onContentReady}
      onContextMenuPreparing={onContextMenuPreparing}
      onDataErrorOccurred={onDataErrorOccurred}
      onDisposing={onDisposing}
      onFocusedCellChanged={onFocusedCellChanged}
      onFocusedCellChanging={onFocusedCellChanging}
      onFocusedRowChanging={onFocusedRowChanging}
      onInitialized={onInitialized}
      onInitNewRow={onInitNewRow}
      onKeyDown={onKeyDown}
      onOptionChanged={onOptionChanged}
      onRowClick={onRowClick}
      onRowCollapsed={onRowCollapsed}
      onRowCollapsing={onRowCollapsing}
      onRowDblClick={onRowDblClick}
      onRowExpanded={onRowExpanded}
      onRowExpanding={onRowExpanding}
      onRowInserted={onRowInserted}
      onRowInserting={onRowInserting}
      onRowPrepared={onRowPrepared}
      onRowRemoved={onRowRemoved}
      onRowRemoving={onRowRemoving}
      onRowUpdated={onRowUpdated}
      onRowUpdating={onRowUpdating}
      onRowValidating={onRowValidating}
    >
      <FilterPanel
        visible={filterPanelVisibility}
        customizeText={filterPanelCustomizeText}
        filterEnabled={filterPanelEnabled}
        texts={filterPanelTexts}
        defaultFilterEnabled={defaultFilterEnabled}
        onFilterEnabledChange={onFilterEnabledChange}
      />

      <LoadPanel
        enabled={LoadPanelEnabled}
        indicatorSrc={indicatorSrc}
        height={loadPanelHeight}
        shading={loadPanelShading}
        shadingColor={loadPanelShadingColor}
        showIndicator={loadPanelShowIndicator}
        showPane={loadPanelShowPane}
        text={loadPanelText}
        width={loadPanelWidth}
      />

      <StateStoring
        enabled={stateStoringEnabled}
        type={stateStoringType}
        storageKey={stateStoringKey}
        customLoad={stateStoringCustomLoad}
        customSave={stateStoringCustomSave}
        savingTimeout={stateStoringSavingTimeOut}
      />

      <Sorting
        mode={sortingMode}
        ascendingText={sortingAscendingText}
        clearText={sortingClearText}
        descendingText={sortingDescendingText}
        showSortIndexes={sortingShowSortIndexes}
      />

      <GroupPanel
        visible={groupPanelVisibility}
        emptyPanelText={groupingEmptyPanelText}
        allowColumnDragging={allowColumnDragging}
      />

      <Grouping
        autoExpandAll={autoExpandAll}
        allowCollapsing={allowCollapsing}
        contextMenuEnabled={contextMenuEnabled}
        expandMode={groupingExpandMode}
        texts={groupTexts}
      />

      <RowDragging
        allowReordering={allowReordering}
        onReorder={onReorder}
        showDragIcons={showDragIcons}
        allowDropInsideItem={allowDropInsideItem}
        autoScroll={rowDraggingAutoScroll}
        boundary={rowDraggingBoundary}
        container={rowDraggingContainer}
        dragDirection={rowDraggingDirection}
        filter={rowDraggingFilter}
        onDragChange={handleOnDragChange}
        onDragEnd={handleOnDragEnd}
        onDragMove={handleOnDragMove}
        onDragStart={handleOnDragStart}
      />

      <FilterRow
        visible={filterRowVisibility}
        applyFilter={applyFilter}
        applyFilterText={applyFilterText}
        betweenEndText={betweenEndText}
        betweenStartText={betweenStartText}
        resetOperationText={resetOperationText}
        showAllText={filterRowShowAllText}
        showOperationChooser={showOperationChooser}
        operationDescriptions={operationDescriptions}
      />

      <HeaderFilter
        visible={headerFilterVisibility}
        allowSearch={headerFilterSearch}
        height={headerFilterHeight}
        searchTimeout={headerFilterSearchTimeOut}
        texts={headerFilterTexts}
        width={headerFilterWidth}
      />

      <Selection
        mode={selectionMode}
        selectAllMode={selectAllMode}
        showCheckBoxesMode={showCheckBoxesMode}
        allowSelectAll={allowSelectAll}
        deferred={selectionDeferred}
      />

      <ColumnChooser
        enabled={enabledColumnChooser}
        allowSearch={searchInColumnChooser}
        emptyPanelText={columnChooserEmptyPanelText}
        height={columnChooserHeight}
        mode={columnChooserMode}
        searchTimeout={columnChooserSearchTimeOut}
        sortOrder={columnChooserSortOrder}
        title={i18n.language == 'ar' ? 'منتقي الأعمدة' : "Column Chooser"}
        width={columnChooserWidth}
      />

      <ColumnFixing enabled={enabledFixingColumns} texts={columnFixingtexts} />

      <KeyboardNavigation
        editOnKeyPress={editOnKeyPress}
        enabled={keyboardNavigationEnabled}
        enterKeyAction={enterKeyAction}
        enterKeyDirection={enterKeyDirection}
      />

      <SearchPanel
        visible={SearchPanelVisibility}
        highlightSearchText={highlightSearchText}
        placeholder={i18n.language == 'ar' ? 'بحث' : 'Search'}
        highlightCaseSensitive={searchPanelHighlightCaseSensitive}
        searchVisibleColumnsOnly={searchPanelVisibleColumnsOnly}
        text={searchPanelText}
        width={searchPanelWidth}
      />

      <Export
        enabled={enabledXlsxExport}
        allowExportSelectedData={allowExportSelectedData}
        formats={exportFormats}
        texts={{
          exportAll: i18n.language == 'ar' ? 'تصدير كافة البيانات' : 'Export all data',
          exportSelectedRows: i18n.language == 'ar' ? 'تصدير الصفوف المحددة' : 'Export selected rows',
          exportTo: i18n.language == 'ar' ? 'تصدير إلى Excel' : 'Export to Excel',
        }}
      />

      <Scrolling
        mode={scrollMode}
        rowRenderingMode={scrollingRowRenderingMode}
        columnRenderingMode={columnRenderingMode}
        preloadEnabled={scrollingPreloadEnabled}
        renderAsync={scrollingRenderAsync}
        scrollByContent={scrollingScrollByContent}
        scrollByThumb={scrollingScrollByThumb}
        showScrollbar={scrollingShowScrollbar}
        useNative={scrollingUseNative}
      />

      <Paging
        enabled={pagingEnabled}
        pageIndex={pagingIndex}
        pageSize={pagingSize}
      />

      <Pager
        visible={showPager}
        allowedPageSizes={pageSizes}
        displayMode={displayMode}
        showPageSizeSelector={showPageSizeSelector}
        showInfo={showPagerInfo}
        infoText={pagerInfoText}
        showNavigationButtons={showNavigationButtons}
        label={pagerLabel}
      />

      <MasterDetail
        enabled={MasterDetailEnabled}
        autoExpandAll={masterDetailAutoExpandAll}
        component={MasterDetailComponent}
        render={MasterDetailRender}
        template={masterDetailTemplate}
      />

      {columnsList?.map((col: any, i) => {
        let obj: any = {};
        for (const property in col) {
          obj[`${property}`] = col[property];
        }
        return <Column {...obj} key={i} />;
      })}

      {children}

      {(allowEdit || allowDelete || allowView) && (
        <Column
          cssClass="gridIconsCloumnsStyle"
          type="buttons"
          caption={t("details")}
          fixed={true}
          fixedPosition={i18n.language === "ar" ? "left" : "right"}
        >
          {allowView && (
            <Button
              hint={i18n.language == 'ar' ? 'عرض' : "View"}
              render={(record) => {
                return (
                  <IconButton
                    btncolor="warning"
                    icon="eye"
                    onClick={() => handleView(record.data)}
                  />
                );
              }}
            />
          )}
          {allowEdit && (
            <Button
              hint={i18n.language == 'ar' ? 'تعديل' : "Edit"}
              render={(record) => {
                if (allowEditException) {
                  let prop = Object.keys(allowEditException)[0]
                  let val = Object.values(allowEditException)[0]
                  if (record.data[prop] != val) {
                    return (
                      <IconButton
                        btncolor="info"
                        icon="edit"
                        onClick={() => handleEdit(record.data)}
                      />
                    );
                  }
                } else {
                  return (
                    <IconButton
                      btncolor="info"
                      icon="edit"
                      onClick={() => handleEdit(record.data)}
                    />
                  );
                }
              }}
            />
          )}
          {allowDelete && (
            <Button
              hint={i18n.language == 'ar' ? 'حذف' : "Delete"}
              render={(record) => {
                return (
                  <IconButton
                    btncolor="error"
                    icon="delete"
                    onClick={() => handleDelete(record.data)}
                  />
                );
              }}
            />
          )}
        </Column>
      )}

      {/* <Summary
        recalculateWhileEditing={true}
      // calculateCustomSummary={this.calculateSelectedRow}
      >
        {summaryList?.map((list, i) => {
          let obj = {}
          for (const property in list) {
            obj[`${property}`] = list[property]
          }
          return <TotalItem {...obj} key={i} />
        })}
      </Summary> */}

      {/* <Summary
        recalculateWhileEditing={true}
        calculateCustomSummary={this.calculateSelectedRow}
      >
        <TotalItem
          name="SelectedRowsSummary"
          column="OrderNumber"
          summaryType="count"
          customizeText={this.customizeDate}
          valueFormat="currency"
          displayFormat="Sum: {0}"
          showInColumn="SaleAmount"
        />
      </Summary> */}
    </DataGrid>
  );
};

export default GridExpress;
