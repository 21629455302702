import React, { useState, useEffect, ReactNode } from 'react';
import { Button as AntdButton } from 'antd';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";

const StyledButton = styled(AntdButton) <{ btncolor: string, btntype: string, dir: string, width: string, height: string }>`
    border-radius: ${props => props.dir == 'rtl' ? '10px 50px 50px 50px' : '50px 10px 50px 50px'};
    width: ${props => props.width};
    height: ${props => props.height};
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    fontSize: 18px;
    line-height: 25px;

    ${props => props.btntype == 'default' && `
        border: none;
        color: #fff!important;
    `}
    ${props => props.btntype == 'default' && props.btncolor == 'primary' && `
        background-color: ${props.theme.antd.colorPrimary}!important; 
        &:hover {
            filter: brightness(90%);
        }
    `}
    ${props => props.btntype == 'default' && props.btncolor == 'info' && `
        background-color: ${props.theme.antd.colorInfo}!important; 
        &:hover {
            filter: brightness(185%);
        }
    `}
    ${props => props.btntype == 'default' && props.btncolor == 'success' && `
        background-color: ${props.theme.antd.colorSuccess}!important; 
        &:hover {
            filter: brightness(85%);
        }
    `}
    ${props => props.btntype == 'default' && props.btncolor == 'danger' && `
        background-color: #F16430!important; 
        &:hover {
            filter: brightness(90%);
        }
    `}

    ${props => props.btntype == 'outline' && `
        border: 1px solid;
    `}
    ${props => props.btntype == 'outline' && props.btncolor == 'primary' && `
        border-color: ${props.theme.antd.colorPrimary}!important;  
        color: ${props.theme.antd.colorPrimary}!important;
        &:hover {
            background: ${props.theme.antd.colorPrimary}!important;
            color: #fff!important;
        }
    `}
    ${props => props.btntype == 'outline' && props.btncolor == 'info' && `
        border-color: ${props.theme.antd.colorInfo}!important;  
        color: ${props.theme.antd.colorInfo}!important;
        &:hover {
            background: ${props.theme.antd.colorInfo}!important;
            color: #fff!important;
        }
    `}
    ${props => props.btntype == 'outline' && props.btncolor == 'success' && `
        border-color: ${props.theme.antd.colorSuccess}!important;  
        color: ${props.theme.antd.colorSuccess}!important;
        &:hover {
            background: ${props.theme.antd.colorSuccess}!important;
            color: #fff!important;
        }
    `}
    ${props => props.btntype == 'outline' && props.btncolor == 'danger' && `
        border-color: #F16430!important;  
        color: #F16430!important;
        &:hover {
            background: #F16430!important;
            color: #fff!important;
        }
    `}
`

type CustomMainButtonProps = {
    title?: string
    href?: string
    target?: string
    // Option to fit button width to its parent width
    block?: boolean
    disabled?: boolean
    loading?: boolean | { delay: number }
    onClick?: () => void
    btncolor?: 'primary' | 'success' | 'danger' | 'info'
    btntype?: 'default' | 'outline'
    dir?: 'rtl' | 'ltr'
    width?: string
    height?: string
    htmlType?: "button" | "submit" | "reset"
}

export default function MainButton({
    title,
    href,
    target,
    block = false,
    disabled = false,
    loading = false,
    btncolor = 'primary',
    onClick = () => { },
    btntype = 'default',
    width = '295px',
    height = '70px;',
    htmlType = 'button'
}: CustomMainButtonProps) {
    const { i18n } = useTranslation()
    return (
        <StyledButton
            href={href}
            target={target}
            block={block}
            disabled={disabled}
            loading={loading}
            onClick={onClick}
            btncolor={btncolor}
            btntype={btntype}
            dir={i18n.language === "en" ? 'ltr' : 'rtl'}
            width={width}
            height={height}
            htmlType={htmlType}
        >
            {title}
        </StyledButton>
    )
}