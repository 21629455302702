const Bell = ({notifNo} : { notifNo: Number }) => {
    return (
        <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 17.44V20.77M12.02 13C8.34002 13 5.36002 15.98 5.36002 19.66V21.76C5.36002 22.44 5.08002 23.46 4.73002 24.04L3.46002 26.16C2.68002 27.47 3.22002 28.93 4.66002 29.41C9.4413 31 14.6087 31 19.39 29.41C19.7055 29.3048 19.9933 29.13 20.2322 28.8986C20.4711 28.6671 20.6549 28.385 20.7701 28.073C20.8853 27.761 20.9289 27.4271 20.8978 27.096C20.8666 26.7649 20.7614 26.445 20.59 26.16L19.32 24.04C18.97 23.46 18.69 22.43 18.69 21.76V19.66C18.68 16 15.68 13 12.02 13Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M15.33 29.82C15.33 31.65 13.83 33.15 12 33.15C11.09 33.15 10.25 32.77 9.64998 32.17C9.04998 31.57 8.66998 30.73 8.66998 29.82" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" />
        <g>
            <rect x="15" y="1" width="20" height="20" rx="7" fill="#D0B166" stroke="#F7F7F7" strokeWidth="2" />
            <text x="70%" y="35%" dominantBaseline="middle" textAnchor="middle" fontSize="11" fill="#fff"><>{notifNo}</></text>
        </g>
    </svg>
    )
} 

export default Bell;