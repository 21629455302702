import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { getAllTasksList } from 'api/tasks/tasks'
import { useAppSelector, useAppDispatch } from 'store'
import GridExpress from 'components/GridExpress/GridExpress'
import CustomStore from 'devextreme/data/custom_store'
import { triggerModalTab } from 'features/utils/modalTabSlice'
import TasksListForm from './TasksListForm'
import moment from 'moment'
import Datebox from 'components/Datebox'

export default function TasksList() {

    const { t, i18n } = useTranslation()
    const [showModal, setShowModal] = useState<any>(false)
    const [requestData, setRequestData] = useState<any>({
        fromdate: '',
        todate: ''
    })
    const currentUser = useAppSelector((state: any) => state.currentUser)
    const modalStatus = useAppSelector(state => state.modalTab)
    const dispatch = useAppDispatch()

    useEffect(() => {
        setShowModal(modalStatus?.Open)
    }, [modalStatus])

    const columnsList = () => {
        let MissionTypeName = 'Mission_Type_Name_EN',
            MissionFrom = 'Mission_from_User_Name_EN',
            MissionTo = 'Mission_to_User_Name_EN',
            DepartmentNameKey = 'Department_Name_EN'

        if (i18n.language == 'ar') {
            MissionTypeName = 'Mission_Type_Name_AR'
            MissionFrom = 'Mission_from_User_Name_AR'
            MissionTo = 'Mission_to_User_Name_AR'
            DepartmentNameKey = 'Department_Name_AR'
        }

        return [
            {
                dataField: "Mission_No",
                caption: t('missionNo'),
            },
            {
                dataField: "Mission_Date",
                caption: t('missionDate'),
                cellRender: (data: any) => moment(data.Mission_Date).format('HH:mm YYYY-MM-DD')
            },
            {
                dataField: DepartmentNameKey,
                caption: t('DepartmentName'),
            },
            {
                dataField: MissionFrom,
                caption: t('missionFrom'),
            },
            {
                dataField: MissionTo,
                caption: t('missionTo'),
            },
            {
                dataField: MissionTypeName,
                caption: t('missionTypeName'),
            },
            {
                dataField: "Mission_Title",
                caption: t('missionTitle'),
            },
        ]
    }

    const jsonDataSource = new CustomStore({
        key: "Mission_Id",
        loadMode: 'raw',
        load: () => {
            return getAllTasksList(currentUser.Data.Data.access_token, requestData)
        }
    })

    return (
        <>
            <Row gutter={[12, 0]}>
                <Col xs={24} md={6}>
                    <Datebox
                        label={t('fromDate') as string}
                        variant="date"
                        onChange={(d: any, dateString: string) => {
                            setRequestData((prevState: any) => ({
                                ...prevState,
                                fromdate: dateString
                            }))
                        }}
                    />
                </Col>
                <Col xs={24} md={6}>
                    <Datebox
                        label={t('toDate') as string}
                        variant="date"
                        onChange={(d: any, dateString: string) => {
                            setRequestData((prevState: any) => ({
                                ...prevState,
                                todata: dateString
                            }))
                        }}
                    />
                </Col>
            </Row>

            <Row style={{ marginTop: '30px' }}>
                <Col>
                    <GridExpress
                        allowSelectAll
                        allowView={true}
                        allowEdit={false}
                        columnsList={columnsList()}
                        dataSource={jsonDataSource}
                        handleView={(data) => dispatch(triggerModalTab({ Open: true, Data: data, Type: 'view' }))}
                        allowAdd={false}
                        gridTitle={t('tasksList') as string}
                        groupPanelVisibility={false}
                        xlsxFileName={t('tasksList') as string}
                    >
                    </GridExpress>
                </Col>
            </Row >

            {showModal && <TasksListForm />}
        </>
    )
}