import React, { useState, ReactNode, useEffect } from 'react'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { Modal, Upload, Button } from 'antd'
import type { RcFile, UploadProps } from 'antd/es/upload'
import type { UploadFile } from 'antd/es/upload/interface'
import styled from "styled-components"

const prefixCls = 'ant-upload'
const SwitchLabel = styled.div`
  color: rgb(0, 0, 0);
  text-align: start;
  padding: 12px;
  font-weight: bold;
  color: ${props => props.theme.antd.colorTextLabel};
`
const AntdUpload = styled(Upload)`
    .${prefixCls}:hover {
        border-color: ${props => props.theme.antd.colorTextLabel}!important;
    }
    .${prefixCls}, .${prefixCls}-list-item-container {
        height: 130px!important;
        width: 130px!important;
    }

    .${prefixCls}-list{
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

type CustomUploadProps = {
    label?: string
    disabled?: boolean
    defaultImg?: string
    multiple?: boolean
    maxCount?: number
    canDelete?: boolean
    listType?: string
    handleUpload?: (options: any) => void
}

const getBase64 = (file: RcFile): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
})

export default function ImageUploader({
    label,
    disabled,
    defaultImg,
    multiple = false,
    maxCount = 1,
    canDelete = false,
    listType,
    handleUpload = () => { },
    ...rest
}: CustomUploadProps) {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([
        {
            uid: '-1',
            name: '',
            status: 'done',
            url: defaultImg,
        },
    ]);

    const handleCancel = () => setPreviewOpen(false)

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile)
        }

        setPreviewImage(file.url || (file.preview as string))
        setPreviewOpen(true)
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
    }

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        newFileList[0].status = 'done'
        setFileList(newFileList)
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    )

    return (
        <>
            {label ? <SwitchLabel>{label}</SwitchLabel> : null}

            <AntdUpload
                fileList={fileList}
                listType="picture-circle"
                onPreview={handlePreview}
                onChange={handleChange}
                customRequest={handleUpload}
                disabled={disabled}
                multiple={multiple}
                maxCount={maxCount}
                showUploadList={{ showRemoveIcon: canDelete }}
            >
                {uploadButton}
            </AntdUpload>

            <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </>
    );
}
