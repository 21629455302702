import { configureStore } from "@reduxjs/toolkit";
// import logger from "redux-logger";
// import { baseApi } from "api/baseApi";
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistReducer,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import currentUserReducer from "features/user";
import currentProfileReducer from "features/user/setCurrentProfile";
import loadingReducer from "features/utils/loadingSlice";
import notificationReducer from "features/utils/notificationSlice";
import tabsReducer from "features/utils/tabsSlice";
import activeTabReducer from "features/utils/activeTabSlice";
import modalTabReducer from "features/utils/modalTabSlice";
import { axiosMiddleware } from "api/apiConfig";
import { axiosBaseAPI } from "api/axiosBaseQuery";
import { departmentsAPI } from "api/departmentsAPI"

const appReducer = combineReducers({
  [axiosBaseAPI.reducerPath]: axiosBaseAPI.reducer,
  currentUser: currentUserReducer,
  currentProfile: currentProfileReducer,
  loading: loadingReducer,
  notification: notificationReducer,
  tabs: tabsReducer,
  activeTab: activeTabReducer,
  modalTab: modalTabReducer
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlwares = getDefaultMiddleware({
      serializableCheck: false
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
      // }).concat(axiosMiddleware);
    }).concat(departmentsAPI.middleware);
    // .concat(baseApi.middleware),
    middlwares.push(axiosMiddleware);
    if (process.env.NODE_ENV !== "production") {
      const { logger } = require("redux-logger");
      middlwares.push(logger);
    }

    return middlwares;
  },
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

type RootState = ReturnType<typeof store.getState>;
// type AppDispatch = typeof store.dispatch;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const getReduxState: () => RootState = () => store.getState();
