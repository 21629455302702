import { Col } from "antd";
import styled from "styled-components";
import { BottomMargin, RowWrapper } from "./DashboartStyledComponents";
import {
  Chart, Series, CommonSeriesSettings, Export, ArgumentAxis
} from 'devextreme-react/chart';

const ChartContainer = styled.div`
  background-color: white;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: 0px 0px 30px rgba(1, 18, 36, 0.06);
`;

const dataSource = [{
  state: '1 Aug',
  year1970: 286.7,
  year1980: 480.4,
}, {
  state: '8 Aug',
  year1970: 557.8,
  year1980: 423.2,
}, {
  state: '15 Aug',
  year1970: 332.7,
  year1980: 387.7,
}, {
  state: '22 Aug',
  year1970: 287.5,
  year1980: 378.1,
}, {
  state: '29 Aug',
  year1970: 550.7,
  year1980: 209.2,
}]

const dataSource2 = [
  {
    company: 'US',
    y2005: 200.25,
    y2004: 157.28,
  }, {
    company: 'UK',
    y2005: 250.43,
    y2004: 253.49,
  }, {
    company: 'China',
    y2005: 173.32,
    y2004: 350.86,
  }, {
    company: 'Japan',
    y2005: 300.50,
    y2004: 252.95,
  }, {
    company: 'Italy',
    y2005: 350.89,
    y2004: 100.67,
  }, {
    company: 'Span',
    y2005: 130.52,
    y2004: 200.72,
  }
]

const DashboardCharts = () => {
  return (
    <BottomMargin>
      <RowWrapper gutter={[20, 20]} justify={"space-between"} align={"middle"}>
        <Col sm={24} md={12}>
          <ChartContainer>
            <Chart
              id="chart"
              palette="Soft"
              dataSource={dataSource}
            >
              <CommonSeriesSettings
                argumentField="state"
                type="bar"
                barWidth={22}
              />
              <Series valueField="year1970" name="1970" color='#1BC4BD' cornerRadius='4' />
              <Series valueField="year1980" name="1980" color='#E5EAEE' cornerRadius='4' />
              <Export enabled={true} />
            </Chart>
          </ChartContainer>
        </Col>
        <Col sm={24} md={12}>
          <ChartContainer>
            <Chart
              palette="Harmony Light"
              dataSource={dataSource2}>
              <CommonSeriesSettings
                argumentField="company"
                type="splinearea"
                opacity="1"
              />
              <Series valueField="y2005" name="2005" color="rgba(255, 168, 0, 0.3)"></Series>
              <Series valueField="y2004" name="2004" color="#3699FF"></Series>
              <ArgumentAxis valueMarginsEnabled={false} />
              <Export enabled={true} />
            </Chart>
          </ChartContainer>
        </Col>
      </RowWrapper>
    </BottomMargin>
  );
};

export default DashboardCharts;
