import 'i18n'
import './App.css'
import Login from 'pages/Login/Login'
import Spinner from 'components/Spinner'
import Toast from 'components/Toast'
import Layout from 'layout'
import { useAppDispatch, useAppSelector } from 'store'
import { useEffect } from 'react'
import { triggerNotification } from 'features/utils/notificationSlice'
import { useTranslation } from "react-i18next"

function App() {
  const currentUser = useAppSelector(state => state.currentUser)
  const loading = useAppSelector(state => state.loading)
  const notification = useAppSelector(state => state.notification)
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()

  useEffect(() => {
    // Close Notification on it's Own
    if (notification.Open) {
      setTimeout(() => dispatch(triggerNotification({ Open: false })), 6000)
    }
  }, [notification.Open])

  useEffect(() => {
    // Close Notification if Page Refreshed
    dispatch(triggerNotification({ Open: false }))
  }, [window.performance])

  return (
    <>
      {
        !currentUser?.Status || currentUser?.Data?.Data?.User_Permissions?.length == 0 ? <Login />
          // : currentUser.Data?.resetPass || !currentUser.Data?.verifyMail || !currentUser.Data?.verifyPhone ? <></>
          : <Layout />
      }

      <Spinner isLoading={loading.status} />

      {notification.Open ?
        <Toast
          message={notification.messages}
          description={notification.error}
          colorType="solid"
          type="error"
          closable={true}
          onClose={() => dispatch(triggerNotification({ Open: false }))}
          style={{
            position: 'fixed',
            top: '10px',
            left: i18n.language == 'ar' ? '20%' : '80%',
            width: '400px',
            transform: 'translateX(-50%)',
            zIndex: '999'
          }}
        />
        : null}
    </>
  );

}

export default App;
