import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { changeDepartmentStatus } from 'api/departments/departments'
import { useGetDepartmentsListQuery } from 'api/departmentsAPI'
import { useAppSelector, useAppDispatch } from 'store'
import GridExpress from 'components/GridExpress/GridExpress'
import Switch from 'components/Switch'
import { triggerLoading } from 'features/utils/loadingSlice'
import { triggerModalTab } from 'features/utils/modalTabSlice'
import DepartmentsForm from './DepartmentsForm'

export default function DepartmentsList() {
    const { t, i18n } = useTranslation()
    const [showModal, setShowModal] = useState<any>(false)
    const currentUser = useAppSelector((state: any) => state.currentUser)
    const modalStatus = useAppSelector((state: any) => state.modalTab)
    const dispatch = useAppDispatch()
    const { data, error, isLoading } = useGetDepartmentsListQuery({});

    useEffect(() => {
        setShowModal(modalStatus?.Open)
    }, [modalStatus])

    const customizeCellCheckbox = (data: any) => {
        return (
            <Switch
                checked={data.data.Active_Status_Id}
                onChange={async () => {
                    let at = currentUser.Data.Data.access_token
                    let status = data.data.Active_Status_Id ? 0 : 1
                    let id = data.data.Department_Id
                    dispatch(triggerLoading({ status: true }))
                    await changeDepartmentStatus(
                        at,
                        id,
                        status
                    ).then(() => {
                        dispatch(triggerLoading({ status: false }))
                    })
                }}
            />
        )
    }

    const columnsList = () => {
        let DepartmentName = 'Department_Name_EN'

        if (i18n.language == 'ar') {
            DepartmentName = 'Department_Name_AR'

        }
        return [
            {
                dataField: "Department_Code",
                caption: t('munsCode'),
                width: '200px'
            },
            {
                dataField: DepartmentName,
                caption: t('DepartmentName'),
                // width: '300px'
            },
            {
                dataField: "Active_Status_Id",
                cellRender: customizeCellCheckbox,
                caption: t('status'),
                width: '120px'
            }
        ]
    }

    const handleExportCustomization = (options: any) => {
        if (options.gridCell.rowType === 'data' && options.gridCell.column.dataField == "Active_Status_Id") {
            if (options.gridCell.value == 1) {
                options.excelCell.value = "true"
            } else {
                options.excelCell.value = "false"
            }
        }
    }

    return (
        <>
            <Row>
                <Col>
                    <GridExpress
                        allowSelectAll
                        allowView={true}
                        allowEdit={true}
                        columnsList={columnsList()}
                        dataSource={data}
                        keyExpr={"Department_Id"}
                        handleView={(data) => dispatch(triggerModalTab({ Open: true, Data: data, Type: 'view' }))}
                        handleEdit={(data) => dispatch(triggerModalTab({ Open: true, Data: data, Type: 'edit' }))}
                        allowAdd={false}
                        gridTitle={t('departments') as string}
                        groupPanelVisibility={false}
                        xlsxFileName={t('departments') as string}
                        handleWordCustomization={(options: any) => handleExportCustomization(options)}
                    >
                    </GridExpress>
                </Col>
            </Row >

            {showModal && <DepartmentsForm />}
        </>
    )
}