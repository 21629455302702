import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface ITabsInitialState {
    Data: any
}

const initialState: ITabsInitialState = {
    Data: JSON.parse(localStorage.getItem('Tabs') || '[]')
}

const tabsSlice = createSlice({
    name: "tabs",
    initialState,
    reducers: {
        triggerTabs: (state, action: PayloadAction<ITabsInitialState>) => {
            state.Data = action.payload.Data;
            localStorage.setItem('Tabs' , JSON.stringify(action.payload.Data))
        }
    }
})

export default tabsSlice.reducer
export const { triggerTabs } = tabsSlice.actions