import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { apiConfig } from "api/apiConfig";
import { triggerLoading } from "../utils/loadingSlice";
import { triggerNotification } from "../utils/notificationSlice";
import { persistor } from "store";

interface IFormValues {
    userId: number;
    token: string;
}

interface IInitialState {
    Status: boolean;
    Data: any;
}

const initialState: IInitialState = { 
    Status: false,
    Data: {}
}

export const setCurrentProfile = createAsyncThunk(
    "currentProfile/setCurrenProfile",
    async ({ userId, token }: IFormValues, { dispatch }) => {
        const data = await apiConfig.post("/API/SYSTEM/LOGIN/SET_USER_ACCOUNT_PROFILE",
            {
                User_Id: userId,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        const finalPayload = data
        if (!finalPayload.data.Status) {
            dispatch(triggerNotification({ Open: true, error: finalPayload.data.Error_AR, messages: finalPayload.data.Error_EN }))
        }
        dispatch(triggerLoading({ status: false }));
        return finalPayload;
    }
)

const currentProfileReducer = createSlice({
    name: "currentProfile",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setCurrentProfile.pending, (state) => { })
        builder.addCase(setCurrentProfile.fulfilled, (state, action) => {
            const data = {
                ...action.payload.data
            }
            state.Data = data;
            state.Status = data.Status;
        })
        builder.addCase(setCurrentProfile.rejected, (state, action) => { })
    }
})

export default currentProfileReducer.reducer;
