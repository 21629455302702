import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TabModal from 'components/TabModal'
import { Form, Row, Col } from 'antd'
import {
    getClientsList,
    getSuspensionsAttachmentList,
    downloadViolationAttachmentFile,
    cancelSuspensions,
    deleteSuspensionAttachment
} from 'api/suspensions/suspensions'
import { useAppSelector, useAppDispatch } from 'store'
import { triggerLoading } from 'features/utils/loadingSlice'
import { triggerModalTab } from 'features/utils/modalTabSlice'
import Selectfield from 'components/Selectfield'
import FilesUploadedList from 'components/FilesUploadedList'
import Textarea from "components/Textarea"
import FileUploader from 'components/FileUploader'
import Button from 'components/Button'
import { CloseOutlined } from '@ant-design/icons'
import { apiConfig } from '../../api/apiConfig'

export default function SuspensionsAdministrationForm() {

    const { t, i18n } = useTranslation()
    const [showTabForm, setShowTabForm] = useState<any>(false)
    const [showAttachmentList, setShowAttachmentList] = useState<any>(false)
    const [attachmentList, setAttachmentList] = useState<any>([])
    const [formData, setFormData] = useState<any>()
    const [clientsList, setClientsList] = useState<any>([])
    const currentUser = useAppSelector((state: any) => state.currentUser)
    const currentactiveTab = useAppSelector((state: any) => state.activeTab.Data)
    const modalStatus = useAppSelector((state: any) => state.modalTab)
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()

    useEffect(() => {
        setFormData(modalStatus?.Data)
        if (modalStatus?.Type != 'add') {
            getAttachmentList()
        }
        getClientsArr()
    }, [])

    useEffect(() => {
        if (currentactiveTab == '/manage-suspensions-form') {
            setShowTabForm(true)
            setTimeout(() => dispatch(triggerLoading({ status: false })), 1000)
        }
    }, [currentactiveTab])

    const getAttachmentList = async () => {
        await getSuspensionsAttachmentList(currentUser.Data.Data.access_token, modalStatus?.Data?.Violation_Id)
            .then(res => {
                setShowAttachmentList(res.length > 0 ? true : false)
                setAttachmentList(res)
            })
    }

    const handleFileUploader = async (data: any) => {
        let bodyFormData = new FormData(),
            at = currentUser.Data.Data.access_token,
            bodyData = { Violation_Id: modalStatus?.Data?.Violation_Id }
        if (modalStatus?.Type == 'edit') {
            bodyFormData.append("PARAMETERS", JSON.stringify(bodyData))
            bodyFormData.append('Violation_Attach_File', data?.file)
            await apiConfig.post('/API/ADMINISTRATION/VIOLATIONS/ADD_VIOLATION_ATTACHMENT',
                bodyFormData,
                {
                    headers: {
                        Authorization: `Bearer ${at}`
                    },
                    onUploadProgress: event => {
                        if (event?.loaded && event?.total) {
                            let percent = Math.floor((event?.loaded / event?.total) * 100);
                            data.onProgress({ percent: percent })
                            if (percent === 100) {
                                setTimeout(() => percent = 0, 1000);
                            }
                        }
                    }
                })
                .then((res: any) => {
                    if (res.data.Status) {
                        data.onSuccess()
                        getAttachmentList()
                    } else {
                        data.onError()
                    }
                })
        } else { }
    }

    const getClientsArr = async () => {
        let clientsList: any = [],
            at = currentUser.Data.Data.access_token,
            // url = modalStatus?.Type == 'edit'
            //     ? '/API/ADMINISTRATION/VIOLATIONS/GET_CLIENTS_PROFILES'
            //     : 'API/ADMINISTRATION/VIOLATIONS/GET_CLIENTS_PROFILES_WITHOUT_VIOLATIONS'

            url = '/API/ADMINISTRATION/VIOLATIONS/GET_CLIENTS_PROFILES'

        await getClientsList(at, url).then(res => {
            res.map((client: any) => {
                let label = "",
                    option: any = {};

                if (i18n.language === "ar") {
                    label = client?.Client_Name_AR ? client?.Client_Name_AR : "";
                    label = client?.Dealing_Type_Name_AR
                        ? label + " - " + client?.Dealing_Type_Name_AR
                        : label;
                } else {
                    label = client?.Client_Name_EN ? client?.Client_Name_EN : "";
                    label = client?.Dealing_Type_Name_EN
                        ? label + " - " + client?.Dealing_Type_Name_EN
                        : label;
                }

                label = client?.License_No ? label + " - " + client?.License_No : label;

                option.label = label;
                option.value = client?.Client_Profile_Id;

                clientsList.push(option);
                setClientsList(clientsList)
            })
        })
    }

    const handleOnDownload = async (data: any) => {
        await downloadViolationAttachmentFile(currentUser.Data.Data.access_token, data?.Violation_Attachment_File)
            .then(res => {
                let a = document.createElement("a"); // Create <a>
                a.href = "data:image/png;base64," + res.Data.File_Bytes; // Base64 
                a.download = res.Data.File_Name; // File name 
                a.click(); // Download file
            })
    }

    const handleOnDelete = async (data: any) => {
        dispatch(triggerLoading({ status: true }))
        await deleteSuspensionAttachment(
            currentUser.Data.Data.access_token,
            data?.Violation_Attachment_Id
        ).then(() => {
            getAttachmentList()
            dispatch(triggerLoading({ status: false }))
        })
    }

    const handleSubmit = async (values: any) => {
        dispatch(triggerLoading({ status: true }))
        let bodyFormData = new FormData(),
            at = currentUser.Data.Data.access_token,
            url = modalStatus?.Type == 'add' ? '/API/ADMINISTRATION/VIOLATIONS/ADD_VIOLATION'
                : '/API/ADMINISTRATION/VIOLATIONS/UPD_VIOLATION',
            bodyData = values

        await apiConfig.post(url, modalStatus?.Type == 'edit' ? bodyData : bodyFormData, {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }).catch((error) => {
            return error.response;
        }).then(() => {
            dispatch(triggerLoading({ status: false }))
            dispatch(triggerModalTab({ Open: false, Data: {} }))
        })

        // if (modalStatus?.Type == 'edit') {
        //     bodyData.Violation_Id = modalStatus?.Data?.Violation_Id
        //     bodyFormData.append("PARAMETERS", JSON.stringify(bodyData))
        //     if (suspAttachment.length > 0) {
        //         bodyFormData.append('Violation_Attach_File', suspAttachment[0]?.originFileObj)
        //         const response = await apiConfig
        //             .post('/API/ADMINISTRATION/VIOLATIONS/ADD_VIOLATION_ATTACHMENT', bodyFormData, {
        //                 headers: {
        //                     Authorization: `Bearer ${at}`,
        //                 },
        //             })
        //             .catch((error) => {
        //                 return error.response;
        //             })
        //     }
        // } else {
        //     bodyData = values
        //     bodyFormData.append("PARAMETERS", JSON.stringify(bodyData))
        //     if (suspAttachment.length > 0) {
        //         suspAttachment.forEach((file: any, index: any) => {
        //             console.log(file.originFileObj)
        //             bodyFormData.append(`Violation_Attach_File${index + 1}`, file.originFileObj)
        //         });
        //     }
        // }
    }

    const handleOnCancelSuspensions = async () => {
        dispatch(triggerLoading({ status: true }))
        await cancelSuspensions(
            currentUser.Data.Data.access_token,
            modalStatus?.Data?.Violation_Id
        ).then(() => {
            dispatch(triggerLoading({ status: false }))
            dispatch(triggerModalTab({ Open: false, Data: {} }))
        })
    }

    const myForm = (
        <Form
            form={form}
            style={{ width: '100%' }}
            onFinish={handleSubmit}
            initialValues={{
                "Client_Profile_Id": modalStatus?.Data?.Client_Profile_Id,
                "Violation_Notes": modalStatus?.Data?.Violation_Notes,
                "Violation_Attach_File": modalStatus?.Data?.Violation_Attach_File,
            }}
        >
            <Row gutter={[12, 0]}>
                <Col xs={24} md={12}>
                    <Row gutter={[12, 0]}>
                        <Col xs={24} md={24}>
                            <Form.Item name="Client_Profile_Id">
                                <Selectfield
                                    label={t('clientsList') as string}
                                    options={clientsList}
                                    // disabled={modalStatus.Type == 'view' || modalStatus.Type == 'edit'}
                                    disabled={modalStatus.Type != 'add' && true}
                                    defaultValue={modalStatus?.Data?.Client_Profile_Id}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            {modalStatus.Type != 'view' &&
                                <Form.Item
                                    name="Violation_Attach_File"
                                // rules={[{ required: true, message: t('error.field_required') as string }]}
                                >
                                    <FileUploader
                                        label={t('attachments') as string}
                                        formfield={true}
                                        maxCount={modalStatus.Type == 'add' ? 5 : 1}
                                        multiple={modalStatus.Type == 'add' ? true : false}
                                        handleUpload={(data: any) => handleFileUploader(data)}
                                    />
                                </Form.Item>
                            }

                            {modalStatus.Type != 'add' && showAttachmentList &&
                                <FilesUploadedList
                                    label={t('attachmentList') as string}
                                    attachmentList={attachmentList}
                                    fileName={'Violation_Attachment_File_Name'}
                                    canDownload={true}
                                    canDelete={modalStatus.Type != 'view' ? true : false}
                                    onDownload={(data: any) => handleOnDownload(data)}
                                    onDelete={(data: any) => handleOnDelete(data)}
                                />
                            }
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} md={12}>
                    <Row gutter={[12, 0]}>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="Violation_Notes"
                                rules={[{ required: true, message: t('error.field_required') as string }]}
                            >
                                <Textarea
                                    label={t('notes') as string}
                                    disabled={modalStatus.Type == 'view'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {!modalStatus?.Open &&
                <Row style={{ justifyContent: 'end' }}>
                    <Col>
                        <Button btnsize='small' title={t('save') as string} onClick={() => form.submit()} />
                    </Col>
                    {modalStatus?.Data?.Violations_Status_Name_EN != 'Canceled' && (
                        <Col>
                            <Button
                                btncolor="secondary"
                                title={t('cancelSuspension') as string}
                                icon={<CloseOutlined />}
                                onClick={() => handleOnCancelSuspensions()}
                            />
                        </Col>
                    )}
                </Row>
            }
        </Form>
    )

    const bonusBtn = {
        btncolor: "secondary",
        title: t('cancelSuspension') as string,
        icon: (<CloseOutlined />),
        onClick: () => handleOnCancelSuspensions()
    }

    return (
        <>
            <TabModal
                children={myForm}
                title={'مدخلات الايقاف / Suspension Form'}
                link={'/manage-suspensions-form'}
                formData={formData}
                onOk={() => { form.submit() }}
                bonusBtn={modalStatus?.Type == 'edit' && modalStatus?.Data?.Violations_Status_Name_EN != 'Canceled' ? bonusBtn : {}}
            />

            {showTabForm && myForm}
        </>
    )
}