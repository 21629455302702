import { useState, useEffect } from 'react'
import {
    checkWebNotifications,
    getNotificationDetails,
    getAllWebNotification,
    getUnreadWebNotification,
    deleteNotification,
    deleteAllNotifications,
    deleteReadedNotifications,
} from 'api/system/notifications'
import { useAppSelector, useAppDispatch } from 'store'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DeleteFilled } from '@ant-design/icons'
import IconButton from 'components/IconButton'
import { triggerLoading } from 'features/utils/loadingSlice'
import ChatIcon from 'app/images/Chat_Icon.svg'
import moment from 'moment'

const NotifDropdown = styled.div<{}>`
    height: 100%;
    display: flex;
    flex-direction: column;

    #noNotification {
        color: ${props => props.theme.antd.colorInfo};
        font-weight: 600!important;
        fontSize: 16px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: inherit;
    }
`
const NotifiListHeader = styled.div<{ lang: string }>`
    background: ${props => props.theme.antd.colorInfo};
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 2rem;
    border-radius: ${props => props.lang == 'ar' ? '0 15px 0 0' : '15px 0 0 0'};

    p {
        line-height: normal;
        fontSize: 16px;
        font-weight: 600;
        margin-bottom: 0;
        padding-inline-end: 20px;
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
`
const NotificationsList = styled.div<{}>`
    overflow: scroll;
    cursor: pointer;

    p {
        line-height: normal;
        margin-bottom: 0;
        font-weight: normal!important;
    }
    
    #title {
        color: ${props => props.theme.antd.colorInfo};
        font-weight: 600!important;
        padding-inline-start: 5px;
    }

    #time {
        fontSize: 12px;
        direction: initial;
    }

    div {
        padding: 10px;
    }

    #notifBody {
        padding-inline-start: 4px;
    }

   .reading-notify {
    background-color: #337ab747;
    padding: 10px;
    // border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
   }

    #footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        padding-inline-start: 4px;    
    }

    #deleteIcon {
        color: ${props => props.theme.antd.colorInfo};
    }
`
const Badge = styled.div<{}>`
  background: ${props => props.theme.antd.colorPrimary};
  width: 100px;
//   width: 85px;
  height: 35px;
  border-radius: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  fontSize: 12px;
  font-weight: 600;
`
const NotifListFooter = styled.div<{}>`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: .5rem;
    border-top: 1px solid ${props => props.theme.antd.colorInfo};
    color: ${props => props.theme.antd.colorInfo};
    font-weight: 600;
`


export default function Notifications(props: any) {
    const { t, i18n } = useTranslation()
    const currentUser = useAppSelector(state => state.currentUser)
    const dispatch = useAppDispatch()
    const [notfList, setnotfList] = useState([])
    const [notfBody, setnotfBody] = useState(null)
    const [activeNotif, setActiveNotif] = useState(null)
    const [nofCount, setNofCount] = useState({
        New_Notifications: 0,
        Unread_Notifications: 0,
        All_Notifications: 0,
    })

    useEffect(() => {
        const checkNotification = async () => {
            await checkWebNotifications(currentUser.Data.Data.access_token)
                .then((res) => setNofCount(res))
        }
        checkNotification()
        getWebNotification()
    }, [currentUser])

    useEffect(() => {
        props.onNewNotifications(nofCount.Unread_Notifications)
    }, [nofCount])

    const getWebNotification = async () => {
        await getAllWebNotification(currentUser.Data.Data.access_token)
            .then((res) => setnotfList(res))
    }

    const getDetails = async (notifId: number) => {
        setnotfBody(null)
        await getNotificationDetails(notifId, currentUser.Data.Data.access_token)
            .then((res) => setnotfBody(i18n.language == 'ar' ? res.Notification_Body_AR : res.Notification_Body_EN))
    }

    const deleteAll = async () => {
        dispatch(triggerLoading({ status: true }))
        await deleteAllNotifications(currentUser.Data.Data.access_token)
            .then(() => {
                dispatch(triggerLoading({ status: false }))
                getWebNotification()
            })
    }

    const deleteReaded = async () => {
        dispatch(triggerLoading({ status: true }))
        await deleteReadedNotifications(currentUser.Data.Data.access_token)
            .then(() => {
                dispatch(triggerLoading({ status: false }))
                getWebNotification()
            })
    }

    const onDeleteNotification = async (notifId: number) => {
        dispatch(triggerLoading({ status: true }))
        await deleteNotification(notifId, currentUser.Data.Data.access_token)
            .then(() => {
                dispatch(triggerLoading({ status: false }))
                getWebNotification()
            })
    }

    return (
        <NotifDropdown>
            <NotifiListHeader lang={i18n.language}>
                <p>{t('notifications')}</p>
                <Badge>{nofCount.Unread_Notifications} {t('unread')}</Badge>
            </NotifiListHeader>

            <NotificationsList>
                {notfList && notfList?.map((n: any, i: any) => (
                    <div
                        key={i}
                        onClick={() => {
                            setActiveNotif(n?.Notification_Web_Id)
                            getDetails(n?.Notification_Web_Id)
                        }}
                        className={`${activeNotif === n?.Notification_Web_Id && 'reading-notify'}`}
                    >
                        <span style={{ display: 'flex', alignItems: 'center', paddingInlineStart: 0 }}>
                            <img src={ChatIcon} width={20} />
                            <p id='title'>{i18n.language == 'ar' ? n?.Notification_Title_AR : n?.Notification_Title_EN}</p>
                        </span>
                        <p id={'notifBody'}>{activeNotif == n?.Notification_Web_Id ? notfBody : null}</p>
                        <span id='footer'>
                            <p id='time'>
                                {moment(n?.Notification_Time).lang("en").format(`hh:mm a`) + " "} -
                                {" " + moment(n?.Notification_Time).lang("en").format(`YYYY-MM-DD`)}
                            </p>
                            <IconButton   btncolor="error"
                            icon="delete" bgColor="transparent" onClick={() => onDeleteNotification(n?.Notification_Web_Id)} />
                        </span>
                    </div>
                ))}
            </NotificationsList>

            {notfList.length <= 0 && <p id='noNotification'>{t('noNotification')}</p>}

            {notfList.length > 0 ?
                <NotifListFooter>
                    <span
                        onClick={() => deleteReaded()}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <IconButton
                            btncolor="error"
                            icon="delete"
                            width='30'
                            height='30'
                        />
                        <span>{t('deleteReaded')}</span>
                    </span>

                    <span
                        onClick={() => deleteAll()}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <IconButton
                            btncolor="error"
                            icon="delete"
                            width='30'
                            height='30'
                        />
                        <span>{t('deleteAll')}</span>
                    </span>
                </NotifListFooter>
                : null}

        </NotifDropdown >
    )
}