import { useEffect, useState, useRef } from 'react'
import TabModal from 'components/TabModal'
import { useAppSelector, useAppDispatch } from 'store'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'
import {
    getDepUsersList,
    addMission,
    updateMission,
    cancelMission,
    getMissionAttachmentList,
    deleteMissionAttachment
} from 'api/tasks/tasks'
import Textfield from "components/Textfield"
import Textarea from "components/Textarea"
import Steps from "components/Steps"
import { triggerLoading } from 'features/utils/loadingSlice'
import { triggerModalTab } from 'features/utils/modalTabSlice'
import Selectfield from 'components/Selectfield'
import FileUploader from 'components/FileUploader'
import Button from 'components/Button'
import { apiConfig } from '../../../api/apiConfig'
import { CloseOutlined } from '@ant-design/icons'
import FilesUploadedList from 'components/FilesUploadedList'

export default function TasksForm() {

    const { t, i18n } = useTranslation()
    const [formData, setFormData] = useState<any>()
    const [activeStep, setActiveStep] = useState<any>(0)
    const [missionId, setMissionId] = useState<any>(null)
    const [showTabForm, setShowTabForm] = useState<any>(false)
    const [usersList, setUsersList] = useState<any>([])
    const [attachmentList, setAttachmentList] = useState<any>([])
    const modalStatus = useAppSelector(state => state.modalTab)
    const currentUser = useAppSelector((state: any) => state.currentUser)
    const currentactiveTab = useAppSelector(state => state.activeTab.Data)
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()

    useEffect(() => {
        setFormData(modalStatus?.Data)
        getUsersList()
        setMissionId(modalStatus?.Data?.Mission_Id)
    }, [])

    useEffect(() => {
        if (modalStatus?.Type != 'add' && activeStep == 1) {
            getAttachmentList()
        }
    }, [activeStep])

    useEffect(() => {
        if (currentactiveTab == '/tasks-form') {
            setShowTabForm(true)
            setTimeout(() => dispatch(triggerLoading({ status: false })), 1000)
        }
    }, [currentactiveTab])

    const getUsersList = async () => {
        let UsersArr: any = [],
            at = currentUser.Data.Data.access_token
        await getDepUsersList(at)
            .then((res: any) => {
                if (res?.Status) {
                    res?.Data.map((item: any) => {
                        let label = i18n.language === 'ar' ? item.Department_Job_Name_AR : item.Department_Job_Name_EN
                        UsersArr.push({ value: item.User_Id, label })
                    })
                    setUsersList(UsersArr)
                }
            })
    }

    const getAttachmentList = async () => {
        await getMissionAttachmentList(currentUser.Data.Data.access_token, modalStatus?.Data?.Mission_Id)
            .then(res => {
                setAttachmentList(res)
            })
    }

    const handleFileUploader = async (data: any) => {
        let bodyFormData = new FormData(),
            at = currentUser.Data.Data.access_token,
            bodyData = { mission_Id: missionId }
        bodyFormData.append("PARAMETERS", JSON.stringify(bodyData))
        bodyFormData.append('Mission_Attach_File', data?.file)
        await apiConfig.post('/API/ADMINISTRATION/MISSIONS/ADD_MISSION_ATTACHMENT',
            bodyFormData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
                onUploadProgress: event => {
                    if (event?.loaded && event?.total) {
                        let percent = Math.floor((event?.loaded / event?.total) * 100);
                        data.onProgress({ percent: percent })
                        if (percent === 100) {
                            setTimeout(() => percent = 0, 1000);
                        }
                    }
                }
            })
            .then((res: any) => {
                if (res.data.Status) {
                    data.onSuccess()
                    getAttachmentList()
                } else {
                    data.onError()
                }
            })
    }

    const handleOnView = async (data: any) => {
        window.open(data.Mission_Attach_File, "_blank", "noopener")
    }

    const handleOnDelete = async (data: any) => {
        dispatch(triggerLoading({ status: true }))
        await deleteMissionAttachment(
            currentUser.Data.Data.access_token,
            data?.Mission_Attach_Id
        ).then(() => {
            getAttachmentList()
            dispatch(triggerLoading({ status: false }))
        })
    }

    const handleSubmit = async (values: any) => {
        dispatch(triggerLoading({ status: true }))
        if (activeStep == 0) {
            if (modalStatus?.Type == 'add') {
                await addMission(
                    currentUser.Data.Data.access_token,
                    values.Mission_to_User_Id,
                    values.Mission_Title,
                    values.Mission_Description,
                ).then(res => {
                    setMissionId(res.Data.Mission_Id)
                    dispatch(triggerLoading({ status: false }))
                })
            } else {
                await updateMission(
                    currentUser.Data.Data.access_token,
                    modalStatus?.Data?.Mission_Id,
                    values.Mission_to_User_Id,
                    values.Mission_Title,
                    values.Mission_Description,
                ).then(res => {
                    setMissionId(res.Data.Mission_Id)
                    dispatch(triggerLoading({ status: false }))
                })
            }
        }
    }

    const handleonCancelMission = async () => {
        dispatch(triggerLoading({ status: true }))
        await cancelMission(
            currentUser.Data.Data.access_token,
            modalStatus?.Data?.Mission_Id
        ).then(() => {
            dispatch(triggerLoading({ status: false }))
            dispatch(triggerModalTab({ Open: false, Data: {} }))
        })
    }

    const mainTaskDetails = (
        <Row gutter={[12, 0]}>
            <Col xs={24} md={8}>
                <Form.Item
                    name="Mission_to_User_Id"
                    rules={[{ required: true, message: t('error.field_required') as string }]}
                >
                    <Selectfield
                        label={t('missionToUser') as string}
                        options={usersList}
                        // disabled={modalStatus.Type == 'view'}
                        defaultValue={modalStatus?.Data?.Mission_to_User_Id}
                        showSearch={true}
                    />
                </Form.Item>
            </Col>
            <Col xs={24} md={16}>
                <Form.Item
                    name="Mission_Title"
                    rules={[{ required: true, message: t('error.field_required') as string }]}
                >
                    <Textfield
                        label={t('missionTitle') as string}
                    // disabled={modalStatus.Type == 'view'}
                    />
                </Form.Item>
            </Col>
            <Col xs={24} md={24}>
                <Form.Item
                    name="Mission_Description"
                    rules={[{ required: true, message: t('error.field_required') as string }]}
                >
                    <Textarea
                        label={t('missionDesc') as string}
                    // disabled={modalStatus.Type == 'view'}
                    />
                </Form.Item>
            </Col>
        </Row>
    )

    const taskAttachments = (
        <Row gutter={[12, 0]}>
            <Col xs={24} md={10}>
                <Form.Item
                    name="Mission_Attach_File"
                    rules={[{
                        required: attachmentList.length <= 0 ? true : false,
                        message: t('error.field_required') as string
                    }]}
                >
                    <FileUploader
                        label={t('taskAttachments') as string}
                        formfield={true}
                        disabled={modalStatus.Type == 'view'}
                        handleUpload={(data: any) => handleFileUploader(data)}
                        multiple={true}
                    />
                </Form.Item>

                {modalStatus.Type != 'add' && attachmentList.length > 0 &&
                    <FilesUploadedList
                        label={t('attachmentList') as string}
                        attachmentList={attachmentList}
                        fileName='Mission_Attach_File_Name'
                        canView={true}
                        canDelete={modalStatus.Type != 'view' ? true : false}
                        onView={(data: any) => handleOnView(data)}
                        onDelete={(data: any) => handleOnDelete(data)}
                    />
                }
            </Col>
        </Row>
    )

    const myForm = (
        <Form
            form={form}
            style={{ width: '100%', height: '100%' }}
            onFinish={handleSubmit}
            initialValues={{
                "Mission_to_User_Id": modalStatus?.Data?.Mission_to_User_Id,
                "Mission_Title": modalStatus?.Data?.Mission_Title,
                "Mission_Description": modalStatus?.Data?.Mission_Description
            }}
        >
            <Row gutter={[12, 0]}>
                <Col xs={24} md={24}>
                    <Steps
                        onStepChange={(current: any) => setActiveStep(current)}
                        disabledNext={missionId == null ? true : false}
                        width='25%'
                        steps={[
                            {
                                title: `${t('mainTaskDetails') as String}`,
                                content: mainTaskDetails
                            },
                            {
                                title: `${t('taskAttachments') as string}`,
                                content: taskAttachments,
                                disabled: missionId == null ? true : false
                            }
                        ]}
                    />
                </Col>
            </Row>

            <Row style={{ justifyContent: 'end' }}>
                {!modalStatus?.Open &&
                    <Col>
                        <Button
                            title={t('save') as string}
                            onClick={() => form.submit()}
                            btnsize='small'
                        />
                    </Col>
                }
                {!modalStatus?.Open && activeStep == 0 &&
                    <Col>
                        <Button
                            btncolor="secondary"
                            title={t('cancelTask') as string}
                            icon={<CloseOutlined />}
                            onClick={() => handleonCancelMission()}
                        />
                    </Col>
                }
            </Row>
        </Form>
    )

    const bonusBtn = {
        btncolor: "secondary",
        title: t('cancelTask') as string,
        icon: (<CloseOutlined />),
        onClick: () => handleonCancelMission()
    }

    return (
        <>
            <TabModal
                children={myForm}
                title={'مدخلات التكليفات / Assigned TasksForm Form'}
                link={'/tasks-form'}
                formData={formData}
                onOk={() => { form.submit() }}
                bonusBtn={modalStatus?.Type == 'edit' && activeStep == 0 ? bonusBtn : {}}
                showSave={activeStep == 0 ? true : false}
            />

            {showTabForm && myForm}
        </>
    )
}