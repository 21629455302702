import { createApi } from '@reduxjs/toolkit/query/react'
import { apiConfig } from './apiConfig'
import type { AxiosRequestConfig, AxiosError } from 'axios'
import type { BaseQueryFn } from '@reduxjs/toolkit/query'
// import { postForm } from "./apiConfig"

const axiosBaseQuery =
	(
		{ baseUrl }: { baseUrl: string } = { baseUrl: '/' }
	): BaseQueryFn<
		{
			url: string
			method: AxiosRequestConfig['method']
			data?: AxiosRequestConfig['data']
			params?: AxiosRequestConfig['params']
		},
		unknown,
		unknown
	> =>
		async ({ url, method, data, params }) => {

			try {
				// const result = await postForm(url, params);
				const result = await apiConfig({ url, method, params });
				return { data: result.data };
			} catch (axiosError) {
				let err = axiosError as AxiosError
				return {
					error: {
						status: err.response?.status,
						data: err.response?.data || err.message,
					},
				};
			}
		};

export const axiosBaseAPI = createApi({
	baseQuery: axiosBaseQuery(),
	endpoints: () => ({}),
});