import React, { PropsWithChildren, useEffect, useRef } from "react"
import { Modal as ModalAntds, ModalProps } from "antd"
import styled from 'styled-components'
import tabBG from 'app/images/SystemBG.png'
import { useTranslation } from "react-i18next"

const prefixCls = "ant-modal"

const AntdModal = styled(ModalAntds) <{ fullScreen: boolean, height: string, width: string, lang: string }>`
  .${prefixCls}-content {
    position: fixed;
    padding: 0;
    display: flex;
    flex-direction: column;
    z-index: 1;
    ${props => props.fullScreen ?
    `top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 0px;`
    :
    `left: 50%;
      transform: translate(-50%, -50%);
      height: ${props.height};
      width: ${props.width};`
  }
  }

  .${prefixCls}-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: whitesmoke;
    padding: 10px;
    border-radius: 5px;
  }

  .${prefixCls}-body {
    overflow-y: auto;
    overflow-x: hidden;
    margin: 1rem 0;
    padding: 0 20px;
    margin: 0 5px;
  }

  .${prefixCls}-footer {
    margin-top: auto;
    padding: 20px;
  }

  // .${prefixCls}-content::after {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   background: url(${tabBG});
  //   background-repeat: no-repeat;
  //   background-size: 50%;
  //   background-position: bottom left;
  //   transform: ${props => props.lang == 'ar' ? 'none' : 'scaleX(-1)'};
  //   z-index: -1;
  // }
`
type CustomModalProps = {
  fullScreen?: boolean
  height?: string
  width?: string
}

const Modal: React.FC<ModalProps & PropsWithChildren & CustomModalProps> = ({
  children,
  fullScreen = false,
  height = '',
  width = '',
  ...rest
}) => {

  const { t, i18n } = useTranslation()

  return (
    <>
      <AntdModal
        fullScreen={fullScreen}
        height={height}
        width={width}
        style={{ top: '50%' }}
        lang={i18n.language}
        {...rest}
      >
        {children}
      </AntdModal>
    </>
  );
};

export default Modal;
