import { axiosBaseAPI } from "api/axiosBaseQuery";

function transformResponse(response: any) {
    if (response?.Data?.Data) {
        return [...response?.Data?.Data];
    } else if (response?.Data) {
        return response?.Data;
    }
    return response;
}

axiosBaseAPI.enhanceEndpoints({
    addTagTypes: ['departmentsList', 'departmentsSectorsList']
});

export const departmentsAPI = axiosBaseAPI.injectEndpoints({
    endpoints: (builder) => ({
        getDepartmentsList: builder.query({
            query: () => ({
                url: '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS/GET_DEPARTMENT_LIST',
                method: 'POST',
            }),
            transformResponse: transformResponse,
            keepUnusedDataFor: 60,
            // @ts-expect-error
            providesTags: ['departmentsList']
        }),
        getDepartmentsSectorsList: builder.query({
            query: (params) => {
                return {
                    url: '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTSSECTORS/GET_DEPARTMENT_SECTORS_LIST',
                    method: 'POST',
                    params,
                }
            },
            transformResponse: transformResponse,
            keepUnusedDataFor: 5,
            // @ts-expect-error
            providesTags: ['departmentsSectorsList']
        })
    })
});

export const {
    useGetDepartmentsListQuery,
    useLazyGetDepartmentsListQuery,
    useGetDepartmentsSectorsListQuery,
    useLazyGetDepartmentsSectorsListQuery,
} = departmentsAPI;
