import React, { useState, useEffect, ReactNode } from 'react';
import { Button as AntdButton } from 'antd';
import styled from 'styled-components';

const StyledButton = styled(AntdButton) <{ btncolor: string, btnsize: string, disabledtype: string }>`
  border-radius: 10px;
  border: none!important;
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 400;
  fontSize: 16px;
  margin: 5px;
  color: #fff!important;
  &:hover {
    color: #fff!important;
  }

  // With Icon
  ${props => props.icon && `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
  
  // Size
  ${props => props.btnsize == 'default' ? `
    width: 150px;
    height: 45px;`
    : props.btnsize == 'large' ? `
    width: 158px;
    height: 58px;`
      : props.btnsize == 'small' ? `
    width: 100px;
    height: 45px;`
        : null}

  // Color
  ${props => props.btncolor == 'primary' && `
    background: ${props.theme.antd.colorPrimary}!important; 
  `}
  ${props => props.btncolor == 'info' && `
    background: ${props.theme.antd.colorInfo}!important; 
  `}
  ${props => props.btncolor == 'success' && `
    background: ${props.theme.antd.colorSuccess}!important; 
  `}
  ${props => props.btncolor == 'danger' && `
    background: #F16430!important; 
  `}
  ${props => props.btncolor == 'secondary' && `
    background: #7A86A1!important; 
  `}
  ${props => props.btncolor == 'white' && `
    background: #fff!important; 
    border: 1px solid #d9d9d9!important; 
    box-shadow: 0 2px 0 rgba(0,0,0,.02)!important; 
    color: rgba(0, 0, 0, 0.88)!important; 
    &:hover {
      color: rgba(0, 0, 0, 0.88)!important; 
    }
  `}
  ${props => props.disabled && `
    opacity: .4;
  `}
`

type CustomButtonProps = {
  title?: string,
  href?: string,
  target?: string
  // Option to fit button width to its parent width
  block?: boolean,
  disabled?: boolean,
  loading?: boolean | { delay: number },
  onClick?: () => void,
  btncolor?: 'primary' | 'secondary' | 'success' | 'danger' | 'info' | 'white',
  btnsize?: 'small' | 'default' | 'large',
  icon?: ReactNode
  disabledtype?: 'default' | 'outline'
}

export default function Button({
  title,
  href,
  target,
  block = false,
  disabled = false,
  loading = false,
  btncolor = 'primary',
  onClick = () => { },
  btnsize = 'default',
  icon,
  disabledtype = 'default'
}: CustomButtonProps) {
  return (
    <StyledButton
      href={href}
      target={target}
      block={block}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      btnsize={btnsize}
      btncolor={btncolor}
      icon={icon}
      disabledtype={disabledtype}
    >
      {title}
    </StyledButton>
  )
}