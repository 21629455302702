import { useEffect, useState } from 'react'
import TabModal from 'components/TabModal'
import { useAppSelector, useAppDispatch } from 'store'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'
import {
    addDepartmentSection,
    updateDepartmentSection,
    getDepartmentSectionSectorsDropdown
} from 'api/departments/departments'
import Textfield from "components/Textfield"
import { triggerLoading } from 'features/utils/loadingSlice'
import { triggerModalTab } from 'features/utils/modalTabSlice'
import Switch from 'components/Switch'
import Selectfield from 'components/Selectfield'
import Button from 'components/Button'

export default function SectionsForm() {

    const { t, i18n } = useTranslation()
    const [formData, setFormData] = useState<any>()
    const [isChecked, setIsChecked] = useState<any>()
    const [showTabForm, setShowTabForm] = useState<any>(false)
    const [administrationsList, setAdministrationsList] = useState<any>([])
    const modalStatus = useAppSelector(state => state.modalTab)
    const currentUser = useAppSelector((state: any) => state.currentUser)
    const currentactiveTab = useAppSelector(state => state.activeTab.Data)
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()

    useEffect(() => {
        setFormData(modalStatus?.Data)
        setIsChecked(modalStatus.Type == 'add' ? 1 : modalStatus?.Data?.Active_Status_Id)
        getDepartmentSectors()
    }, [])

    useEffect(() => {
        if (currentactiveTab == '/department-sections-form') {
            setShowTabForm(true)
            setTimeout(() => dispatch(triggerLoading({ status: false })), 1000)
        }
    }, [currentactiveTab])

    const getDepartmentSectors = async () => {
        let departmentSectorsArr: any = [],
            departmentId = currentUser?.Data?.Data?.Department_Id,
            at = currentUser.Data.access_token
        await getDepartmentSectionSectorsDropdown(at, departmentId)
            .then(res => {
                if (res?.Status) {
                    res?.Data.map((item: any) => {
                        let label = i18n.language === 'ar' ? item.Record_Name_AR : item.Record_Name_EN
                        departmentSectorsArr.push({ value: item.Record_Id, label })
                    })
                    setAdministrationsList(departmentSectorsArr)
                }
            })
    }

    const handleSubmit = async (values: any) => {
        dispatch(triggerLoading({ status: true }))
        if (modalStatus?.Type == 'add') {
            await addDepartmentSection(
                currentUser.Data.Data.access_token,
                modalStatus?.Data?.Department_Id,
                values.Department_Section_Name_AR,
                values.Department_Section_Name_EN,
                values.Department_Section_Description_AR,
                values.Department_Section_Description_EN,
                values.Department_Section_Phone,
                values.Active_Status_Id ? 1 : 0,
                values.Department_Section_Code,
                values.Department_Sector_Id
            ).then(() => {
                dispatch(triggerLoading({ status: false }))
                dispatch(triggerModalTab({ Open: false, Data: {} }))
            })
        } else {
            await updateDepartmentSection(
                currentUser.Data.Data.access_token,
                modalStatus?.Data?.Department_Section_Id,
                values.Department_Section_Name_AR,
                values.Department_Section_Name_EN,
                values.Department_Section_Description_AR,
                values.Department_Section_Description_EN,
                values.Department_Section_Phone,
                values.Active_Status_Id ? 1 : 0,
                values.Department_Section_Code,
                values.Department_Sector_Id
            ).then(() => {
                dispatch(triggerLoading({ status: false }))
                dispatch(triggerModalTab({ Open: false, Data: {} }))
            })
        }
    }

    const myForm = (
        <Form
            form={form}
            style={{ width: '100%' }}
            onFinish={handleSubmit}
            initialValues={{
                "Department_Section_Name_AR": modalStatus?.Data?.Department_Section_Name_AR,
                "Department_Section_Name_EN": modalStatus?.Data?.Department_Section_Name_EN,
                "Department_Section_Description_AR": modalStatus?.Data?.Department_Section_Description_AR,
                "Department_Section_Description_EN": modalStatus?.Data?.Department_Section_Description_EN,
                "Department_Section_Phone": modalStatus?.Data?.Department_Section_Phone,
                "Active_Status_Id": isChecked,
                "Department_Section_Code": modalStatus?.Data?.Department_Section_Code,
                "Department_Sector_Id": modalStatus?.Data?.Department_Sector_Id
            }}
        >
            <Row gutter={[12, 0]}>
                <Col xs={24} md={3}>
                    <Form.Item name="Department_Sector_Id">
                        <Textfield
                            label={t('sectionNo') as string}
                            disabled={true}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item
                        name="Department_Section_Code"
                        rules={[{ required: true, message: t('error.field_required') as string }]}
                    >
                        <Textfield
                            label={t('sectionCode') as string}
                            disabled={modalStatus.Type == 'view'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={3} style={{ textAlign: 'center' }}>
                    <Form.Item name="Active_Status_Id">
                        <Switch
                            label={t('status') as string}
                            disabled={modalStatus.Type == 'view'}
                            checked={isChecked}
                            onChange={(state) => setIsChecked(state == true ? 1 : 0)}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="Department_Sector_Id"
                        rules={[{ required: true, message: t('error.field_required') as string }]}
                    >
                        <Selectfield
                            label={t('municipalAdministrations') as string}
                            options={administrationsList}
                            disabled={modalStatus.Type == 'view'}
                            defaultValue={modalStatus?.Data?.Department_Sector_Id}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="Department_Section_Name_AR"
                        rules={[{ required: true, message: t('error.field_required') as string }]}
                    >
                        <Textfield
                            label={t('sectionNameAR') as string}
                            disabled={modalStatus.Type == 'view'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="Department_Section_Name_EN"
                        rules={[{ required: true, message: t('error.field_required') as string }]}
                    >
                        <Textfield
                            label={t('sectionNameEN') as string}
                            disabled={modalStatus.Type == 'view'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="Department_Section_Description_AR"
                        rules={[{ required: true, message: t('error.field_required') as string }]}
                    >
                        <Textfield
                            label={t('sectionDescAR') as string}
                            disabled={modalStatus.Type == 'view'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="Department_Section_Description_EN"
                        rules={[{ required: true, message: t('error.field_required') as string }]}
                    >
                        <Textfield
                            label={t('sectionDescEN') as string}
                            disabled={modalStatus.Type == 'view'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="Department_Section_Phone"
                        rules={[{ required: true, message: t('error.field_required') as string }]}
                    >
                        <Textfield
                            label={t('telephone') as string}
                            disabled={modalStatus.Type == 'view'}
                        />
                    </Form.Item>
                </Col>
            </Row>

            {!modalStatus?.Open &&
                <Row style={{ justifyContent: 'end' }}>
                    <Col>
                        <Button title={t('save') as string} onClick={() => form.submit()} />
                    </Col>
                </Row>
            }
        </Form>
    )

    return (
        <>
            <TabModal
                children={myForm}
                title={'مدخلات اقسام البلدية / Municipal Sections Form'}
                link={'/department-sections-form'}
                formData={formData}
                onOk={() => { form.submit() }}
            />

            {showTabForm && myForm}
        </>
    )
}