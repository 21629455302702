import React, { useState } from 'react'
import {
	GoogleMap,
	LoadScript,
	MarkerF,
	LoadScriptProps,
	GoogleMapProps,
	MarkerProps,
	// MarkerClustererF,
	MarkerClusterer,
	useJsApiLoader,
	StandaloneSearchBox,
} from '@react-google-maps/api'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const MAP_STYLES = [
	{
		"featureType": "all",
		"elementType": "labels.text",
		"stylers": [
			{
				"color": "#878787"
			}
		]
	},
	{
		"featureType": "all",
		"elementType": "labels.text.stroke",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "landscape",
		"elementType": "all",
		"stylers": [
			{
				"color": "#f9f5ed"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "all",
		"stylers": [
			{
				"color": "#f5f5f5"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "geometry.stroke",
		"stylers": [
			{
				"color": "#c9c9c9"
			}
		]
	},
	{
		"featureType": "water",
		"elementType": "all",
		"stylers": [
			{
				"color": "#aee0f4"
			}
		]
	}
]
const MAP_STYLES2 = [
	{
		"featureType": "all",
		"elementType": "geometry",
		"stylers": [
			{
				"saturation": "0"
			},
			{
				"lightness": "0"
			},
			{
				"visibility": "on"
			},
			{
				"gamma": "1"
			}
		]
	},
	{
		"featureType": "all",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"saturation": 36
			},
			{
				"color": "#e0e9f2"
			},
			{
				"lightness": 40
			}
		]
	},
	{
		"featureType": "all",
		"elementType": "labels.text.stroke",
		"stylers": [
			{
				"visibility": "off"
			},
			{
				"color": "#000000"
			},
			{
				"lightness": "0"
			}
		]
	},
	{
		"featureType": "all",
		"elementType": "labels.icon",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "administrative",
		"elementType": "labels.text",
		"stylers": [
			{
				"visibility": "simplified"
			},
			{
				"saturation": "-100"
			},
			{
				"lightness": "-43"
			}
		]
	},
	{
		"featureType": "landscape",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#5a5858"
			},
			{
				"lightness": "0"
			},
			{
				"visibility": "on"
			},
			{
				"weight": "1.00"
			},
			{
				"gamma": "1"
			},
			{
				"saturation": "-54"
			}
		]
	},
	{
		"featureType": "poi",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#6a6969"
			},
			{
				"lightness": "0"
			}
		]
	},
	{
		"featureType": "poi.attraction",
		"elementType": "geometry",
		"stylers": [
			{
				"visibility": "on"
			}
		]
	},
	{
		"featureType": "poi.attraction",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "poi.attraction",
		"elementType": "geometry.stroke",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "poi.business",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"visibility": "on"
			}
		]
	},
	{
		"featureType": "poi.park",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#698577"
			}
		]
	},
	{
		"featureType": "poi.park",
		"elementType": "labels",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "geometry.stroke",
		"stylers": [
			{
				"visibility": "simplified"
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "labels",
		"stylers": [
			{
				"invert_lightness": true
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "labels.icon",
		"stylers": [
			{
				"visibility": "off"
			},
			{
				"saturation": "-100"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"lightness": "0"
			},
			{
				"color": "#474747"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "geometry.stroke",
		"stylers": [
			{
				"color": "#000000"
			},
			{
				"lightness": "0"
			},
			{
				"weight": 0.2
			},
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"invert_lightness": true
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "labels.icon",
		"stylers": [
			{
				"visibility": "off"
			},
			{
				"saturation": "-100"
			}
		]
	},
	{
		"featureType": "road.highway.controlled_access",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#a1a1a1"
			},
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "road.highway.controlled_access",
		"elementType": "labels",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "road.arterial",
		"elementType": "geometry",
		"stylers": [
			{
				"lightness": "0"
			},
			{
				"visibility": "on"
			},
			{
				"color": "#474747"
			}
		]
	},
	{
		"featureType": "road.arterial",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#454545"
			}
		]
	},
	{
		"featureType": "road.arterial",
		"elementType": "labels",
		"stylers": [
			{
				"saturation": "-80"
			},
			{
				"lightness": "42"
			},
			{
				"color": "#989898"
			}
		]
	},
	{
		"featureType": "road.arterial",
		"elementType": "labels.icon",
		"stylers": [
			{
				"saturation": "-100"
			}
		]
	},
	{
		"featureType": "road.local",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#474747"
			},
			{
				"lightness": "0"
			}
		]
	},
	{
		"featureType": "road.local",
		"elementType": "labels",
		"stylers": [
			{
				"lightness": "8"
			},
			{
				"color": "#909090"
			}
		]
	},
	{
		"featureType": "road.local",
		"elementType": "labels.icon",
		"stylers": [
			{
				"saturation": "-100"
			}
		]
	},
	{
		"featureType": "transit",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#6d6d6d"
			},
			{
				"lightness": "0"
			},
			{
				"visibility": "simplified"
			}
		]
	},
	{
		"featureType": "water",
		"elementType": "geometry",
		"stylers": [
			{
				"lightness": "0"
			},
			{
				"hue": "#00a9ff"
			}
		]
	}
]
const ThemeBtn = styled.button<{ viewMode?: boolean, lang?: string }>`
	margin: 8px;
	border-radius: 10px;
	padding: 6px 12px;
	border: 0px;
	cursor: pointer;
	${props => props.viewMode == true ?
		`position: relative;`
		: `position: absolute;top: 0;`}
	${props => props.viewMode == false && props.lang == 'ar' && `left: 0;right: unset;`}
	${props => props.viewMode == false && props.lang == 'en' && `left: unset;right: 0;`}
		
`

const LIBS: LoadScriptProps['libraries'] = ['places']
const DEFAULT_MARKER_STYLE: MarkerProps['icon'] = {
	path: "M26.6197 10.1232C25.0226 3.14273 18.8924 0 13.5076 0H13.4924C8.1228 0 1.97744 3.12762 0.380249 10.1081C-1.39947 17.9045 3.4073 24.5072 7.75773 28.6623C9.29974 30.1448 11.3616 32.9997 13.5076 33C15.5763 33 17.6451 30.2034 19.2423 28.6623C23.5927 24.5072 28.3995 17.9196 26.6197 10.1232ZM13.5076 17.6929C12.8784 17.6929 12.2553 17.5698 11.674 17.3307C11.0926 17.0915 10.5644 16.7409 10.1195 16.2989C9.67452 15.857 9.32158 15.3323 9.08078 14.7549C8.83998 14.1774 8.71604 13.5585 8.71604 12.9335C8.71604 12.3085 8.83998 11.6896 9.08078 11.1122C9.32158 10.5347 9.67452 10.0101 10.1195 9.56811C10.5644 9.12616 11.0926 8.77558 11.674 8.5364C12.2553 8.29721 12.8784 8.17411 13.5076 8.17411C14.7784 8.17411 15.9972 8.67555 16.8958 9.56811C17.7943 10.4607 18.2992 11.6713 18.2992 12.9335C18.2992 14.1958 17.7943 15.4064 16.8958 16.2989C15.9972 17.1915 14.7784 17.6929 13.5076 17.6929Z",
	fillColor: "#4E6B31",
	fillOpacity: 1,
	strokeWeight: 1,
	strokeColor: "#4E6B31",
	rotation: 0,
	scale: 1
}
// const svgMarker = {
// 	path: "M30.5,11.4c-1.4-4.6-5.1-8.3-9.7-9.7C16,0.2,10.9,1.1,7,3.9c-3.9,2.9-6.2,7.5-6.2,12.3c0,3.3,1.1,6.5,3,9.1 L16,41l12.2-15.6C31.2,21.4,32,16.3,30.5,11.4z M16,24.4c-4.5,0-8.2-3.7-8.2-8.2S11.5,8,16,8s8.2,3.7,8.2,8.2S20.5,24.4,16,24.4z",
// 	fillColor: "#28A745",
// 	fillOpacity: 1,
// 	strokeWeight: 2,
// 	strokeColor: "#fdfdfd",
// 	rotation: 0,
// 	scale: 1,
// }

type MapMarkerProps = {
	position: MarkerProps['position']
	draggable?: boolean
	icon?: MarkerProps['icon']
	onDragEnd?: MarkerProps['onDragEnd']
}

export const MapMarker = ({ icon = DEFAULT_MARKER_STYLE, ...rest }: MapMarkerProps) => {
	return (
		<MarkerF
			icon="images/pointer.png"
			// icon={svgMarker}
			{...rest}
		/>
	)
}

type MapProps = {
	googleMapsApiKey?: string
	center?: GoogleMapProps['center']
	defaultMarkerPos?: MarkerProps['position']
	draggable?: boolean
	zoom?: number
	className?: string
	renderCustomMarkers?: React.ReactElement<MapMarkerProps> | React.ReactElement<MapMarkerProps>[]
	onLoad?: LoadScriptProps['onLoad']
	onError?: LoadScriptProps['onError']
	onMarkerDragEnd?: MarkerProps['onDragEnd']
	onClickMap?: GoogleMapProps['onClick']
	viewMode?: boolean
}

function Map({
	googleMapsApiKey,
	zoom = 12,
	center,
	onMarkerDragEnd,
	onClickMap,
	className,
	defaultMarkerPos,
	draggable = true,
	renderCustomMarkers,
	viewMode = false,
	...rest
}: MapProps) {

	const { t, i18n } = useTranslation()
	const [theme, setTheme] = useState<any>(MAP_STYLES)
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: "AIzaSyCBx-BN5uLwmFezDw0NeQs9xp_UcplHIzs",
		libraries: ['places'],
	})

	return (
		<div
			className='test'
		>
			{
				isLoaded &&
				<>

					<ThemeBtn
						type='button'
						viewMode={viewMode}
						lang={i18n.language}
						onClick={() => setTheme(theme == MAP_STYLES2 ? MAP_STYLES : MAP_STYLES2)}
					>
						{t('changeMapTheme')}
					</ThemeBtn>

					<GoogleMap
						{...rest}
						mapContainerClassName={className}
						center={center}
						zoom={zoom}
						options={{ styles: theme }}
						onClick={onClickMap}
					>
						<StandaloneSearchBox>
							<input
								type="text"
								placeholder='Search'
								style={{
									boxSizing: `border-box`,
									marginTop: '10px',
									border: `1px solid #fff`,
									borderRadius: '4px',
									width: `240px`,
									height: `40px`,
									padding: `0 12px`,
									backgroundColor: 'rgba(255,255,255,0.9)',
									boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
									fontSize: `14px`,
									outline: `none`,
									textOverflow: `ellipses`,
									position: "absolute",
									left: "50%",
									marginLeft: "-120px"
								}}
							/>
						</StandaloneSearchBox>
						{
							renderCustomMarkers ?
								renderCustomMarkers
								:
								defaultMarkerPos ? (
									<MapMarker
										position={defaultMarkerPos}
										draggable={draggable}
										onDragEnd={onMarkerDragEnd}
									/>
								) : null
						}
					</GoogleMap>
				</>
			}
		</div >
	)

}

export default styled(Map)`
    width: 100%;
    min-height: 350px;
    border-radius: 18px;
`
