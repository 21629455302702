import React, { useState, useEffect, ReactNode } from "react";
import { Card } from "antd";
import styled, { CSSProperties } from "styled-components";

const prefixCls = "ant-card";
const AntdCard = styled(Card) <{ disabled: boolean; bgcolor: string }>`
    background: ${(props) => props.bgcolor && props.bgcolor};
    border-radius: 20px;
    opacity: ${(props) => (props.disabled ? "0.2;" : "1;")}
    padding: 10px;
    cursor: pointer;

    .${prefixCls}-body {
        padding: 0px;
        border-radius: 0px;
    }
`;
const CardTitle = styled.span`
  font-family: "Almarai";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  padding: 0 10px;
`;
const CardNum = styled.span`
  font-family: "Almarai";
  font-style: normal;
  font-weight: 800;
  font-size: 70px;
  line-height: 78px;
  color: #000000;
  margin-top: 20px;
`;
const CardImg = styled.img`
  margin-top: 5px;
  height: 75px;
`;

type CustomCard = {
  number?: string;
  title?: string;
  icon?: string;
  star?: boolean;
  disabled?: boolean;
  bgcolor?: string;
  className?: string;
  style?: CSSProperties | undefined;
};

export default function DashCard({
  number,
  title,
  icon = "https://cdn.iconscout.com/icon/free/png-512/email-statement-1795392-1522762.png?f=avif&w=256",
  star,
  disabled = false,
  bgcolor = "#CCE5EB",
  className = "",
  style = undefined,
}: CustomCard) {
  return (
    <AntdCard
      disabled={disabled}
      bgcolor={bgcolor}
      className={className}
      style={style}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <CardNum>{number}</CardNum>
        <CardImg src={icon} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CardTitle>{title}</CardTitle>
        <span>
          <svg
            width="29"
            height="28"
            viewBox="0 0 29 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.0755978 10.5578C0.265436 9.97061 0.786278 9.55354 1.40248 9.49801L9.77268 8.73798L13.0825 0.991075C13.3265 0.423326 13.8823 0.0558167 14.4999 0.0558167C15.1174 0.0558167 15.6732 0.423326 15.9172 0.992402L19.227 8.73798L27.5986 9.49801C28.2137 9.55487 28.7332 9.97061 28.9241 10.5578C29.1151 11.1451 28.9387 11.7891 28.4734 12.1951L22.1465 17.7439L24.0122 25.962C24.1487 26.5663 23.9142 27.1909 23.4128 27.5533C23.1433 27.748 22.828 27.8472 22.5101 27.8472C22.2359 27.8472 21.964 27.7733 21.7199 27.6272L14.4999 23.312L7.28242 27.6272C6.75427 27.945 6.08851 27.916 5.58824 27.5533C5.08665 27.1898 4.85234 26.565 4.98886 25.962L6.8545 17.7439L0.527628 12.1962C0.0623226 11.7891 -0.115349 11.1462 0.0755978 10.5578Z"
              fill={!star || disabled ? "rgba(1, 18, 36, 0.5)" : "#FFC107"}
            />
          </svg>
        </span>
      </div>
    </AntdCard>
  );
}
