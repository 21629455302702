import { useEffect, useState } from 'react'
import TabModal from 'components/TabModal'
import { useAppSelector, useAppDispatch } from 'store'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'
import {
    addDepartmentCenter,
    updateDepartmentCenter,
} from 'api/departments/departments'
import Textfield from "components/Textfield"
import { triggerLoading } from 'features/utils/loadingSlice'
import { triggerModalTab } from 'features/utils/modalTabSlice'
import Switch from 'components/Switch'
import Button from 'components/Button'
import Map from 'components/Map'

export default function CentersForm() {

    const { t, i18n } = useTranslation()
    const [formData, setFormData] = useState<any>()
    const [isChecked, setIsChecked] = useState<any>()
    const [showTabForm, setShowTabForm] = useState<any>(false)
    const [showMapError, setShowMapError] = useState<any>(false)
    const [mapPosition, setMapPosition] = useState<any>({
        lat: 0,
        lng: 0
    })
    const modalStatus = useAppSelector(state => state.modalTab)
    const currentUser = useAppSelector((state: any) => state.currentUser)
    const currentactiveTab = useAppSelector(state => state.activeTab.Data)
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()

    useEffect(() => {
        setFormData(modalStatus?.Data)
        setIsChecked(modalStatus.Type == 'add' ? 1 : modalStatus?.Data?.Active_Status_Id)
        setMapPosition({
            lat: modalStatus?.Data?.Department_Service_Center_latitudes,
            lng: modalStatus?.Data?.Department_Service_Center_longitudes
        })
    }, [])

    useEffect(() => {
        if (currentactiveTab == '/department-centers-form') {
            setShowTabForm(true)
            setTimeout(() => dispatch(triggerLoading({ status: false })), 1000)
        }
    }, [currentactiveTab])

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(getPosition, null, {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            });
        }
    }

    const getPosition = (position: any) => {
        setMapPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        })
        setShowMapError(false)
    }

    const handleSubmit = async (values: any) => {
        if (mapPosition.lat == undefined || mapPosition.lng == undefined) {
            setShowMapError(true)
            return
        } else {
            dispatch(triggerLoading({ status: true }))
            if (modalStatus?.Type == 'add') {
                await addDepartmentCenter(
                    currentUser.Data.Data.access_token,
                    modalStatus?.Data?.Department_Id,
                    values.Department_Service_Center_Name_AR,
                    values.Department_Service_Center_Name_EN,
                    values.Department_Service_Center_Address_AR,
                    values.Department_Service_Center_Address_EN,
                    values.Department_Service_Center_Telephone,
                    values.Department_Service_Center_Email,
                    mapPosition.lat,
                    mapPosition.lng,
                    values.Active_Status_Id ? 1 : 0
                ).then(() => {
                    dispatch(triggerLoading({ status: false }))
                    dispatch(triggerModalTab({ Open: false, Data: {} }))
                })
            } else {
                await updateDepartmentCenter(
                    currentUser.Data.Data.access_token,
                    modalStatus?.Data?.Department_Service_Center_Id,
                    values.Department_Service_Center_Name_AR,
                    values.Department_Service_Center_Name_EN,
                    values.Department_Service_Center_Address_AR,
                    values.Department_Service_Center_Address_EN,
                    values.Department_Service_Center_Telephone,
                    values.Department_Service_Center_Email,
                    mapPosition.lat,
                    mapPosition.lng,
                    values.Active_Status_Id ? 1 : 0
                ).then(() => {
                    dispatch(triggerLoading({ status: false }))
                    dispatch(triggerModalTab({ Open: false, Data: {} }))
                })
            }
        }
    }

    const myForm = (
        <Form
            form={form}
            style={{ width: '100%' }}
            onFinish={handleSubmit}
            initialValues={{
                "Department_Service_Center_Name_AR": modalStatus?.Data?.Department_Service_Center_Name_AR,
                "Department_Service_Center_Name_EN": modalStatus?.Data?.Department_Service_Center_Name_EN,
                "Department_Service_Center_Address_AR": modalStatus?.Data?.Department_Service_Center_Address_AR,
                "Department_Service_Center_Address_EN": modalStatus?.Data?.Department_Service_Center_Address_EN,
                "Department_Service_Center_Telephone": modalStatus?.Data?.Department_Service_Center_Telephone,
                "Department_Service_Center_Email": modalStatus?.Data?.Department_Service_Center_Email,
                "Active_Status_Id": isChecked,
                "Department_Service_Center_Id": modalStatus?.Data?.Department_Service_Center_Id,
                "Department_Service_Center_latitudes": modalStatus?.Data?.Department_Service_Center_latitudes,
                "Department_Service_Center_longitudes": modalStatus?.Data?.Department_Service_Center_longitudes
            }}
        >

            <Row gutter={[12, 0]}>
                <Col xs={24} md={12}>
                    <Row gutter={[12, 0]}>
                        <Col xs={24} md={6}>
                            <Form.Item name="Department_Service_Center_Id">
                                <Textfield
                                    label={t('centerNo') as string}
                                    disabled={true}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6} style={{ textAlign: 'center' }}>
                            <Form.Item name="Active_Status_Id">
                                <Switch
                                    label={t('status') as string}
                                    disabled={modalStatus.Type == 'view'}
                                    checked={isChecked}
                                    onChange={(state) => setIsChecked(state == true ? 1 : 0)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}></Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="Department_Service_Center_Name_AR"
                                rules={[{ required: true, message: t('error.field_required') as string }]}
                            >
                                <Textfield
                                    label={t('centerNameAR') as string}
                                    disabled={modalStatus.Type == 'view'}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="Department_Service_Center_Name_EN"
                                rules={[{ required: true, message: t('error.field_required') as string }]}
                            >
                                <Textfield
                                    label={t('centerNameEN') as string}
                                    disabled={modalStatus.Type == 'view'}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="Department_Service_Center_Address_AR"
                                rules={[{ required: true, message: t('error.field_required') as string }]}
                            >
                                <Textfield
                                    label={t('centeAddAR') as string}
                                    disabled={modalStatus.Type == 'view'}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="Department_Service_Center_Address_EN"
                                rules={[{ required: true, message: t('error.field_required') as string }]}
                            >
                                <Textfield
                                    label={t('centerAddEN') as string}
                                    disabled={modalStatus.Type == 'view'}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="Department_Service_Center_Telephone"
                                rules={[{ required: true, message: t('error.field_required') as string }]}
                            >
                                <Textfield
                                    label={t('telephone') as string}
                                    disabled={modalStatus.Type == 'view'}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="Department_Service_Center_Email"
                                rules={[{ required: true, message: t('error.field_required') as string }]}
                            >
                                <Textfield
                                    label={t('mail') as string}
                                    disabled={modalStatus.Type == 'view'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} md={12}>
                    <button
                        style={{
                            margin: '8px',
                            borderRadius: '10px',
                            padding: '6px 12px',
                            border: '0',
                            cursor: 'pointer',
                            display: modalStatus.Type == 'view' ? 'none' : 'block'
                        }}
                        type='button'
                        onClick={() => getCurrentLocation()}
                    >
                        {t('knowMyLocation')}
                    </button>
                    <div
                        style={{
                            border: showMapError && '1px solid red',
                            borderRadius: '20px'
                        }}
                    >
                        <Map
                            center={{
                                lat: parseFloat(mapPosition.lat),
                                lng: parseFloat(mapPosition.lng)
                            }}
                            defaultMarkerPos={{
                                lat: parseFloat(mapPosition.lat),
                                lng: parseFloat(mapPosition.lng)
                            }}
                            onMarkerDragEnd={(e) => {
                                setMapPosition({
                                    lat: e?.latLng?.lat(),
                                    lng: e?.latLng?.lng()
                                })
                            }}
                            draggable={modalStatus.Type == 'view' ? false : true}
                            viewMode={modalStatus.Type == 'view' ? true : false}
                        />
                    </div>
                    {showMapError && <span style={{ color: 'red' }}>{t('error.field_required')}</span>}
                </Col>
            </Row>

            {
                !modalStatus?.Open &&
                <Row style={{ justifyContent: 'end' }}>
                    <Col>
                        <Button title={t('save') as string} onClick={() => form.submit()} />
                    </Col>
                </Row>
            }
        </Form >
    )

    return (
        <>
            <TabModal
                children={myForm}
                title={'مدخلات مراكز البلدية / Municipal Centers Form'}
                link={'/department-centers-form'}
                formData={formData}
                onOk={() => { form.submit() }}
            />

            {showTabForm && myForm}
        </>
    )
}
